import React, {useEffect, useState} from 'react';
import Template from '../../components/Template';
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import {Practice} from "../../model/practice";
import {Report} from "../../model/report";
import {App, RootState} from "../../model";
import {connect} from 'react-redux';
import {Icon} from "semantic-ui-react";
import OsRollUpVarianceModel from "../../components/OsRollUpVarianceModel";
import {OnChange} from "../../model/common";
import moment from "moment";
import {exportCSV} from "../../components/ExportCsv";
import OsRollUpModel from "../../components/OsRollUpModel";
import DatePicker from "react-datepicker";
import ReactDOMServer from "react-dom/server";
import OSBrandRollUPModel from "../../components/OSBrandRollUPModel";

interface Props {
    report: Report,
    practice: Practice,
    app: App,
}

function OpenSurgeryPCRReport(props: Props) {

    const initialState:any = {
        report: {},
        popup:{
            show:false,
            key:'',
            brand:'',
            total:0
        }
    };
    const queryParams :any= new URLSearchParams(window.location.search);
    const currentYear = queryParams.get("year")?queryParams.get("year"):moment().format('YYYY');
    const currentMonth = queryParams.get("month")?queryParams.get("month"):moment().month()+1;
    const [filterDate, setFilterDate] = useState(new Date(moment().startOf('month').toString()));

    const reportAction = useActions(reportActions);
    const [state, setState] = useState(initialState);
    const [excel, setExcel] = useState(true);
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const [aptPopupInfo, setAptPopupInfo] = useState([]);
    const [brand, setBrand] = useState("");
    const [osNWDModal, setOsNWDModal] =  useState(false);
    const [variance, setVariance] =  useState({show:false});

    const sendMailOSPCRPopupDetail=(params:any)=>{
        reportAction.sendMailOSPCRPopupDetail(params);
    }

    const OSPCRReport: {[key: string]: Array<{}>} = props.report.oSPCRData;
    useEffect(() => {
        const url = `${month}/${year}/all`;
        reportAction.fetchOSPCRRollUpData(url).then((report: any) => {
            setState({...state, report});
        })
    }, []);

    const onChangeDate = async (date:any) => {
        date = moment(date).format('YYYY-MM-DD');
        let month: number;
        let year: string;
        const parts = date.split('-', 3);
        month = parts[1];
        year = parts[0];
        setMonth(month);
        setYear(year);
        window.location.href = `open-surgery-dashboard?month=${month}&year=${year}`;
    }

    const onChangeFilter: OnChange = async (e, element) => {

        const yearMonth = element.value;
        let month:number;
        let year:string;
        let practice:string;
        const parts = yearMonth.split('-', 3);
        if (yearMonth) {
            month = parts[1];
            year = parts[0];
            practice = 'all';
        } else {
            month = moment().month()+1;
            year = moment().format('YYYY');
            practice = 'all';
        }
        const url = `${month}/${year}/${practice}`;
        setMonth(month);
        setYear(year);
        window.location.href = `open-surgery-dashboard?month=${month}&year=${year}`;
    };

    const sendEmail = () => {
        const csvDataReturn :any = makeCSV();
        const pdfDataReturn :any = ReactDOMServer.renderToString(<div>
            <h3 style={{textAlign: "center"}}>Oral Surgery Patient Care Report - Brand Roll Up</h3>
            <br/>
            {makeTables()}
        </div>);
        reportAction.sendMailOSR({data: csvDataReturn, pdf: pdfDataReturn,fileName:"OS PCR BRAND ROLL UP"});
        setExcel(true);
    };
    const modalNWDOpen = (aptPopupInfo:any,brand:string) =>{
        setOsNWDModal(true);
        setAptPopupInfo(aptPopupInfo);
        setBrand(brand)
    }

    const openBrandModel = (key :any,brand:string,total:any) =>{
      setState({...state,popup:{key,brand,show:true,total}});
    }

    const closeBrandModel = (key :any,brand:string) =>{
        setState({...state,popup:{key:"",brand:"",show:false,total:0}});
    }

    const openVarianceModal=(item:any,brand:string,key:string)=>{
      const obj={show:true,item,brand,key}
      setVariance(obj);
    }

    const closeOSNWDModelModal = () => {
        setOsNWDModal(false);
    }
    const closeVarianceModal = () => {
        setVariance({...variance,show:false});
    }
    const makeTables=()=>{
        const {rollUp,actualPatientVisits,carePerVisit,careMonthtoDate,referralsMonthToDate,brandsArr,monthYear}=state.report
        const arr=[rollUp,actualPatientVisits,carePerVisit,careMonthtoDate,referralsMonthToDate]
        const header =brandsArr.map((brand:string)=><th colSpan={1} className="header-os">{brand}</th>)
        const rollUpArr  = [
                            ['Days Open','Days Completed','% Complete','Completed Care','Projected Revenue','Goal Care'],
                            ['Actual Patient Visits','Goal Patient Visits','Pt Visit Appointments Scheduled','Patient Show Rate'],
                            ['Actual CPV','Goal CPV','Variance'],
                            ['Actual Care','Goal Care','Variance'],
                            ['Total PT. Count','Total Patient count age 15+','# of OS referrals','Referral %']
                           ]
        const brandPopUpArray=['Actual Care','Goal Care','Actual CPV','Goal CPV','Days Open','Days Completed','Completed Care','Actual Patient Visits','Goal Patient Visits','Total PT. Count','Total Patient count age 15+'];
        return ( <div key={'rollup'} className="ui card">
            <div className="content">
                <h3>Current Month: {monthYear}</h3>
                {arr.map((item:any,index:number)=>(<>
                <h3>{item.title}</h3>
                <div  className="table-responsive FreezeTable">
                    <table className="ui celled single line table unstackable tableStyle">
                        <thead>
                        <tr key={index}><th colSpan={1} className="header-os"><div className="first-th">&nbsp;</div></th>
                            {header}</tr></thead>
                        <tbody>
                        {rollUpArr[index].map((key:string)=><tr key={key+"-"+index}><td key={key}>{key}</td>{ brandsArr.map((brand:string,i:number)=><td key={key+i+"-"+brand}
                            className={key=="Pt Visit Appointments Scheduled"||key=="Variance"||key=="Patient Show Rate"||brandPopUpArray.includes(key)?'clickable sizeNwd':""}
                            onClick={() => key=="Pt Visit Appointments Scheduled"?modalNWDOpen(item[key][brand],brand): (key=="Variance"||key=="Patient Show Rate"?openVarianceModal(item,brand,key):(brandPopUpArray.includes(key)?openBrandModel(key,brand,!Array.isArray(item[key][brand])?item[key][brand]:item[key][brand].length) :  ()=>{}))}>
                            {!Array.isArray(item[key][brand])?item[key][brand]:item[key][brand].length}</td>)}</tr>)}
                        </tbody>
                    </table>
                </div></>))}
            </div>
        </div>)
    }

    const makeCSV=()=>{
        const {rollUp,actualPatientVisits,carePerVisit,careMonthtoDate,referralsMonthToDate,brandsArr,monthYear}=state.report
        const arr=[rollUp,actualPatientVisits,carePerVisit,careMonthtoDate,referralsMonthToDate]
        const header =brandsArr.join(",");
        const csvData:any=[];
        const rollUpArr  = [
            ['Days Open','Days Completed','% Complete','Completed Care','Projected Revenue','Goal Care'],
            ['Actual Patient Visits','Goal Patient Visits','Pt Visit Appointments Scheduled','Patient Show Rate'],
            ['Actual CPV','Goal CPV','Variance'],
            ['Actual Care','Goal Care','Variance'],
            ['Total PT. Count','Total Patient count age 15+','# of OS referrals','Referral %']
        ]
        arr.forEach((item: any, index: number) => {
            csvData.push(item.title);
            csvData.push(" ," + header);

            let table = rollUpArr[index].map((key: string) => {
                return key + "," + brandsArr.map((brand: string, i: number) => {
                    if (!Array.isArray(item[key][brand])) {
                        return item[key][brand] && isNaN(item[key][brand])
                            ? item[key][brand].split(',').join("")
                            : item[key][brand];
                    } else {
                        return item[key][brand].length;
                    }
                }).join(",");
            });

            csvData.push(...table);
        });

        return csvData

    }
    return (
        <Template activeLink="Oral Surgery Patient Care Report">
             <div className="ui card">
                 <div className="content filterContainer">
                <h2 className="float-left mr10">Oral Surgery Patient Care Report - Brand Roll Up</h2>
                     <div className="float-right referralsFilters">
                         <a className="link mr30" onClick={() => sendEmail()}><Icon name="send"/> Send</a>
                         <a className="link" onClick={() =>exportCSV("OS PCR brandRollUp.csv",makeCSV() )}><Icon
                        name="file alternate"/>Export</a>
                    <div className="month-year-picker">
                        <DatePicker
                            name="date"
                            className='ui reports-custom-date-input cursorPointer'
                            dateFormat="MMM yyyy"
                            showMonthYearPicker={true}
                            onChange={(date:any) => onChangeDate(date)}
                            selected={filterDate}
                            placeholderText={'Select Month'}
                        />
                        <Icon className="calendar-icon cursorPointer" name="calendar alternate outline" />
                    </div>
                </div>
            </div></div>
            {state.report&& state.report.rollUp &&makeTables()}
            {osNWDModal && <OsRollUpModel  aptPopupInfo={aptPopupInfo} brand={brand} sendMailOSPCRPopupDetail ={sendMailOSPCRPopupDetail} handleClose={closeOSNWDModelModal}/>}
            {variance&& variance.show && <OsRollUpVarianceModel handleClose={closeVarianceModal} variance={variance}/>}
            {state.popup&&state.popup.show &&<OSBrandRollUPModel total ={state.popup.total} brand={state.popup.brand} name={state.popup.key} handleClose={closeBrandModel} popupInfo={state.report.popupInfo}/>}
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app,
    };
}

export default connect(mapStateToProps)(OpenSurgeryPCRReport);