import React, {useState} from 'react';
import {useActions} from "../redux/actions";
import * as reportActions from "../redux/actions/report";
import OsModal from "../components/OsModel";
import OsNWDModal from "../components/OsNWDModel";
import {Practice,NewOsTypes} from "../model/practice";
import { Report} from "../model/report";
import {App, RootState} from "../model";
import {connect} from 'react-redux';
import {Icon, Grid,Message} from "semantic-ui-react";
import {indexValues} from "../constants";

import moment from "moment";
import {Button } from "semantic-ui-react";
import {exportNewOpenSurgeryReport, htmlNewOpenSurgeryReport} from "./ExportCsv";
import HelpMark from "./HelpMark";
import DatePicker from "react-datepicker";

const customMoment = moment;
interface Props {
    report: Report,
    practice: Practice,
    app: App
}
function NewOSReport(props: any) {
    const queryParams :any= new URLSearchParams(window.location.search);
    const currentYear = moment().format('YYYY');
    const currentMonth = moment().month()+1;

    const reportAction = useActions(reportActions);
    const [loading, setLoading] = useState(true);
    const [startDate,setStartDate] = useState(customMoment().format('Y-MM-DD') )
    const [month, setMonth] = useState(currentMonth);
    const [practiceAbbr, setPracticeAbbr] = useState('');
    const [practiceInfo, setPracticeInfo] = useState('');
    const [filterDate, setFilterDate] = useState(new Date(moment().startOf('month').toString()));

    const [messageStatus,setMessageStatus] = useState(false);
    const [messageContent,setMessageContent] = useState('');
    const [year, setYear] = useState(currentYear);
    const [endDate,setEndDate] = useState(customMoment().format('Y-MM-DD') )
    const [osModal, setOsModal] =  useState(false);
    const [osNWDModal, setOsNWDModal] =  useState(false);
    const [excel, setExcel] = useState(true);
    const OSPCRReport: {[key: string]: Array<{}>} = props.report.oSPCRData;


    const sendMailOSPCRPopupDetail=(params:any)=>{
        reportAction.sendMailOSPCRPopupDetail(params);
    }

    const fetchRecords = (startDate:any, status:any) => {

        const yearMonth = startDate;
        let month:any;
        let year:string;
        let practice:string;
        const parts = yearMonth.split('-', 3);
        const nextMonth = parts[1];
        const prevMonth = parts[1];
        if (yearMonth) {
            month = status ? nextMonth : prevMonth;
            year = parts[0];
            practice = 'all';
        } else {
            month = moment().month();
            year = moment().format('YYYY');
            practice = 'all';
        }
        const url = `${month}/${year}/${practice}`;
        setMonth(month);
        setYear(year);
        reportAction.fetchOSPCRData(url);
        setLoading(false);

    }
    const monthAllData:Array<{}> = OSPCRReport.monthData;
    const yearAllData:Array<{}> = OSPCRReport.yearData;


    const modalOpen = (practiceAbbr:any) =>{
        setPracticeAbbr(practiceAbbr);
        setOsModal(true);
    }

    const modalNWDOpen = (practice:any) =>{
        setPracticeInfo(practice);
        setOsNWDModal(true);
    }
    const closeOSNWDModelModal = () => {
        setPracticeInfo('');
        setOsNWDModal(false);
    }

    const closeOSModelModal = () => {
        setPracticeAbbr('');
        setOsModal(false);
    }

    const onChangeDate = (date:any) => {
        setLoading(true);
        date= customMoment(date).format('YYYY-MM-DD') ;
        const parts = date.split('-', 3);
        setFilterDate(date)
        setStartDate(customMoment(date).format('Y-MM-DD') );
        setEndDate(customMoment(date).add(11, 'months').format('Y-MM-DD') );
        window.location.href = `open-surgery-pcr-report?month=${date.split("-")[1]}&year=${date.split("-")[0]}`;
    };
    const loadBudget = () => {
        const finalDate:any = customMoment(startDate).format('YYYY-MM');
        reportAction.reloadBudgetData({monthYear:finalDate}).then((res: any) => {
            const actualData:any = res;
            const dateFormat = actualData.period;;
            setMessageStatus(true)
            if(actualData.status){
                 fetchRecords(dateFormat,false);
            }
            setMessageContent(actualData.msg);
            setTimeout(
                () => setMessageStatus(false),
                1000
            );
        });
    }

    const numberWithCommas = (x: string) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const insertSpaces = (s:string) => {
        s = s.replace(/([a-z])([A-Z])/g, '$1 $2');
        s = s.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        return s;
    }
    const capitalizeFirstLetter = (s:string) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const capitalizeAllLetters = (s:string) => {
        return s.toUpperCase();
    }



    const getTableRows = () => {
        let returnHeaderArray: React.ReactElement[] = [];
        let returnHeaderYearArray: React.ReactElement[] = [];
        let printDateValue:any = moment().format('MMMM YYYY');

        if (monthAllData && monthAllData.length) {
            monthAllData.forEach((item: any, key: number) => {
                printDateValue = item.reportDate;
                returnHeaderArray.push(
                    <th key={key + item.practice + 'month'} className="header-os" onClick={() => modalOpen(item.practiceAbbr)} >{item.practice}
                    </th>);
            });
        }
        if (yearAllData && yearAllData.length) {
            yearAllData.forEach((item: any, key: number) => {
                returnHeaderYearArray.push(
                    <th key={key + item.practice + 'year'} className="header-os" >{item.practice}
                    </th>);
            });
        }

        let returnArrayTable:React.ReactElement[] = [];
        let returnArrayDaysOpen: React.ReactElement[] = [];
        let returnArrayCompletedDays: React.ReactElement[] = [];
        let returnArrayPercentCompleted: React.ReactElement[] = [];
        let returnArrayDaysLeft: React.ReactElement[] = [];

        let returnArrayBudCare: React.ReactElement[] = [];
        let returnArrayActCare: React.ReactElement[] = [];
        let returnArrayDeltaCare: React.ReactElement[] = [];

        let returnArrayBudCpvCare: React.ReactElement[] = [];
        let returnArrayActCpvCare: React.ReactElement[] = [];
        let returnArrayDeltaCpvCare: React.ReactElement[] = [];

        let returnArrayActPtVisits: React.ReactElement[] = [];
        let returnArrayBudPt: React.ReactElement[] = [];
        let returnArrayDeltaPTVisits: React.ReactElement[] = [];

        let returnArrayLastWorkDay: React.ReactElement[] = [];
        let returnArrayLwdCare: React.ReactElement[] = [];
        let returnArrayLwdPtCount: React.ReactElement[] = [];

        let returnArrayNextWorkDay: React.ReactElement[] = [];
        let returnArrayNwdCareGoal: React.ReactElement[] = [];
        let returnArrayNwdAptCount: React.ReactElement[] = [];
        let returnArrayNwdPtGoal: React.ReactElement[] = [];
        let returnArrayNwdScheduleCare: React.ReactElement[] = [];
        let returnArrayPTC: React.ReactElement[] = [];


        let practiceName: string[] = [];

        // month records
        if(monthAllData && monthAllData.length > 0) {
            const objMonth: any = {};
            let dataObj: any = {};
            let dataKeys: string[] = [];
            monthAllData.forEach((item: any, key: number) => {
                Object.assign(objMonth, {[item.practiceAbbr]: item.monthNewAllData});
                const data: any = key == 0 ? dataKeys = Object.keys(item.monthNewAllData) : '';
                practiceName.push(item.practiceAbbr);
            });

            dataKeys.forEach((key) => {
                let obj = {};
                let monthObj = {};
                monthAllData.forEach((item: any) => {

                    const monthData = item.monthNewAllData[key];
                    Object.keys(monthData).forEach((value) => {
                        Object.assign(monthObj, {[`${item.practiceAbbr}_${value}`]: monthData[value],});
                        obj = Object.assign(obj, {[value]: monthObj})
                    });
                });
                Object.assign(dataObj, {[key]: obj})
            });


            Object.keys(dataObj).forEach((key,index) => {

                const keyObj = dataObj[key];
                const keyName = key;
                Object.keys(keyObj).forEach((value,index) => {
                    //Work Days
                    if(keyName === "workDays"){
                        if(value === 'daysOpen') {
                            returnArrayDaysOpen.push(<td key={value + '-' + index} >{capitalizeFirstLetter(insertSpaces(value))}</td>);
                        }else if(value === 'completedDays'){
                            returnArrayCompletedDays.push(<td key={value + '-' + index} >{capitalizeFirstLetter(insertSpaces(value))}</td>);
                        }else if(value === 'percentCompleted'){
                            returnArrayPercentCompleted.push(<td key={value + '-' + index} >{capitalizeFirstLetter(insertSpaces(value))}</td>);
                        }else if(value === 'daysLeft'){
                            returnArrayDaysLeft.push(<td key={value + '-' + index} >{capitalizeFirstLetter(insertSpaces(value))}</td>);
                        }
                    }
                    //LWD
                    if(keyName === "LWD"){
                        if(value === 'lastWorkDay') {
                            returnArrayLastWorkDay.push(<td key={value + '-' + index} >{capitalizeFirstLetter(insertSpaces(value))}</td>);
                        }else if(value === 'lwdCare'){
                            returnArrayLwdCare.push(<td key={value + '-' + index} >LWD Care</td>);
                        }else if(value === 'lwdPtCount'){
                            returnArrayLwdPtCount.push(<td key={value + '-' + index} >LWD Pt Count</td>);
                        }

                    }

                    if(keyName === "careMTD"){
                        if (value === 'budCare') {
                            returnArrayBudCare.push(<td key={value + '-' + index}>Care Goal MTD</td>);
                        } else if (value === 'actCare') {
                            returnArrayActCare.push(<td key={value + '-' + index} >Care Actual MTD</td>);
                        } else if (value === 'deltaCare') {
                            returnArrayDeltaCare.push(<td key={value + '-' + index}>Care Delta MTD</td>);
                        }
                    }

                    if(keyName === 'goalToActualPatientVisitsMTD'){
                       if (value === 'goalPt') {
                           const val = 'goalPtVisits'
                            returnArrayBudPt.push(<td key={value + '-' + index + '-' + keyName}>{capitalizeFirstLetter(insertSpaces(val))}</td>);
                        } else if (value === 'actPtVisits') {
                            returnArrayActPtVisits.push(<td key={value + '-' + index + '-' + keyName}>{capitalizeFirstLetter(insertSpaces(value))}</td>);
                        } else if (value === 'deltaPTVisits') {
                            returnArrayDeltaPTVisits.push(<td key={value + '-' + index + '-' + keyName}>Delta Pt Visits</td>);
                        }
                    }

                    if(keyName === 'goalToActualCPV'){
                        if (value === 'goalCpv') {
                            returnArrayBudCpvCare.push(<td key={value + '-' + index}>Goal CPV</td>);
                        }else if(value === 'actCpv'){
                            returnArrayActCpvCare.push(<td key={value + '-' + index}>Act CPV</td>);

                        }else if(value === 'deltaCpv'){
                            returnArrayDeltaCpvCare.push(<td key={value + '-' + index}>Delta CPV</td>);

                        }
                    }



                    let monthgrandTotal: any = 0;
                    //Lwd Pt Count
                    let LWDmonthgrandTotal: any = 0;
                    //Lwd Care Total
                    let CareActualMTDGrandTotal: any = 0;
                    let CMTDmonthgrandTotal: any = 0;
                    let VMTDmonthgrandTotal: any = 0;
                    let ACPVmonthgrandTotal: any = 0;
                    let NWDmonthgrandTotal: any = 0;
                    
                    
                    let CareGaolMTD: any = 0;
                    let count:any = 0;
                    practiceName.forEach((practice,index) => {
                        //Work Days
                        if(keyName === "workDays"){
                            if(value === 'daysOpen') {
                                monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                const numseVar  = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                                returnArrayDaysOpen.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>);
                            }else if(value === 'completedDays'){
                                monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                const numseVar = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                                returnArrayCompletedDays.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>);
                            }else if(value === 'percentCompleted'){
                                const numseVar = parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(1) + '%';
                                monthgrandTotal = monthgrandTotal + parseInt(keyObj[value][`${practice}_${value}`]);
                                returnArrayPercentCompleted.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>);
                            }else if(value === 'daysLeft'){
                                monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                const numseVar = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                                returnArrayDaysLeft.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>);
                            }
                        }
                        //LWD
                        if(keyName === "LWD"){
                            if(value == 'lastWorkDay') {
                                const numseVar = keyObj[value][`${practice}_${value}`];
                                CareGaolMTD = CareGaolMTD + parseFloat(keyObj[value][`${practice}_${value}`]);
                                returnArrayLastWorkDay.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>);                             
                            }else if(value == 'lwdCare'){
                                const numseVar = '$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))
                                    + '';
                                CareActualMTDGrandTotal = CareActualMTDGrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                returnArrayLwdCare.push(<td key={value + '-' + index + '-' + practice} >{numseVar}</td>);
                            }else if(value == 'lwdPtCount'){
                                LWDmonthgrandTotal = LWDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                const numseVar = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                                returnArrayLwdPtCount.push(<td key={value + '-' + index + '-' + practice} >{numseVar}</td>);                         
                            }
                        }

                        if(keyName === "careMTD") {
                            if (value == 'budCare' || value == 'actCare' || value == 'deltaCare') {

                                const numseVar = '$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '';
                                const checkNum: any = parseFloat(keyObj[value][`${practice}_${value}`])
                                if (checkNum !== 0) {
                                    count++;
                                    CMTDmonthgrandTotal = CMTDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                }
                                {value == 'budCare' && returnArrayBudCare.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>)};
                                {value == 'actCare' && returnArrayActCare.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>)};
                                {value == 'deltaCare' && returnArrayDeltaCare.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>)};
                            }
                        }

                        if(keyName === 'goalToActualPatientVisitsMTD'){
                            if (value == 'actPtVisits' || value == 'goalPt' || value == 'deltaPTVisits') {
                                    VMTDmonthgrandTotal = VMTDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                const numseVar = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                                    {value == 'actPtVisits' && returnArrayActPtVisits.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>)};
                                    {value == 'goalPt' && returnArrayBudPt.push(<td key={value + '-' + index}>{numseVar}</td>)};
                                    {value == 'deltaPTVisits' && returnArrayDeltaPTVisits.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>)};
                                }
                        }

                        if(keyName === 'goalToActualCPV'){
                           if (value == 'goalCpv' || value == 'actCpv' || value == 'deltaCpv') {
                               const numseVar = '$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '';
                               const checkNum: any = parseFloat(keyObj[value][`${practice}_${value}`])
                               if (checkNum !== 0) {
                                   count++;
                                   ACPVmonthgrandTotal = ACPVmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                               }
                                {value == 'goalCpv' && returnArrayBudCpvCare.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>)};
                                {value == 'actCpv' && returnArrayActCpvCare.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>)};
                                {value == 'deltaCpv' && returnArrayDeltaCpvCare.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>)};
                            }
                        }

                        if(keyName === 'NWD'){
                            if(value === 'nextWorkDay') {
                                const numseVar = keyObj[value][`${practice}_${value}`];
                                NWDmonthgrandTotal = NWDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                returnArrayNextWorkDay.push(<td key={value + '-' + index + '-' + practice}>{numseVar}</td>);
                            }else if(value === 'nwdCareGoal'){
                                const numseVar = '$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))
                                    + '';
                                NWDmonthgrandTotal = NWDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                returnArrayNwdCareGoal.push(<td key={value + '-' + index + '-' + practice} >{numseVar}</td>);
                            }else if(value === 'nwdAptCount'){
                                NWDmonthgrandTotal = NWDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                const numseVar = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                                returnArrayNwdAptCount.push(<td key={value + '-' + index + '-' + practice} >{numseVar}</td>);
                            }else if(value === 'nwdPtGoal'){
                                NWDmonthgrandTotal = NWDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                const numseVar = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                                returnArrayNwdPtGoal.push(<td key={value + '-' + index + '-' + practice} >{numseVar}</td>);
                            }else if(value === 'nwdScheduleCare'){
                                const numseVar = '$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))
                                    + '';
                                NWDmonthgrandTotal = NWDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                returnArrayNwdScheduleCare.push(<td key={value + '-' + index + '-' + practice} >{numseVar}</td>);
                            }
                        }

                    });
                    //Work Days
                    if(keyName === "workDays"){
                        if(value === 'daysOpen') {
                            const num: string = `${parseFloat(monthgrandTotal).toFixed(0)}`;
                            returnArrayDaysOpen.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>);
                        }else if(value === 'completedDays'){
                            const num: string = `${parseFloat(monthgrandTotal).toFixed(0)}`;
                            returnArrayCompletedDays.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>);
                        }else if(value === 'percentCompleted'){
                            const percent: any = (monthgrandTotal / practiceName.length);
                            returnArrayPercentCompleted.push(<td key={keyName + '-' + value + '-'+ index}>{parseFloat(percent).toFixed(1) + '%'}</td>);
                        }else if(value === 'daysLeft'){
                            const num: string = `${parseFloat(monthgrandTotal).toFixed(0)}`;
                            returnArrayDaysLeft.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>);
                        }

                    }
                    //LWD
                    if(keyName === "LWD"){
                        if(value === 'lastWorkDay') {
                            returnArrayLastWorkDay.push(<td key={keyName + '-' + value + '-'+ index}>&nbsp;</td>);
                        }else if(value === 'lwdCare'){
                            const price: string = `$${numberWithCommas(parseFloat(CareActualMTDGrandTotal).toFixed(0))}`;
                            returnArrayLwdCare.push(<td key={keyName + '-' + value + '-'+ index}>{price}</td>);
                        }else if(value === 'lwdPtCount'){
                            const num: string = `${parseFloat(LWDmonthgrandTotal).toFixed(0)}`;
                            returnArrayLwdPtCount.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>);
                        }
                    }

                    if(keyName === "careMTD") {
                        if (value == 'budCare' || value == 'actCare' || value == 'deltaCare') {
                            const val: any = CMTDmonthgrandTotal;
                            const num = `${'$' + numberWithCommas(parseFloat(val ? val : 0).toFixed(0))}`;
                            {value == 'budCare' && returnArrayBudCare.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>)}
                            {value == 'actCare' && returnArrayActCare.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>)}
                            {value == 'deltaCare' && returnArrayDeltaCare.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>)}
                        }
                    }

                    if(keyName === 'goalToActualPatientVisitsMTD'){
                        if (value == 'actPtVisits' || value == 'goalPt' || value == 'deltaPTVisits') {
                            const num: string = `${parseFloat(VMTDmonthgrandTotal).toFixed(0)}`;
                            {value == 'actPtVisits' && returnArrayActPtVisits.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>)};
                            {value == 'goalPt' && returnArrayBudPt.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>)};
                            {value == 'deltaPTVisits' && returnArrayDeltaPTVisits.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>)};

                        }
                    }

                    if(keyName === 'goalToActualCPV'){
                        if (value == 'goalCpv' || value == 'actCpv' || value == 'deltaCpv') {
                            const val: any = (ACPVmonthgrandTotal/count);
                            const num = `${'$' + numberWithCommas(parseFloat(val ? val : 0).toFixed(0))}`;
                            {value == 'goalCpv' && returnArrayBudCpvCare.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>)}
                            {value == 'actCpv' && returnArrayActCpvCare.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>)}
                            {value == 'deltaCpv' && returnArrayDeltaCpvCare.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>)}
                       }
                    }

                    if(keyName === 'NWD'){
                        if(value === 'nextWorkDay') {
                            returnArrayNextWorkDay.push(<td key={keyName + '-' + value + '-'+ index}>&nbsp;</td>);
                        }else if(value === 'nwdCareGoal'){
                            const price: string = `$${numberWithCommas(parseFloat(NWDmonthgrandTotal).toFixed(0))}`;
                            returnArrayNwdCareGoal.push(<td key={keyName + '-' + value + '-'+ index}>{price}</td>);
                        }else if(value === 'nwdAptCount'){
                            const num: string = `${parseFloat(NWDmonthgrandTotal).toFixed(0)}`;
                            returnArrayNwdAptCount.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>);
                        }else if(value === 'nwdPtGoal'){
                            const num: string = `${parseFloat(NWDmonthgrandTotal).toFixed(0)}`;
                            returnArrayNwdPtGoal.push(<td key={keyName + '-' + value + '-'+ index}>{num}</td>);
                        }else if(value === 'nwdScheduleCare'){
                            const price: string = `$${numberWithCommas(parseFloat(NWDmonthgrandTotal).toFixed(0))}`;
                            returnArrayNwdScheduleCare.push(<td key={keyName + '-' + value + '-'+ index}>{price}</td>);
                        }
                    }



                });
                //Work Days
                if(keyName === 'workDays'){
                    returnArrayTable.push(<div key={keyName + '-' + index + '-' + indexValues[1]} className="ui card">
                        <div className="content">
                            <h3>Current Month: {printDateValue}</h3>
                            <h3>This table shows the {capitalizeFirstLetter(insertSpaces(key))} per practice</h3>
                            <div  className="table-responsive FreezeTable">
                            <table className="ui celled single line table unstackable tableStyle">
                                <thead>
                                <tr key={keyName + '-' + indexValues[1] + '-'}><th colSpan={1} className="header-os"><div className="first-th">&nbsp;</div></th>
                                    {returnHeaderArray}<th className="header-os">Grand Total</th></tr></thead>
                                <tbody>
                                <tr key={keyName + '-' + indexValues[2] + '-'}>{returnArrayDaysOpen}</tr>
                                <tr key={keyName + '-' + indexValues[3] + '-'}>{returnArrayCompletedDays}</tr>
                                <tr key={keyName + '-' + indexValues[4] + '-'}>{returnArrayDaysLeft}</tr>
                                <tr key={keyName + '-' + indexValues[5] + '-'}>{returnArrayPercentCompleted}</tr></tbody></table></div></div></div>);
                }
                //LWD
                if(keyName === 'LWD'){
                    returnArrayTable.push(<div key={keyName + '-' + index + '-' + indexValues[1]} className="ui card">

                        <div className="content">
                            <h3>This table shows the {capitalizeFirstLetter(insertSpaces(key))} per practice</h3>
                            <div  className="table-responsive FreezeTable">
                            <table className="ui celled single line table unstackable tableStyle">
                                <thead><tr key={keyName + '-' + indexValues[1] + '-'}><th colSpan={1} className="header-os"><div className="first-th">&nbsp;</div></th>
                                    {returnHeaderArray}<th className="header-os">Grand Total</th>
                                </tr></thead>
                                <tbody><tr key={keyName + '-' + indexValues[2] + '-'}>{returnArrayLastWorkDay}</tr>
                                <tr key={keyName + '-' + indexValues[3] + '-'}>{returnArrayLwdCare}</tr>
                                <tr key={keyName + '-' + indexValues[4] + '-'}>{returnArrayLwdPtCount}</tr>
                                </tbody></table></div></div></div>);
                }
                if(keyName === 'careMTD'){
                    returnArrayTable.push(<div key={keyName + '-' + index + '-' + indexValues[1]}  className="ui card">

                        <div className="content">
                            <h3>This table shows the {capitalizeFirstLetter(insertSpaces(key))} per practice</h3>
                            <div  className="table-responsive FreezeTable">
                            <table className="ui celled single line table unstackable tableStyle">
                                <thead>
                                <tr key={keyName + '-' + indexValues[1] + '-'}><th colSpan={1} className="header-os"><div className="first-th">&nbsp;</div></th>
                                    {returnHeaderArray}<th className="header-os">Grand Total</th>
                                </tr></thead>
                                <tbody>
                                <tr key={keyName + '-' + indexValues[2] + '-'}>{returnArrayBudCare}</tr>
                                <tr key={keyName + '-' + indexValues[3] + '-'}>{returnArrayActCare}</tr>
                                <tr key={keyName + '-' + indexValues[4] + '-'}>{returnArrayDeltaCare}</tr>
                                </tbody></table></div></div></div>);
                }
                if(keyName === 'goalToActualPatientVisitsMTD'){
                    returnArrayTable.push(<div key={keyName + '-' + index + '-' + indexValues[1]}  className="ui card">

                        <div className="content">
                            <h3>This table shows the {capitalizeFirstLetter(insertSpaces(key))} per practice</h3>
                            <div className="table-responsive FreezeTable">
                            <table className="ui celled single line table unstackable tableStyle">
                                <thead>
                                <tr key={keyName + '-' + indexValues[1] + '-'}><th colSpan={1} className="header-os">
                                    <div className="first-th">&nbsp;</div></th>
                                    {returnHeaderArray}<th className="header-os">Grand Total</th></tr></thead>
                                <tbody>
                                <tr key={keyName + '-' + indexValues[2] + '-'}>{returnArrayBudPt}</tr>
                                <tr key={keyName + '-' + indexValues[3] + '-'}>{returnArrayActPtVisits}</tr>
                                <tr key={keyName + '-' + indexValues[4] + '-'}>{returnArrayDeltaPTVisits}</tr>
                                </tbody></table></div></div></div>);
                }
                if(keyName === 'goalToActualCPV'){
                    returnArrayTable.push(<div key={keyName + '-' + index + '-' + indexValues[1]}  className="ui card">

                        <div className="content">
                            <h3>This table shows the {capitalizeFirstLetter(insertSpaces(key))} per practice</h3>
                            <div className="table-responsive FreezeTable">
                            <table className="ui celled single line table unstackable tableStyle">
                                <thead>
                                <tr key={keyName + '-' + indexValues[1] + '-'}><th colSpan={1} className="header-os">
                                    <div className="first-th">&nbsp;</div></th>
                                    {returnHeaderArray}<th className="header-os">Grand Total</th></tr></thead>
                                <tbody>
                                <tr key={keyName + '-' + indexValues[2] + '-'}>{returnArrayBudCpvCare}</tr>
                                <tr key={keyName + '-' + indexValues[3] + '-'}>{returnArrayActCpvCare}</tr>
                                <tr key={keyName + '-' + indexValues[4] + '-'}>{returnArrayDeltaCpvCare}</tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>);
                }
            });
        }

        let returnArrayActCareY: React.ReactElement[] = [];
        let returnArrayPtCount: React.ReactElement[] = [];

        let returnArrayYNextWorkDay: React.ReactElement[] = [];
        let returnArrayYNwdCareGoal: React.ReactElement[] = [];
        let returnArrayYNwdAptCount: React.ReactElement[] = [];
        let returnArrayYNwdPtGoal: React.ReactElement[] = [];
        let returnArrayYNwdScheduleCare: React.ReactElement[] = [];

        let returnArrayYPTCptCount: React.ReactElement[] = [];
        let returnArrayYPTCage15ptCount: React.ReactElement[] = [];
        let returnArrayYPTCosReferrals: React.ReactElement[] = [];
        let returnArrayYPTCreferralsPercentage: React.ReactElement[] = [];


        // year Records
        if(yearAllData && yearAllData.length > 0){
            const objYear: any = {};
            yearAllData.map((s: any) => (Object.assign(objYear, {[s.practiceAbbr]: s.year})));
            let dataObjYear: any = {};
            let practiceNameYear: string[] = [];
            let dataKeysYear: string[] = [];
            yearAllData.forEach((item: any, key: number) => {
                const data: any = key == 0 ? dataKeysYear = Object.keys(item.year) : '';
                practiceNameYear.push(item.practiceAbbr);
                dataKeysYear = dataKeysYear.filter(item => item !== 'scheduleApp')
            });
            let practiceNameCheckYear = '';
            dataKeysYear.forEach((key) => {
                let objYear = {};
                let yearObj = {};
                yearAllData.forEach((item: any) => {
                    const yearData = item.year[key];
                    Object.keys(yearData).forEach((value) => {
                        Object.assign(yearObj, {[`${item.practiceAbbr}_${value}`]: yearData[value],});
                        objYear = Object.assign(objYear, {[value]: yearObj})
                    });
                });
                Object.assign(dataObjYear, {[key]: objYear})
            });


            Object.keys(dataObjYear).forEach((key, index) => {
                const keyObjYear = dataObjYear[key];
                const keyName = key;

                Object.keys(keyObjYear).forEach((value, index) => {

                    if(keyName === 'NWD'){
                         if (value == 'nextWorkDay') {
                             returnArrayYNextWorkDay.push(<td key={value + '-' + index}>{capitalizeFirstLetter(insertSpaces(value))}</td>);
                         }else if(value == 'nwdAptCount'){
                             returnArrayYNwdAptCount.push(<td key={value + '-' + index} >NWD Apt Count</td>);

                         }else if(value == 'nwdCareGoal'){
                             returnArrayYNwdCareGoal.push(<td key={value + '-' + index}>NWD Care Goal</td>);

                         }else if(value === 'nwdPtGoal'){
                             returnArrayYNwdPtGoal.push(<td key={value + '-' + index}>NWD Pt Goal</td>);

                         }else if(value === 'nwdScheduleCare'){
                             returnArrayYNwdScheduleCare.push(<td key={value + '-' + index}>NWD Schedule Care</td>);

                         }
                    }
                    if(keyName === 'PTC') {
                        if (value == 'totalPTCount'){
                            returnArrayYPTCptCount.push(<td key={value + '-1-' + index} >Total PT. Count</td>)
                        }
                        if (value == 'totalPTCountAge15') {
                            returnArrayYPTCage15ptCount.push(<td key={value + '-2-' + index} >Total Patient count age 15+</td>)
                        }
                        if (value == 'osReferrals') {
                            returnArrayYPTCosReferrals.push(<td key={value + '-3-' + index} ># of OS referrals</td>)
                        }
                        if (value == 'referralsPercentage') {
                            returnArrayYPTCreferralsPercentage.push(<td key={value + '-4-' + index} >Referral %  (patients age 15 and up) line 56/55 x 100</td>)
                        }
                    }
                    if(keyName === "newYReport"){
                        if(value === 'careActualYTD') {
                            returnArrayActCareY.push(<td key={value + '-1-' + index} >Care Actual YTD</td>);
                        }else if(value === 'ptCountYtd'){
                            returnArrayPtCount.push(<td key={value + '-2-' + index} >PT Count YTD</td>);
                        }
                    }

                    let yearGrandTotal: any = 0;
                    let NWDYgrandTotal:any = 0;
                    let PTCYgrandTotal: any = 0;
                    practiceNameYear.forEach((practice,index) => {
                        practiceNameCheckYear = practice;

                        if (keyName === 'PTC') {
                            if(value === 'totalPTCount') {
                                const ptCount = keyObjYear[value][`${practice}_${value}`];
                                PTCYgrandTotal = PTCYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                returnArrayYPTCptCount.push(<td key={value + '-' + index + '-' + practice} >{ptCount}</td>);
                            }
                            if (value == 'totalPTCountAge15') {
                                const ptCountAge15 = keyObjYear[value][`${practice}_${value}`];
                                PTCYgrandTotal = PTCYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                returnArrayYPTCage15ptCount.push(<td key={value + '-2-' + index} >{ptCountAge15}</td>)
                            }
                            if (value == 'osReferrals') {
                                const orals = keyObjYear[value][`${practice}_${value}`];
                                PTCYgrandTotal = PTCYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                returnArrayYPTCosReferrals.push(<td key={value + '-3-' + index} >{orals}</td>)
                            }
                            if (value == 'referralsPercentage') {
                                const referralsPercentage = keyObjYear[value][`${practice}_${value}`];
                                PTCYgrandTotal = PTCYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                returnArrayYPTCreferralsPercentage.push(<td key={value + '-4-' + index} >{referralsPercentage}</td>)
                            }
                        }
                        if(keyName === 'NWD'){
                            if(value === 'nextWorkDay') {
                                const numseVar = keyObjYear[value][`${practice}_${value}`];
                                NWDYgrandTotal = NWDYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                returnArrayYNextWorkDay.push(<td key={value + '-' + index + '-' + practice} className='sizeNwd'>{numseVar}</td>);
                            }else if(value === 'nwdCareGoal'){
                                const numseVar = '$' + numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0))
                                    + '';
                                NWDYgrandTotal = NWDYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                returnArrayYNwdCareGoal.push(<td key={value + '-' + index + '-' + practice} className='sizeNwd'>{numseVar}</td>);
                            }else if(value === 'nwdAptCount'){
                                NWDYgrandTotal = NWDYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                const numseVar = `${numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0))}`;
                                returnArrayYNwdAptCount.push(<td className='clickable sizeNwd' key={value + '-' + index + '-' + practice} onClick={() => modalNWDOpen(practice)} >{numseVar}</td>);
                            }else if(value === 'nwdPtGoal'){
                                NWDYgrandTotal = NWDYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);

                                const numseVar = `${numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0))}`;
                                returnArrayYNwdPtGoal.push(<td key={value + '-' + index + '-' + practice} className='sizeNwd' >{numseVar}</td>);
                            }else if(value === 'nwdScheduleCare'){
                                const numseVar = '$' + numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0))
                                    + '';
                                NWDYgrandTotal = NWDYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                returnArrayYNwdScheduleCare.push(<td key={value + '-' + index + '-' + practice} className='sizeNwd' >{numseVar}</td>);
                            }
                        }

                        if(keyName === "newYReport"){
                            if(value === 'careActualYTD') {
                                const numseVar = '$' + numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0))
                                    + '';
                                yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                returnArrayActCareY.push(<td key={value + '-' + index + '-' + practice} >{numseVar}</td>);
                            }else if(value === 'ptCountYtd'){
                                yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                const numseVar = `${numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0))}`;
                                returnArrayPtCount.push(<td key={value + '-' + index + '-' + practice} >{numseVar}</td>);
                            }
                        }
                    });

                    if (keyName === 'PTC') {
                        if(value === 'totalPTCount') {
                            const total: string = `${numberWithCommas(parseFloat(PTCYgrandTotal).toFixed(0))}`;
                            returnArrayYPTCptCount.push(<td  key={keyName + '-' + value + '-'+ index} >{total}</td>);
                        }
                        if (value == 'totalPTCountAge15') {
                            const total: string = `${numberWithCommas(parseFloat(PTCYgrandTotal).toFixed(0))}`;
                            returnArrayYPTCage15ptCount.push(<td key={value + '-2-' + index} >{total}</td>)
                        }
                        if (value == 'osReferrals') {
                            const total: string = `${numberWithCommas(parseFloat(PTCYgrandTotal).toFixed(0))}`;
                            returnArrayYPTCosReferrals.push(<td key={value + '-3-' + index} >{total}</td>)
                        }
                        if (value == 'referralsPercentage') {
                            const total: string = `${numberWithCommas(parseFloat(PTCYgrandTotal).toFixed(0))}`;
                            returnArrayYPTCreferralsPercentage.push(<td key={value + '-4-' + index} >{total}</td>)
                        }
                    }

                    if(keyName === 'NWD'){

                        if(value === 'nextWorkDay') {
                            returnArrayYNextWorkDay.push(<td  key={keyName + '-' + value + '-'+ index} >&nbsp;</td>);
                        }else if(value === 'nwdCareGoal'){
                            const price: string = `$${numberWithCommas(parseFloat(NWDYgrandTotal).toFixed(0))}`;
                            returnArrayYNwdCareGoal.push(<td key={keyName + '-' + value + '-'+ index} className='sizeNwd'>{price}</td>);
                        }else if(value === 'nwdAptCount'){
                            const num: string = `${parseFloat(NWDYgrandTotal).toFixed(0)}`;
                            returnArrayYNwdAptCount.push(<td key={keyName + '-' + value + '-'+ index} className='clickable sizeNwd' onClick={() => modalNWDOpen("all")}>{num}</td>);
                        }else if(value === 'nwdPtGoal'){

                            const num: string = `${parseFloat(NWDYgrandTotal).toFixed(0)}`;
                            returnArrayYNwdPtGoal.push(<td key={keyName + '-' + value + '-'+ index} className='sizeNwd'>{num}</td>);
                        }else if(value === 'nwdScheduleCare'){
                            const price: string = `$${numberWithCommas(parseFloat(NWDYgrandTotal).toFixed(0))}`;
                            returnArrayYNwdScheduleCare.push(<td  key={keyName + '-' + value + '-'+ index} className='sizeNwd'>{price}</td>);
                        }
                    }

                    if(keyName === "newYReport") {
                        if (value === "careActualYTD") {
                            const price: string = `$${numberWithCommas(parseFloat(yearGrandTotal).toFixed(0))}`;
                            returnArrayActCareY.push(<td key={value + '-' + index}>{price}</td>);
                        }
                        if (value === "ptCountYtd") {
                            const num: string = `${parseFloat(yearGrandTotal).toFixed(0)}`;
                            returnArrayPtCount.push(<td key={value + '-' + index}>{num}</td>);
                        }
                    }

                });

                if(keyName === 'NWD'){
                    returnArrayTable.push(<div key={keyName + '-' + index + '-' + indexValues[1]}  className="ui card">

                        <div className="content">
                            <h3>This table shows the {capitalizeFirstLetter(insertSpaces(key))} per practice</h3>
                            <div className="table-responsive FreezeTable">
                            <table className="ui celled single line table unstackable tableStyle">
                                <thead>
                                <tr key={keyName + '-' + indexValues[1] + '-'}><th colSpan={1} className="header-os">
                                    <div className="first-th">&nbsp;</div></th>
                                    {returnHeaderYearArray}<th className="header-os">Grand Total</th></tr></thead>
                                <tbody>
                                <tr key={keyName + '-' + indexValues[2] + '-'}>{returnArrayYNextWorkDay}</tr>
                                <tr key={keyName + '-' + indexValues[5] + '-'}>{returnArrayYNwdPtGoal}</tr>
                                <tr key={keyName + '-' + indexValues[3] + '-'}>{returnArrayYNwdAptCount}</tr>
                                <tr key={keyName + '-' + indexValues[4] + '-'}>{returnArrayYNwdCareGoal}</tr>
                                <tr key={keyName + '-' + indexValues[6] + '-'}>{returnArrayYNwdScheduleCare}</tr>
                                </tbody></table></div></div></div>);
                }

                if(keyName === 'PTC') {
                    returnArrayTable.push(
                        <div key={keyName + '-' + index + '-' + indexValues[1]} className="ui card">
                            <div className="content">
                                <h3>This table shows Patient Count per practice </h3>
                                <div  className="table-responsive FreezeTable">
                                    <table className="ui celled single line table unstackable tableStyle">
                                        <thead>
                                            <tr key={keyName + '-' + indexValues[1]}>
                                                <th colSpan={1} className="header-os">
                                                    <div className="first-th">&nbsp;</div>
                                                </th>
                                                {returnHeaderYearArray}
                                                <th className="header-os">Grand Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={keyName + '-' + indexValues[2] + '-'}>{returnArrayYPTCptCount}</tr>
                                            <tr key={keyName + '-' + indexValues[3] + '-'}>{returnArrayYPTCage15ptCount}</tr>
                                            <tr key={keyName + '-' + indexValues[4] + '-'}>{returnArrayYPTCosReferrals}</tr>
                                            <tr key={keyName + '-' + indexValues[5] + '-'}>{returnArrayYPTCreferralsPercentage}</tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )
                }

                if(keyName === "newYReport") {
                    returnArrayTable.push(<div key={keyName + '-' + index + '-' + indexValues[1]} className="ui card">

                        <div className="content">
                            <h3>This table shows YTD per practice </h3>
                            <div  className="table-responsive FreezeTable">
                            <table className="ui celled single line table unstackable tableStyle">
                                <thead>
                                <tr key={keyName + '-' + indexValues[1]}><th colSpan={1} className="header-os"><div className="first-th">&nbsp;</div></th>
                                    {returnHeaderYearArray}<th className="header-os">Grand Total</th>
                                </tr></thead>
                                <tbody>
                                <tr key={keyName + '-' + indexValues[2] + '-'}>{returnArrayActCareY}</tr>
                                <tr key={keyName + '-' + indexValues[3] + '-'}>{returnArrayPtCount}</tr>
                                </tbody></table></div></div></div>);
                }


            });
           }
        return (<div className="ui">
            <div className="content">{returnArrayTable}</div></div>);

    }

    const sendEmail = (data:any,  excel:any) => {
        const csvDataReturn :any = exportNewOpenSurgeryReport(data, month, year, !excel);
        const pdfDataReturn :any = htmlNewOpenSurgeryReport(data, month, year);
        reportAction.sendMailNewOSR({data: csvDataReturn, pdf: pdfDataReturn});
        setExcel(true);
    };


    return (
        <div>
            {messageStatus && <Message  color='green' content={messageContent} />}
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row className="mb10">
                            <Grid.Column>
                                <Button.Group  className="btnNextPre mr20 float-left">
                                    <Button className="link mb10" onClick={loadBudget}>Load Budget</Button>
                                </Button.Group>
                               
                                <h2 className="float-left textCenter mt0 mb10 ml40" style={{margin: '10px', width:'36%'}} >
                                    Oral Surgery Patient Care New Report <HelpMark pageId="44"/>
                                </h2>
                                <div className="float-right referralsFilters">
                                    <Button className="link mb10" onClick={() => props.flipContent(false)}>OS PCR old Report</Button>
                                    <a className="link mr30" onClick={() => sendEmail( OSPCRReport, excel)}><Icon name="send"/> Send</a>
                                    <a className="link"  onClick={() => exportNewOpenSurgeryReport(OSPCRReport, month, year, excel)}><Icon name="file alternate"/>Export</a>
                                    <div className="month-year-picker">
                                        <DatePicker
                                            name="date"
                                            className='ui reports-custom-date-input cursorPointer'
                                            dateFormat="MMM yyyy"
                                            showMonthYearPicker={true}
                                            onChange={(date:any) => onChangeDate(date)}
                                            selected={filterDate}
                                            placeholderText={'Select Month'}
                                        />
                                        <Icon className="calendar-icon cursorPointer" name="calendar alternate outline" />
                                    </div>
                                </div>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
            <div>
                {getTableRows()}
                {osModal && <OsModal practiceAbbr={practiceAbbr}  handleClose={closeOSModelModal}/>}
                {osNWDModal && <OsNWDModal practiceInfo={practiceInfo}  sendMailOSPCRPopupDetail ={sendMailOSPCRPopupDetail} handleClose={closeOSNWDModelModal}/>}
            </div>
        </div>
    )
}
function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app,
    };
}
export default connect(mapStateToProps)(NewOSReport);
