import React from "react";
import { Label, Tab } from "semantic-ui-react";
import { ProjectedRevenueDr } from "../model/report";
import { numberFormat } from "./numberFormat";
import { TodaysGoalMTDCalaculations } from "./PMDProduction";

export const MTDCalculations = (budgetTodayGoal: any,general_shifts:any[],hygienists_shifts:any[],pmdDailyCareSummary:any[]) => {    
    let MTDCalculations_a: TodaysGoalMTDCalaculations[] = [];
    let dailyCalculations: TodaysGoalMTDCalaculations = {
      date: 0,
      shifts : 0,
      goal : 0,
      bgt_Catch_Up : 0,
      goalByDay: 0,
      Care : 0,
      MTDGoal: 0,
      MTDCare : 0,
      MTDGoal_To_Act_Care: 0
    };
    let returnObj = {MTDCalculationsObj:MTDCalculations_a,MTDGoal:"0",TodayDate:"",goalByDay_value:"0"};
    MTDCalculations_a.push(dailyCalculations);
    var monthNames = ["January", "February", "March", "April", "May","June","July", "August", "September", "October", "November","December"];
    var todaysDate = new Date().getDate();
    var shifts_completed =0, shifts_left=0, total_monthly_shifts =0;
    for (let i = 1; (i <= getDaysInMonth()); i++) {
      let general_shifts_value = typeof general_shifts[i] === 'undefined'?0:(general_shifts[i] != null ? general_shifts[i] : 0);
      let hygienists_shifts_value = typeof general_shifts[i] === 'undefined'?0:(hygienists_shifts[i] != null ? hygienists_shifts[i] : 0);
      if(i<todaysDate){
        shifts_completed += general_shifts_value;
        shifts_completed += hygienists_shifts_value;
      }
      if(i>=todaysDate){
        shifts_left += general_shifts_value;
        shifts_left += hygienists_shifts_value;
      }
      total_monthly_shifts = shifts_completed+shifts_left;
    }
    let MTDGoal_To_Act_Care_value_Prev = 0;
    for (let i = 1; ((i <= getDaysInMonth())&&(i<=todaysDate)); i++) {
      let date_value:any = i+" "+monthNames[new Date().getMonth()]+" "+(new Date().getFullYear());
      let general_shifts_value:any = typeof general_shifts[i] === 'undefined'?0:(general_shifts[i] != null ? general_shifts[i] : 0);
      let hygienists_shifts_value:any = typeof hygienists_shifts[i] === 'undefined'?0:(hygienists_shifts[i] != null ? hygienists_shifts[i] : 0);
      let shifts_value:any = general_shifts_value+hygienists_shifts_value;
      let goal:any = budgetTodayGoal;
      let total_shifts:any = total_monthly_shifts;
      let goalByDay_value:any = (goal/total_shifts)*(shifts_value != null ? shifts_value : 0);
      let dailyBudget = goalByDay_value;
      let Care_value:any = pmdDailyCareSummary[i];
      let MTDGoal_value:any = ( i==1 ? goalByDay_value : (goalByDay_value+MTDCalculations_a[i-1].MTDGoal));
      let MTDCare_value:any = ( i==1 ? Care_value : Care_value + MTDCalculations_a[i-1].MTDCare);
      let MTDGoal_To_Act_Care_value:any = MTDCare_value-MTDGoal_value;      
      MTDGoal_To_Act_Care_value_Prev>=0?(goalByDay_value=goalByDay_value):(goalByDay_value=goalByDay_value+(-1*((MTDGoal_To_Act_Care_value_Prev/shifts_left)*shifts_value)));
      let bgt_Catch_Up = goalByDay_value-dailyBudget;
      let dailyCalculations: TodaysGoalMTDCalaculations = {
          date: date_value,
          shifts : shifts_value+"/"+total_monthly_shifts,
          goal : dailyBudget,
          bgt_Catch_Up:bgt_Catch_Up,
          goalByDay: shifts_value>0? (parseFloat(goalByDay_value)||0):0,
          Care : parseFloat(Care_value)||0,
          MTDGoal: parseFloat(MTDGoal_value)||0,
          MTDCare : parseFloat(MTDCare_value)||0,
          MTDGoal_To_Act_Care: parseFloat(MTDGoal_To_Act_Care_value)||0
        };

      MTDGoal_To_Act_Care_value_Prev = MTDGoal_To_Act_Care_value;
      MTDCalculations_a.push(dailyCalculations);
      returnObj = {MTDCalculationsObj:MTDCalculations_a,MTDGoal:MTDGoal_value,TodayDate:i+" "+monthNames[new Date().getMonth()],goalByDay_value:goalByDay_value};
    }
    return returnObj;
}

const getDaysInMonth = () => {
    return new Date(new Date().getFullYear(),new Date().getMonth()+1, 0).getDate();
};

export const renderTableHeader = (MTDCalculations:any) => {
    let header = Object.keys(MTDCalculations)
    return header.map((key, index) => {
       return <th key={index}>{key.toUpperCase()}</th>
    })
}

  export const renderTableData = (MTDCalculations:any) => {
    MTDCalculations = MTDCalculations.filter((mtdCalculation: any) => 
      mtdCalculation.Care !== 0 || 
      mtdCalculation.MTDCare !== 0 || 
      mtdCalculation.MTDGoal !== 0 || 
      mtdCalculation.MTDGoal_To_Act_Care !== 0 || 
      mtdCalculation.bgt_Catch_Up !== 0 || 
      mtdCalculation.date !== 0 || 
      mtdCalculation.goal !== 0 || 
      mtdCalculation.goalByDay !== 0 || 
      mtdCalculation.shifts !== 0
    );
    return MTDCalculations.map((mtdCalculation: { date: any; shifts: any; goal: any,  bgt_Catch_Up:any, goalByDay: any; Care: any; MTDGoal: any; MTDCare: any; MTDGoal_To_Act_Care: any; }, index: any) => {
       const { date, shifts, goal, bgt_Catch_Up, goalByDay, Care, MTDGoal, MTDCare, MTDGoal_To_Act_Care} = mtdCalculation
       return (
          <tr key={date}>
             <td>{date}</td>
             <td>{shifts}</td>
             <td>{numberFormat(goal)}</td>
             <td>{numberFormat(bgt_Catch_Up)}</td>
             <td>{numberFormat(goalByDay)}</td>
             <td>{numberFormat(Care)}</td>
             <td>{numberFormat(MTDGoal)}</td>
             <td>{numberFormat(MTDCare)}</td>
             <td>{MTDGoal_To_Act_Care>=0 ? numberFormat(MTDGoal_To_Act_Care) : '('+numberFormat(Math.abs(MTDGoal_To_Act_Care))+')'}</td>
          </tr>
       )
    })
 }

 export const todaysGoalDialog = (MTDCalculations:any,getTodayGoal:number,TodayDate:string,MTDGoal:number,
    Days_Left:number,Days_Complete:number,General_Shifts_Complete:number,Hygiene_Shifts_Complete:number,General_Shifts_Left:number,
    Hygiene_Shifts_Left:number,FV_PT:number,FV_CPV:number,RC_PT:number,RC_CPV:number,TX_PT:number,TX_CPV:number,practicename:string) => {
    return (
      <div>
      
        <div className="visible content">
            <table className="ui very basic collapsing celled table ">
              <thead>
                <tr><th>Todays Goal</th>
                <th>{numberFormat(getTodayGoal)}</th>
              </tr></thead>
              <tbody>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Goal
                        <div className="sub header">Total Value
                      </div>
                    </div>
                  </h4>
                  </td>
                  <td>
                  {numberFormat((FV_PT*FV_CPV)+(RC_PT*RC_CPV)+(TX_PT*TX_CPV))}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      MTD Goal - {TodayDate}
                        <div className="sub header">Total Value
                      </div>
                    </div>
                  </h4></td>
                  <td>
                  {numberFormat(MTDGoal)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Work Days
                        <div className="sub header">Total Days
                      </div>
                    </div>
                  </h4></td>
                  <td>
                    {Days_Left+Days_Complete}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Left
                        <div className="sub header">Work Days Left Only
                      </div>
                    </div>
                  </h4></td>
                  <td>
                    {Days_Left}/{Days_Left+Days_Complete}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Shifts
                        <div className="sub header">Total Shifts
                      </div>
                    </div>
                  </h4></td>
                  <td>
                    {General_Shifts_Complete+Hygiene_Shifts_Complete+General_Shifts_Left+Hygiene_Shifts_Left}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Shifts Left
                        <div className="sub header">Shifts Left Only
                      </div>
                    </div>
                  </h4></td>
                  <td>
                    {General_Shifts_Left+Hygiene_Shifts_Left}/{General_Shifts_Complete+Hygiene_Shifts_Complete+General_Shifts_Left+Hygiene_Shifts_Left}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        
            <table className="ui celled structured table">
              <thead>
                <tr>
                  <th rowSpan={1}>Goal</th>
                  <th rowSpan={1}>Type</th>
                  <th rowSpan={1}>PT Visit</th>
                  <th rowSpan={1}>CPV</th>
                  <th rowSpan={1}>Goal</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3}>Goal</td>
                  <td>FV</td>
                  <td>{FV_PT}</td>
                  <td>{numberFormat(FV_CPV)}</td>
                  <td>{numberFormat(FV_PT*FV_CPV)}</td>
                </tr>
                <tr>
                  <td>RC</td>
                  <td>{RC_PT}</td>
                  <td>{numberFormat(RC_CPV)}</td>
                  <td>{numberFormat(RC_PT*RC_CPV)}</td>
                </tr>
                <tr>
                  <td>TX</td>
                  <td>{TX_PT}</td>
                  <td>{numberFormat(TX_CPV)}</td>
                  <td>{numberFormat(TX_PT*TX_CPV)}</td>
                </tr>
                <tr>
                  <td rowSpan={1}>Total</td>
                  <td></td>
                  <td>{FV_PT+RC_PT+TX_PT}</td>
                  <td>{numberFormat(((FV_PT*FV_CPV)+(RC_PT*RC_CPV)+(TX_PT*TX_CPV))/(FV_PT+RC_PT+TX_PT))}</td>
                  <td>{numberFormat((FV_PT*FV_CPV)+(RC_PT*RC_CPV)+(TX_PT*TX_CPV))}</td>
                </tr>
              </tbody>
            </table>
            
            <div>
            <table className="ui celled structured table">
              <thead>
                <tr>
                  <th >Days</th>
                  <th >Practice</th>
                  <th >Complete</th>
                  <th >Left</th>
                  <th >Total</th>
                </tr>
                <tr>
                  <td rowSpan={1}>Days</td>
                  <td rowSpan={1}>{practicename}</td>
                  <td rowSpan={1}>{Days_Complete}</td>
                  <td rowSpan={1}>{Days_Left}</td>
                  <td rowSpan={1}>{Days_Complete+Days_Left}</td>
                </tr>
              </thead>
            </table>
            </div>

            <table className="ui celled structured table">
              <thead>
                <tr>
                  <th rowSpan={1}>Shifts</th>
                  <th rowSpan={1}>Type</th>
                  <th rowSpan={1}>Shifts Complete</th>
                  <th rowSpan={1}>Shifts Left</th>
                  <th rowSpan={1}>Total Shifts</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3}>Shifts</td>
                  <td className="left aligned">General Shifts</td>
                  <td>{General_Shifts_Complete}</td>
                  <td>{General_Shifts_Left}</td>
                  <td>{General_Shifts_Complete+General_Shifts_Left}</td>
                </tr>
                <tr>
                  <td className="left aligned">Hygiene Shifts</td>
                  <td>{Hygiene_Shifts_Complete}</td>
                  <td>{Hygiene_Shifts_Left}</td>
                  <td>{Hygiene_Shifts_Complete+Hygiene_Shifts_Left}</td>
                </tr>
              </tbody>
            </table>

            <div style={{ fontSize: '10px !important,zoom: 75%,max-width: 2000px' }}>
            <table className="ui striped table mtdclctable" >
              <thead>
                <tr>{renderTableHeader(MTDCalculations[0])}</tr>
              </thead>
              <tbody>                    
                {renderTableData(MTDCalculations)}
              </tbody>
            </table>
            </div>  
        
      </div>
    )
}

export const projectedRevenueDialog = (MTDCalculations:any,General_Shifts_Complete:number,Hygiene_Shifts_Complete:number,General_Shifts_Left:number,
  Hygiene_Shifts_Left:any) => {
  const MTDCalculations_LastObj: { date: string; shifts: string; budget: number,  bgt_Catch_Up:number, goalByDay: number; Care: number; MTDGoal: number; MTDCare: number; MTDGoal_To_Act_Care: number; }=MTDCalculations.slice(-1)[0];
  const projectedCare = MTDCalculations_LastObj.MTDCare/((General_Shifts_Complete+Hygiene_Shifts_Complete)/
  (General_Shifts_Complete+Hygiene_Shifts_Complete+General_Shifts_Left+Hygiene_Shifts_Left));  
  return (
    <div>
      <p>
        <Label color='green'>
          Projected Care
        </Label>
        <b> = </b>
        <Label color='blue'>
          ACT Care MTD
        </Label>
        <b> / </b>
        <Label color='orange'>
          ( Totals Shifts Completed / Total Shifts )
        </Label>
      </p>

      <p>
        <div className="visible content">
          <table className="ui very basic collapsing celled table ">
            <thead>
              <tr><th>Projected Care</th>
              <th>{numberFormat(projectedCare)}</th>
            </tr></thead>
            <tbody>
              <tr>
                <td>
                  <h4 className="ui image header">
                    <div className="content">
                    Actual Care MTD
                      <div className="sub header">Total Value
                    </div>
                  </div>
                </h4>
                </td>
                <td>
                {numberFormat(MTDCalculations_LastObj.MTDCare)}
                </td>
              </tr>
              <tr>
                <td>
                  <h4 className="ui image header">
                    <div className="content">
                    Total Completed Shifts
                      <div className="sub header">Total Value
                    </div>
                  </div>
                </h4></td>
                <td>
                  {General_Shifts_Complete+Hygiene_Shifts_Complete}
                </td>
              </tr>
              <tr>
                <td>
                  <h4 className="ui image header">
                    <div className="content">
                    Total Shifts
                      <div className="sub header">Total Value
                    </div>
                  </div>
                </h4></td>
                <td>
                {General_Shifts_Complete+Hygiene_Shifts_Complete+General_Shifts_Left+Hygiene_Shifts_Left}
                </td>
              </tr>                         
            </tbody>
          </table>
        </div>
      </p>

          <table className="ui celled structured table">
            <thead>
              <tr>
                <th rowSpan={1}>Shifts</th>
                <th rowSpan={1}>Type</th>
                <th rowSpan={1}>Shifts Complete</th>
                <th rowSpan={1}>Shifts Left</th>
                <th rowSpan={1}>Total Shifts</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={3}>Shifts</td>
                <td className="left aligned">General Shifts</td>
                <td>{General_Shifts_Complete}</td>
                <td>{General_Shifts_Left}</td>
                <td>{General_Shifts_Complete+General_Shifts_Left}</td>
              </tr>
              <tr>
                <td className="left aligned">Hygiene Shifts</td>
                <td>{Hygiene_Shifts_Complete}</td>
                <td>{Hygiene_Shifts_Left}</td>
                <td>{Hygiene_Shifts_Complete+Hygiene_Shifts_Left}</td>
              </tr>
            </tbody>
          </table>

          <div style={{ fontSize: '10px !important,zoom: 75%,max-width: 2000px' }}>
          <table className="ui striped table mtdclctable" >
            <thead>
              <tr>{renderTableHeader(MTDCalculations[0])}</tr>
            </thead>
            <tbody>                    
              {renderTableData(MTDCalculations)}
            </tbody>
          </table>
          </div>  
      
    </div>
  )
}

export const projectedRevenueDrDialog = (data:ProjectedRevenueDr)=> {
  const projectedCare = data.ActAllMTD/(data.shiftsCompleted/(data.shiftsCompleted+data.shiftsLeft));  
  return (
    <div>
      <p>
        <Label color='green'>
          Projected Care
        </Label>
        <b> = </b>
        <Label color='blue'>
          ACT Care MTD
        </Label>
        <b> / </b>
        <Label color='orange'>
          ( Totals Shifts Completed / Total Shifts )
        </Label>
      </p>

      <p>
        <div className="visible content">
          <table className="ui very basic collapsing celled table ">
            <thead>
              <tr><th>Projected Care</th>
              <th>{numberFormat(projectedCare)}</th>
            </tr></thead>
            <tbody>
              <tr>
                <td>
                  <h4 className="ui image header">
                    <div className="content">
                    Actual Care MTD
                      <div className="sub header">Total Value
                    </div>
                  </div>
                </h4>
                </td>
                <td>
                {numberFormat(data.ActAllMTD)}
                </td>
              </tr>
              <tr>
                <td>
                  <h4 className="ui image header">
                    <div className="content">
                    Total Shifts Completed
                      <div className="sub header">Total Value
                    </div>
                  </div>
                </h4></td>
                <td>
                  {data.shiftsCompleted}
                </td>
              </tr>
              <tr>
                <td>
                  <h4 className="ui image header">
                    <div className="content">
                    Total Shifts
                      <div className="sub header">Total Value
                    </div>
                  </div>
                </h4></td>
                <td>
                {data.shiftsCompleted+data.shiftsLeft}
                </td>
              </tr>                         
            </tbody>
          </table>
        </div>
      </p>      
    </div>
  )
}

export const excludedCareDialog = (excludedCareObj: any) => {
  // Safely handle null values in excludedCareObj
  const safeExcludedCareObj = {
    missedAptValue: excludedCareObj?.missedAptValue || 0,
    missedAptData: excludedCareObj?.missedAptData || [],
    brokenAptValue: excludedCareObj?.brokenAptValue || 0,
    brokenAptData: excludedCareObj?.brokenAptData || [],
    oralSurgeryValue: excludedCareObj?.oralSurgeryValue || 0,
    oralSurgeryData: excludedCareObj?.oralSurgeryData || [],
    orthoCodesValue: excludedCareObj?.orthoCodesValue || 0,
    orthoCodesData: excludedCareObj?.orthoCodesData || [],
    billingCodesValue: excludedCareObj?.billingCodesValue || 0,
    billingCodesData: excludedCareObj?.billingCodesData || [],
    excludedCareValue: excludedCareObj?.excludedCareValue || 0,
    excludedCareData: excludedCareObj?.excludedCareData || [],
  };

  // Data rows for the summary table
  const tableRows = [
    { label: 'Missed Appointments', value: safeExcludedCareObj.missedAptValue, data: safeExcludedCareObj.missedAptData },
    { label: 'Broken Appointments', value: safeExcludedCareObj.brokenAptValue, data: safeExcludedCareObj.brokenAptData },
    { label: 'Oral Surgery', value: safeExcludedCareObj.oralSurgeryValue, data: safeExcludedCareObj.oralSurgeryData },
    { label: 'Ortho Codes', value: safeExcludedCareObj.orthoCodesValue, data: safeExcludedCareObj.orthoCodesData },
    { label: 'Billing Codes', value: safeExcludedCareObj.billingCodesValue, data: safeExcludedCareObj.billingCodesData },
    { label: 'Total Excluded Care', value: safeExcludedCareObj.excludedCareValue, data: safeExcludedCareObj.excludedCareData },
  ];

  // Create tabs dynamically based on tableRows
  const panes = [
    {
      menuItem: {
        key: 'summary',
        content: (
          <div className="pcr_tbl_numbers_clickable">
            Summary
          </div>
        ),
      },
      render: () => (
        <Tab.Pane>
          <div className="visible-content" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
            <table className="ui very basic collapsing celled table" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>Excluded Care</th>
                  <th style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>Care</th>
                </tr>
              </thead>
              <tbody>
                {tableRows.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <h4 className="ui image header">
                        <div className="content">{row.label}</div>
                        <div className="sub header">Amount</div>
                      </h4>
                    </td>
                    <td>
                      {numberFormat(row.value)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Tab.Pane>
      ),
    },
    ...tableRows
      .filter((row) => row.data && row.data.length > 0) // Only include rows with data
      .map((row, index) => ({
        menuItem: {
          key: row.label.toLowerCase().replace(/ /g, '-'),
          content: (
            <div className="pcr_tbl_numbers_clickable">
              {row.label}
            </div>
          ),
        },
        render: () => (
          <Tab.Pane>
            <div className="visible-content" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
              <table className="ui very basic collapsing celled table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>Category</th>
                    <th style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>Patient Number</th>
                    <th style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>Procedure Date</th>
                    <th style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>Proc Fee</th>
                    <th style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>Doctor Name</th>
                    <th style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>Proc Code</th>
                    <th style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>Visit Type</th>
                    <th style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>Age</th>
                    <th style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>Carrier Name</th>
                  </tr>
                </thead>
                <tbody>
                  {row.data.map((item: any, index: any) => (
                    <tr key={index}>
                      <td>{item.ItemName || ''}</td>
                      <td>{item.PatNum || ''}</td>
                      <td>{item.ProcDate || ''}</td>
                      <td>{numberFormat(item.Procfee || 0)}</td>
                      <td>{item.Dr_Name || ''}</td>
                      <td>{item.ProcCode || ''}</td>
                      <td>{item.visitType || ''}</td>
                      <td>{item.age || ''}</td>
                      <td>{item.CarrierName || ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Tab.Pane>
        ),
      })),
  ];

  return (
    <div>
      <Tab
        panes={panes}
        menu={{ pointing: true, secondary: true }} // Add styling to the tab menu
        renderActiveOnly={true} // Only render the active tab for better performance
      />
    </div>
  );
};