import React, {useEffect, useState} from "react";
import {Button, Card, Dropdown, Form, Input, Label, Modal, Table} from "semantic-ui-react";
import {INPUT_TYPES, VARIABLE_NAMES} from "../../constants";
import {useDispatch} from "react-redux";
import * as doctorVariableActions from "../../redux/actions/doctorVariables";
import * as providerActions from "../../redux/actions/provider";

const ProviderRatesVariablesModal: React.FC<{ variable: any, onClose: Function }> = (props) => {
    const dispatch = useDispatch();
    const [variable, setVariable] = useState(props.variable);
    const [isLoading, setIsLoading] = useState(false);
    const [option, setOption] = useState<any>({});

    const saveVariable = async () => {
        setIsLoading(true);
        await dispatch(doctorVariableActions.saveVariable(variable));
        setIsLoading(false);
        props.onClose();
    }

    const onChange = (name: string, value: string) => {
        setVariable({...variable, [name]: value});
    }

    const removeOption = (index: number) => {
        setVariable({...variable, options: (variable.options as any[]).filter((_, i) => i !== index)});
    }

    const onChangeOption = (name: string, value: string) => {
        setOption({...option, [name]: value});
    }

    const addOption = () => {
        setVariable({...variable, options: (variable.options as any[] ?? []).concat([option])});
        setOption({});
    }

    return <Modal open={true} onClose={() => props.onClose()}>
        <Modal.Content className="p-5">
            <Card>
                <Card.Content>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Label</label>
                                <Input value={variable.label} onChange={(_, v) => onChange('label', v.value)}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Name</label>
                                <Input value={variable.name} onChange={(_, v) => onChange('name', v.value)}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Input Type</label>
                                <Dropdown
                                    value={variable.inputType}
                                    selection={true}
                                    options={INPUT_TYPES}
                                    onChange={(_, v) => onChange('inputType', v.value as string)}
                                />
                            </Form.Field>
                        </Form.Group>
                        <br/>
                        {
                            variable.inputType === "select" && (<>
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell content="Text"/>
                                            <Table.HeaderCell content="Value"/>
                                            <Table.HeaderCell/>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            (variable.options as any[])?.map((option, i) => <Table.Row key={i + option.value}>
                                                <Table.Cell>{option.text}</Table.Cell>
                                                <Table.Cell>{option.value}</Table.Cell>
                                                <Table.Cell><Button color="red" content="Remove" size="small" onClick={() => removeOption(i)}/></Table.Cell>
                                            </Table.Row>)
                                        }

                                        <Table.Row>
                                            <Table.Cell><Input value={option.text} onChange={(_, v) => onChangeOption('text', v.value)}/></Table.Cell>
                                            <Table.Cell><Input value={option.value} onChange={(_, v) => onChangeOption('value', v.value)}/></Table.Cell>
                                            <Table.Cell><Button color="green" content="Add" size="small" onClick={() => addOption()}/></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </>)
                        }
                    </Form>
                </Card.Content>
            </Card>
        </Modal.Content>
        <Modal.Actions>
            <Button loading={isLoading} primary={true} onClick={saveVariable}>Save</Button>
        </Modal.Actions>
    </Modal>;
}

export default ProviderRatesVariablesModal;