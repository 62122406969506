import React from "react";
import Template from "../components/Template";
import ProviderRates from "../components/ProviderRates/ProviderRates";
import {Tab, TabPane} from "semantic-ui-react";
import ProviderRatesVariables from "../components/ProviderRates/ProviderRatesVariables";

function ProviderRatesTracker() {
    const panes = [
        {
            menuItem: 'Rates',
            render: () => <TabPane className="tabContent" attached={false}><ProviderRates/></TabPane>,
        },
        {
            menuItem: 'Variables',
            render: () => <TabPane className="tabContent" attached={false}><ProviderRatesVariables/></TabPane>,
        },
    ]
    return (
        <Template activeLink='providerRatesTracker'>
            <h2 className="mr10">Provider Rates Tracker</h2>
            <Tab menu={{ pointing: true }} panes={panes} />
        </Template>
    );

}

export default ProviderRatesTracker;