import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Dropdown, Form, Icon, Label, Table,} from "semantic-ui-react";
import *  as doctorVariableActions from "../../redux/actions/doctorVariables"
import * as providerActions from "../../redux/actions/provider";
import ProviderRatesAddForm from "./ProviderRatesAddForm";


const ProviderRates = () => {
    const [selectedVariables, setSelectedVariables] = useState<any[]>([]);
    const [currentProvider, setCurrentProvider] = useState(undefined);
    const [pageData, setPageData] = useState({} as any)
    const [providerOptions, setProviderOptions] = useState<any[]>([]);
    const [udaDoctors, setUdaDoctors] = useState<any[]>([]);
    const [variables, setVariables] = useState<any[]>([]);
    const dispatch = useDispatch();


    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const results: any[] = await Promise.all([
            dispatch(doctorVariableActions.fetchDoctorVariables()),
            dispatch(providerActions.getProviderListOptions()),
            dispatch(providerActions.getUdaDoctors()),
            dispatch(doctorVariableActions.fetchVariables())
        ])
        setPageData(results[0]);
        setProviderOptions(results[1]);
        setUdaDoctors(results[2]);
        setVariables(results[3]);
        setSelectedVariables(results[3].slice(0, 15).map((v: any) => v.name));
    }

    const handleSaveProvider = async (data: any) => {
        setCurrentProvider(undefined);
        await dispatch(doctorVariableActions.saveDoctorVariable(data));
        loadData();
    };

    const handleProvideClick = (provider: any) => {
        const defaults = udaDoctors.find((item) => item.UserId === provider.userId) ?? {};
        const form = {...defaults, ...provider};
        const data = variables.reduce((acc: any, v: any) => {
            const item = (pageData[provider.id] ?? {})[v.id] ?? {};
            acc[v.id] = item.value ?? form[v.name];
            form.from = item.from ? new Date(item.from) : undefined;
            form.to = item.to ? new Date(item.to) : undefined;
            return acc;
        }, {});

        setCurrentProvider({...form, variables: data});
    }

    if (!pageData) return <></>

    return (
        <div>
            <Form>
                <Form.Field>
                    <label>Show Variables</label>
                    <Dropdown
                        placeholder='Exclude Variables'
                        fluid={true}
                        search={true}
                        selection={true}
                        multiple={true}
                        options={variables.map((v) => ({text: v.label, value: v.name}))}
                        onChange={(_, d) => setSelectedVariables(d.value as string[])}
                        value={selectedVariables}
                    />
                </Form.Field>
            </Form>

            <Table compact="very" striped={true} celled={true} fixed={true} size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={2}><Icon name="pin"/> Provider</Table.HeaderCell>
                        {variables.filter(f => selectedVariables.includes(f.name)).map(({name, label}) =>
                            <Table.HeaderCell key={name}>
                                <span title={label} className="table-ellipsis text-uppercase">{label}</span>
                            </Table.HeaderCell>)}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        providerOptions.map((provider, index) => (<Table.Row key={index + provider.key}>
                            <Table.Cell>
                                <Label className="cursorPointer" onClick={() => handleProvideClick(provider)}>
                                    <Icon name="edit outline"/>{provider.text}
                                </Label>
                            </Table.Cell>
                            {variables.filter(f => selectedVariables.includes(f.name)).map(({name, label, id}) => {
                                    return <Table.Cell key={name}>
                                        <span title={label} className="table-ellipsis">
                                            {(pageData[provider.id] ?? {})[id]?.value ?? '-'}
                                        </span>
                                    </Table.Cell>
                                }
                            )}
                        </Table.Row>))
                    }
                </Table.Body>
            </Table>

            {
                currentProvider &&
                <ProviderRatesAddForm
                    handleModalClose={() => setCurrentProvider(undefined)}
                    currentProvider={currentProvider}
                    handleSaveProvider={handleSaveProvider}
                    variables={variables}
                />
            }
        </div>
    );
};

export default ProviderRates;
