import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    Header,
    Icon,
    Message,
    TextArea,
    Form,
    Input,
    Checkbox,
    Card,
    Label,
    Menu
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Template from '../../components/Template';
import HelpMark from '../../components/HelpMark';
import * as pdfPreviewActions from "../../redux/actions/pdfPreviewActions";
import * as cronEmailSettingsActions from "../../redux/actions/cronEmailSettings";
import { useActions } from '../../redux/actions';
import '../../styles/css/PDFPreview.css';

interface S3Object {
    key: string;
    type: 'folder' | 'file';
    name: string;
    lastModified?: string;
    size?: number;
    hasError?: boolean;
}

interface EmailAttachment {
    fileKey: string;
    fileName: string;
    htmlContent: string;
    approved: boolean;
}

interface CronEmailSetting {
    id: number;
    cronId: number;
    group: string;
    description: string;
    emails: string;
    email_body?: string;
}

interface EmailRecipient {
    email: string;
    valid: boolean;
}

const REPORT_TYPES = ['PCR', 'OSPCR', 'ORTHOPCR', 'MONTHEND_PCR'];
const CRON_IDS = {
    PCR: 203,
    OSPCR: 29,
    ORTHOPCR: 201,
    MONTHEND_PCR: 220
};

function PDFPreviewPage() {
    const [state, setState] = useState({
        activeTab: 0,
        files: [] as S3Object[],
        selectedFile: null as S3Object | null,
        fileContent: '',
        previewOpen: false,
        attachments: [] as EmailAttachment[],
        emailSubject: '',
        emailBody: '',
        emailRecipientsInput: '',
        recipients: [] as EmailRecipient[],
        message: null as { positive?: boolean; negative?: boolean; warning?: boolean; content: string } | null,
        isLoading: false,
        isSending: false,
        selectedDate: new Date(),
        showSendConfirmation: false,
        fetchingEmailSettings: false,
        emailSettingsError: null as string | null,
        fileHasError: false
    });

    const [zoomLevel, setZoomLevel] = useState(1);
    const pdfPreviewAction = useActions(pdfPreviewActions);
    const cronEmailSettingsAction = useActions(cronEmailSettingsActions);

    const formatDateForPath = (date: Date) => {
        return `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}`;
    };

    useEffect(() => {
        if (state.message) {
            const timer = setTimeout(() => {
                setState(prev => ({ ...prev, message: null }));
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [state.message]);

    const isValidEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const markFileAsError = () => {
        if (!state.selectedFile) return;

        setState(prev => ({
            ...prev,
            files: prev.files.map(f =>
                f.key === prev.selectedFile?.key ? { ...f, hasError: true } : f
            ),
            previewOpen: false,
            message: { warning: true, content: 'File marked as having errors' }
        }));
    };

    const fetchEmailSettings = async (cronId: number) => {
        try {
            setState(prev => ({
                ...prev,
                fetchingEmailSettings: true,
                emailSettingsError: null
            }));

            const response = await cronEmailSettingsAction.getCronEmailSettingsByCronId(cronId);

            if (response && response.length) {
                const emailSettings = response[0];
                const emailsArray = emailSettings.emails
                    ? emailSettings.emails.split(',').map((email: string) => ({
                        email: email.trim(),
                        valid: true
                    }))
                    : [];

                setState(prev => ({
                    ...prev,
                    recipients: emailsArray,
                    emailSubject: emailSettings.group || `${REPORT_TYPES[state.activeTab]} Reports`,
                    emailBody: emailSettings.email_body || ''
                }));
            }
        } catch (error) {
            console.error('Error fetching email settings:', error);
            setState(prev => ({
                ...prev,
                emailSettingsError: 'Failed to load email settings'
            }));
        } finally {
            setState(prev => ({ ...prev, fetchingEmailSettings: false }));
        }
    };

    useEffect(() => {
        const loadData = async () => {
            const currentCronId = CRON_IDS[REPORT_TYPES[state.activeTab] as keyof typeof CRON_IDS];
            await fetchEmailSettings(currentCronId);
            await handleTabChange(null, { activeIndex: state.activeTab });
        };

        loadData();
    }, [state.activeTab, state.selectedDate]);

    const handleTabChange = async (e: any, { activeIndex }: any) => {
        setState(prev => ({ ...prev, activeTab: activeIndex, isLoading: true }));

        try {
            const formattedDate = formatDateForPath(state.selectedDate);
            const reportType = REPORT_TYPES[activeIndex];
            const path = `${formattedDate}/${reportType.toUpperCase()}/`;

            const response = await pdfPreviewAction.listS3Objects(path);

            if (!response?.success) {
                throw new Error('Failed to fetch files');
            }

            const objects = response?.objects || [];
            const htmlFiles = objects.filter((obj: S3Object) =>
                obj.type === 'file' && obj.name.toLowerCase().endsWith('.html.gz')
            ).sort((a: any, b: any) => a.name.localeCompare(b.name));

            setState(prev => ({
                ...prev,
                files: htmlFiles,
                isLoading: false,
                message: htmlFiles.length === 0 ? {
                    warning: true,
                    content: `No ${reportType} reports found for ${state.selectedDate.toLocaleDateString()}`
                } : null
            }));
        } catch (error) {
            console.error('Error loading reports:', error);
            setState(prev => ({
                ...prev,
                isLoading: false,
                message: {
                    negative: true,
                    content: `Failed to load reports. Please check the path.`
                }
            }));
        }
    };

    const handleDateChange = (date: Date) => {
        setState(prev => ({ ...prev, selectedDate: date }));
    };

    const processHtmlContent = (content: string) => {
        let cleaned = content.replace(/^"+|"+$/g, '');
        cleaned = cleaned.replace(/\\"/g, '"');
        cleaned = cleaned.replace(/\\n/g, '');
        cleaned = cleaned.replace(/\\t/g, '');
        return cleaned;
    };

    const checkForFileErrors = (content: string): boolean => {
        // Implement your actual error detection logic here
        return content.includes('error') ||
            content.includes('exception') ||
            content.includes('failed');
    };

    const loadFileContent = async (file: S3Object) => {
        setState(prev => ({ ...prev, isLoading: true, selectedFile: file }));

        try {
            const response = await pdfPreviewAction.getS3Object(file.key);
            const data = response?.data || response;

            if (!data?.content) {
                throw new Error('File content is empty');
            }

            const hasError = checkForFileErrors(data.content);

            setState(prev => ({
                ...prev,
                fileContent: processHtmlContent(data.content),
                previewOpen: true,
                isLoading: false,
                fileHasError: hasError
            }));

            if (hasError) {
                setState(prev => ({
                    ...prev,
                    files: prev.files.map(f =>
                        f.key === file.key ? { ...f, hasError: true } : f
                    )
                }));
            }

        } catch (error) {
            console.error('Error loading file:', error);
            setState(prev => ({
                ...prev,
                isLoading: false,
                message: { negative: true, content: 'Failed to load file content' },
                fileHasError: true
            }));
        }
    };

    const addToAttachments = () => {
        if (!state.selectedFile || !state.fileContent) return;

        const existingIndex = state.attachments.findIndex(
            a => a.fileKey === state.selectedFile?.key
        );

        const newAttachment: EmailAttachment = {
            fileKey: state.selectedFile.key,
            fileName: state.selectedFile.name,
            htmlContent: state.fileContent,
            approved: true
        };

        const attachments = existingIndex >= 0
            ? state.attachments.map((a, i) => i === existingIndex ? newAttachment : a)
            : [...state.attachments, newAttachment];

        setState(prev => ({
            ...prev,
            files: prev.files.map(f =>
                f.key === prev.selectedFile?.key ? { ...f, hasError: false } : f
            ),
            attachments,
            previewOpen: false,
            message: { positive: true, content: 'Added to email attachments' }
        }));
    };

    const removeFromAttachments = (fileKey: string) => {
        setState(prev => ({
            ...prev,
            attachments: prev.attachments.filter(a => a.fileKey !== fileKey),
            message: { warning: true, content: 'Attachment removed' }
        }));
    };

    const handleRecipientsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(prev => ({ ...prev, emailRecipientsInput: e.target.value }));
    };

    const handleRecipientsKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (['Enter', 'Tab', ','].includes(e.key)) {
            e.preventDefault();

            const email = state.emailRecipientsInput.trim();

            if (email) {
                const isValid = isValidEmail(email);
                setState(prev => ({
                    ...prev,
                    recipients: [...prev.recipients, { email, valid: isValid }],
                    emailRecipientsInput: ''
                }));
            }
        }
    };

    const handleRecipientsPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();

        const paste = e.clipboardData.getData('text');
        const emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

        if (emails) {
            const newRecipients = emails.map(email => ({
                email: email.trim(),
                valid: true
            }));

            setState(prev => ({
                ...prev,
                recipients: [...prev.recipients, ...newRecipients],
                emailRecipientsInput: ''
            }));
        }
    };

    const removeRecipient = (index: number) => {
        setState(prev => {
            const newRecipients = [...prev.recipients];
            newRecipients.splice(index, 1);
            return { ...prev, recipients: newRecipients };
        });
    };

    const confirmSendEmail = () => {
        if (state.attachments.filter(a => a.approved).length === 0) {
            setState(prev => ({ ...prev, message: { negative: true, content: 'No approved attachments to send' } }));
            return;
        }
        setState(prev => ({ ...prev, showSendConfirmation: true }));
    };

    const sendEmail = async () => {
        try {
            setState(prev => ({ ...prev, isSending: true, showSendConfirmation: false }));

            const emailRequest = {
                subject: state.emailSubject,
                body: state.emailBody,
                recipients: state.recipients.filter(r => r.valid).map(r => r.email),
                attachments: state.attachments
                    .filter(a => a.approved)
                    .map(attachment => ({
                        fileName: attachment.fileName,
                        htmlContent: attachment.htmlContent
                    }))
            };

            await pdfPreviewAction.sendEmailWithAttachments(emailRequest);

            const currentCronId = CRON_IDS[REPORT_TYPES[state.activeTab] as keyof typeof CRON_IDS];

            setState(prev => ({
                ...prev,
                isSending: false,
                attachments: [],
                selectedFile: null,
                fileContent: '',
                previewOpen: false,
                message: {
                    positive: true,
                    content: 'Email sent successfully'
                }
            }));

            await fetchEmailSettings(currentCronId);
            await handleTabChange(null, { activeIndex: state.activeTab });

        } catch (error) {
            console.error('Error sending email:', error);
            setState(prev => ({
                ...prev,
                isSending: false,
                message: { negative: true, content: 'Failed to send email' }
            }));
        }
    };

    const formatFileName = (fileName: string) => {
        if (!fileName) return '';
        let formatted = fileName.replace('.html.gz', '');
        formatted = formatted.replace(/\d{8}$/, '');
        return formatted.trim();
    };

    const formatFileSize = (bytes: number) => {
        if (!bytes) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const handleZoomIn = () => {
        setZoomLevel(prev => Math.min(prev + 0.1, 2));
    };

    const handleZoomOut = () => {
        setZoomLevel(prev => Math.max(prev - 0.1, 0.5));
    };

    const handleZoomReset = () => {
        setZoomLevel(1);
    };

    const isFileAttached = (fileKey: string) => {
        return state.attachments.some(a => a.fileKey === fileKey);
    };

    const formatDisplayDate = (date: Date) => {
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };

    return (
        <Template activeLink="pdf-preview">
            <div className="pdf-preview-container">
                <Card fluid className="header-card">
                    <Card.Content>
                        <div className="header-content">
                            <h2 className="float-left">Report Preview & Email Tool <HelpMark pageId='pdf-preview' /></h2>

                            {state.message && (
                                <div className="notification-popup">
                                    <Message
                                        floating
                                        positive={state.message.positive}
                                        negative={state.message.negative}
                                        warning={state.message.warning}
                                        onDismiss={() => setState(prev => ({ ...prev, message: null }))}
                                        content={state.message.content}
                                        style={{
                                            margin: 0,
                                            width: '100%'
                                        }}
                                    />
                                </div>
                            )}

                            <div className="topFilters">
                                <DatePicker
                                    selected={state.selectedDate}
                                    onChange={handleDateChange}
                                    maxDate={new Date()}
                                    dateFormat="yyyy-MM-dd"
                                    className="date-picker-input"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Select Date"
                                />
                            </div>
                        </div>
                    </Card.Content>
                </Card>

                <Menu pointing secondary className="report-type-menu">
                    {REPORT_TYPES.map((type, index) => (
                        <Menu.Item
                            key={type}
                            name={type}
                            className="pcr_tbl_numbers_clickable"
                            active={state.activeTab === index}
                            onClick={(e, data) => handleTabChange(e, { activeIndex: index })}
                        />
                    ))}
                </Menu>

                <Card fluid className="content-card">
                    <Card.Content className="content-wrapper">
                        <div className="columns-container">
                            <div className="documents-column">
                                <div className="section-header">
                                    <Header as="h2">
                                        Available Reports for {formatDisplayDate(state.selectedDate)}
                                        <div className="float-right">
                                            {state.files.length > 0 && (
                                                <Label circular color="blue">
                                                    {state.files.length}
                                                </Label>
                                            )}
                                        </div>
                                    </Header>
                                </div>
                                <div className="document-list">
                                    {state.files.length > 0 ? (
                                        <ul className="file-list">
                                            {state.files.map(file => (
                                                <li
                                                    key={file.key}
                                                    className={`file-item ${isFileAttached(file.key) ? 'verified attached' : ''} ${file.hasError ? 'error-file' : ''}`}
                                                    onClick={() => loadFileContent(file)}
                                                >
                                                    <div className="file-icon">
                                                        <Icon
                                                            name={file.hasError ? "warning sign" : isFileAttached(file.key) ? "lock" : "file code outline"}
                                                            color={file.hasError ? "red" : isFileAttached(file.key) ? "green" : undefined}
                                                        />
                                                    </div>
                                                    <div className="file-details">
                                                        <div className="file-name">
                                                            {formatFileName(file.name)}
                                                            {file.hasError && <span className="error-badge">Error</span>}
                                                            {isFileAttached(file.key) && <span className="verified-badge">Verified</span>}
                                                        </div>
                                                        <div className="file-meta">
                                                            <span>{formatFileSize(file.size || 0)}</span>
                                                            {file.lastModified && (
                                                                <span>{new Date(file.lastModified).toLocaleTimeString()}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {isFileAttached(file.key) && (
                                                        <div className="attachment-badge">
                                                            <Icon name="check" color="green" />
                                                        </div>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <Message info>
                                            <Icon name="info circle" />
                                            No reports found for {REPORT_TYPES[state.activeTab]} on {state.selectedDate.toLocaleDateString()}
                                        </Message>
                                    )}
                                </div>
                            </div>

                            <div className="email-column">
                                <div className="email-container">
                                    <div className="section-header">
                                        <Header as="h2">
                                            Email Composition
                                            {state.fetchingEmailSettings && (
                                                <Icon name="circle notched" loading style={{ marginLeft: '10px' }} />
                                            )}
                                        </Header>
                                    </div>

                                    {state.emailSettingsError && (
                                        <Message negative>
                                            {state.emailSettingsError}
                                        </Message>
                                    )}

                                    <Form className="email-form">
                                        <Form.Field>
                                            <label>Recipients</label>
                                            <div className="recipients-input-container">
                                                {state.recipients.map((recipient, index) => (
                                                    <div key={index} className={`recipient-chip ${!recipient.valid ? 'invalid' : ''}`}>
                                                        {recipient.email}
                                                        <Icon
                                                            name="close"
                                                            onClick={() => removeRecipient(index)}
                                                            className="recipient-remove-icon"
                                                        />
                                                    </div>
                                                ))}
                                                <Input
                                                    transparent
                                                    value={state.emailRecipientsInput}
                                                    onChange={handleRecipientsChange}
                                                    onKeyDown={handleRecipientsKeyDown}
                                                    onPaste={handleRecipientsPaste}
                                                    placeholder={state.recipients.length === 0 ? "email1@example.com, email2@example.com" : ""}
                                                    className="recipients-input"
                                                />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Subject</label>
                                            <Input
                                                value={state.emailSubject}
                                                onChange={(e, { value }) => setState(prev => ({ ...prev, emailSubject: value }))}
                                                placeholder={`${REPORT_TYPES[state.activeTab]} Reports`}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Body</label>
                                            <TextArea
                                                value={state.emailBody}
                                                onChange={(e, { value }) => setState(prev => ({ ...prev, emailBody: value as string }))}
                                                placeholder="Enter your email message here..."
                                                rows={5}
                                            />
                                        </Form.Field>
                                    </Form>

                                    <div className="attachments-section">
                                        <Header as="h4">
                                            Attachments ({state.attachments.filter(a => a.approved).length} approved)
                                        </Header>
                                        {state.attachments.length > 0 ? (
                                            <ul className="attachments-list">
                                                {state.attachments.map(attachment => (
                                                    <li key={attachment.fileKey} className="attachment-item">
                                                        <Checkbox
                                                            checked={attachment.approved}
                                                            onChange={(e, { checked }) => setState(prev => ({
                                                                ...prev,
                                                                attachments: prev.attachments.map(a =>
                                                                    a.fileKey === attachment.fileKey ? { ...a, approved: !!checked } : a
                                                                )
                                                            }))}
                                                            label={formatFileName(attachment.fileName)}
                                                        />
                                                        <Icon
                                                            name="trash"
                                                            onClick={() => removeFromAttachments(attachment.fileKey)}
                                                            link
                                                            color="red"
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <Message info>
                                                <Icon name="info circle" />
                                                No attachments added yet
                                            </Message>
                                        )}
                                    </div>

                                    <div className="send-button-container">
                                        <Button
                                            primary
                                            onClick={confirmSendEmail}
                                            loading={state.isSending}
                                            disabled={state.attachments.filter(a => a.approved).length === 0}
                                        >
                                            <Icon name="send" /> Send Email
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Content>
                </Card>

                {/* Preview Modal - Fullscreen with File Name Header */}
                <Modal
                    open={state.previewOpen}
                    onClose={() => setState(prev => ({ ...prev, previewOpen: false }))}
                    className="pdf-preview-modal fullscreen-modal"
                    closeIcon
                    dimmer="blurring"
                >
                    <Modal.Header>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon name="file code outline" />
                            <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                                {state.selectedFile ? formatFileName(state.selectedFile.name) : 'Document Preview'}
                            </span>
                        </div>
                    </Modal.Header>
                    <Modal.Content className="fullscreen-modal-content">
                        <div className="pdf-zoom-controls">
                            <Button.Group size="small">
                                <Button onClick={handleZoomOut} icon="zoom-out" />
                                <Button onClick={handleZoomReset} content="Reset" />
                                <Button onClick={handleZoomIn} icon="zoom-in" />
                                <Button content={`${Math.round(zoomLevel * 100)}%`} />
                            </Button.Group>
                        </div>
                        <div className="pdf-iframe-container">
                            <iframe
                                srcDoc={`
                                <!DOCTYPE html>
                                <html>
                                <head>
                                    <style>
                                    body {
                                        font-family: Arial, Helvetica, sans-serif;
                                        font-size: ${14 * zoomLevel}px;
                                        margin: 0;
                                        padding: 20px;
                                    }
                                    table {
                                        width: 100% !important;
                                        font-size: ${12 * zoomLevel}px !important;
                                    }
                                    </style>
                                </head>
                                <body class="pdf-content-wrapper">
                                    ${state.fileContent}
                                </body>
                                </html>
                            `}
                                style={{
                                    transform: `scale(${zoomLevel})`,
                                    width: `${100 / zoomLevel}%`,
                                    height: `${100 / zoomLevel}%`,
                                }}
                                sandbox="allow-same-origin"
                            />
                        </div>
                    </Modal.Content>
                    <Modal.Actions className="fullscreen-modal-actions">
                        <Button
                            color="red"
                            onClick={markFileAsError}
                        >
                            <Icon name="warning" /> File Has Errors
                        </Button>
                        <Button
                            color="green"
                            onClick={addToAttachments}
                            disabled={isFileAttached(state.selectedFile?.key || '')}
                        >
                            <Icon name="paperclip" />
                            {isFileAttached(state.selectedFile?.key || '') ? 'Already Added' : 'Add to Email'}
                        </Button>
                        <Button onClick={() => setState(prev => ({ ...prev, previewOpen: false }))}>
                            <Icon name="close" /> Close
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Modal
                    size='mini'
                    open={state.showSendConfirmation}
                    onClose={() => setState(prev => ({ ...prev, showSendConfirmation: false }))}
                >
                    <Modal.Header>Confirm Email Send</Modal.Header>
                    <Modal.Content>
                        <p>Are you sure you want to send this email with {state.attachments.filter(a => a.approved).length} attachments to {state.recipients.length} recipients?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => setState(prev => ({ ...prev, showSendConfirmation: false }))}>
                            Cancel
                        </Button>
                        <Button positive onClick={sendEmail} loading={state.isSending}>
                            Send
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </Template>
    );
}

export default PDFPreviewPage;