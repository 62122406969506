import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Container,
    Dimmer,
    Dropdown,
    Form,
    Grid,
    Header,
    Icon,
    Input,
    Label,
    Loader,
    Message,
    Pagination,
    Segment,
    Table
} from "semantic-ui-react";
import { connect } from "react-redux";
import { RootState } from "../../model";
import { useActions } from "../../redux/actions";
import * as moment from "moment";
import * as QueryLoggingActions from "../../redux/actions/queryLogging";
import Template from "../Template";

interface QueryLoggingProps {
    user: any;
    app: any;
    alert: any;
}

type QueryData = {
    id: number;
    query_text: string;
    execution_time: number;
    timestamp: string;
    status: string;
    source_location?: string;
};

type PaginationData = {
    page: number;
    total_pages: number;
    total_records: number;
    per_page: number;
};

type ApiResponse = {
    status: string;
    data: QueryData[];
    pagination: PaginationData;
};

const QueryLogging: React.FC<QueryLoggingProps> = (props) => {
    const toolingEndpoint = "https://tooling.cdp.dental";
    const queryLoggingActions = useActions(QueryLoggingActions);
    const [state, setState] = useState({
        queries: [] as QueryData[],
        pagination: null as PaginationData | null,
        currentPage: 1,
        loading: true,
        error: null as string | null,
        apiStatus: null as string | null,
        sortBy: 'execution_time',
        sortOrder: 'desc',
        refreshInterval: 30, // seconds
        lastRefreshed: new Date(),
        searchParams: {
            term: '',
            startDate: '',
            endDate: '',
            caseSensitive: false
        },
        activeSearch: {
            term: '',
            startDate: '',
            endDate: '',
            caseSensitive: false
        }
    });

    // Format time in a readable way
    const formatTime = (ms: number): string => {
        // For very small values (less than 1ms), show with more precision
        if (ms < 1) {
            return `${(ms * 1000).toFixed(2)}μs`; // microseconds
        }
        // For values less than 1000ms, show as milliseconds
        if (ms < 1000) {
            return `${ms.toFixed(2)}ms`;
        }
        // For values 1000ms or greater, show as seconds
        return `${(ms / 1000).toFixed(2)}s`;
    };

    // Format timestamp
    const formatTimestamp = (timestamp: string): string => {
        return moment.default(timestamp).format('YYYY-MM-DD HH:mm:ss');
    };

    // Determine color based on execution time
    const getTimeColor = (executionTime: number): string => {
        if (executionTime < 200) return "green"; // Fast
        if (executionTime < 1000) return "yellow"; // Medium
        if (executionTime < 3000) return "orange"; // Slow
        return "red"; // Very slow
    };

    // Get status color
    const getStatusColor = (status: string): string => {
        if (status === 'success') return "green";
        if (status === 'warning') return "yellow";
        return "red"; // Failure
    };

    // Format last refreshed time
    const formatLastRefreshed = (): string => {
        return moment.default(state.lastRefreshed).fromNow();
    };

    // Fetch queries from API
    const fetchQueries = async (page = 1) => {
        try {
            setState(prev => ({ ...prev, loading: true }));
            
            // Build query parameters
            const params: any = {
                page
            };
            
            // Add search parameters if they exist
            if (state.activeSearch.term) {
                params.search = state.activeSearch.term;
            }
            
            if (state.activeSearch.caseSensitive) {
                params.case_sensitive = 'true';
            }
            
            if (state.activeSearch.startDate) {
                params.start_date = state.activeSearch.startDate;
            }
            
            if (state.activeSearch.endDate) {
                params.end_date = state.activeSearch.endDate;
            }

            console.log('Fetching query logs with params:', params);
            const response = await queryLoggingActions.getQueryLogs(params) as ApiResponse;
            console.log('Query logs response:', response);
            
            // Extract data based on the API response shape
            if (!response) {
                throw new Error('No response received from the API');
            }
            
            const { status, data, pagination } = response;
            
            if (!data) {
                throw new Error('No data received in the API response');
            }
            
            setState(prev => ({
                ...prev,
                apiStatus: status,
                queries: Array.isArray(data) ? data : [],
                pagination: pagination,
                currentPage: pagination?.page || 1,
                error: null,
                loading: false,
                lastRefreshed: new Date()
            }));

            // Apply client-side sorting since the API doesn't support it
            if (data && Array.isArray(data) && data.length > 0) {
                const sortedQueries = [...data].sort((a, b) => {
                    if (state.sortBy === 'execution_time') {
                        return state.sortOrder === 'asc' 
                            ? a.execution_time - b.execution_time 
                            : b.execution_time - a.execution_time;
                    } else if (state.sortBy === 'timestamp') {
                        return state.sortOrder === 'asc'
                            ? new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
                            : new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
                    } else if (state.sortBy === 'id') {
                        return state.sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                    }
                    return 0;
                });
                
                setState(prev => ({
                    ...prev,
                    queries: sortedQueries
                }));
            }
        } catch (err: any) {
            console.error('Error fetching query data:', err);
            setState(prev => ({
                ...prev,
                error: `Failed to fetch data: ${err.message || 'Unknown error'}`,
                queries: [],
                pagination: null,
                loading: false
            }));
        }
    };

    // Initial data fetch
    useEffect(() => {
        fetchQueries(state.currentPage);
        
        // Set up auto-refresh interval
        const intervalId = setInterval(() => {
            if (state.refreshInterval > 0) {
                fetchQueries(state.currentPage);
            }
        }, state.refreshInterval * 1000);
        
        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [state.currentPage, state.refreshInterval, state.activeSearch, state.sortBy, state.sortOrder]);

    // Handle page change
    const handlePageChange = (_e: any, data: any) => {
        const newPage = data.activePage;
        setState(prev => ({ ...prev, currentPage: newPage }));
        fetchQueries(newPage);
    };

    // Handle refresh interval change
    const handleRefreshIntervalChange = (e: any, { value }: any) => {
        setState(prev => ({ ...prev, refreshInterval: value }));
    };

    // Handle manual refresh
    const handleManualRefresh = () => {
        fetchQueries(state.currentPage);
    };

    // Handle search parameter change
    const handleSearchChange = (param: string, value: any) => {
        setState(prev => ({
            ...prev,
            searchParams: {
                ...prev.searchParams,
                [param]: value
            }
        }));
    };

    // Handle search submission
    const handleSearch = () => {
        setState(prev => ({
            ...prev,
            activeSearch: { ...prev.searchParams },
            currentPage: 1
        }));
        fetchQueries(1);
    };

    // Handle clear search
    const handleClearSearch = () => {
        const clearedParams = {
            term: '',
            startDate: '',
            endDate: '',
            caseSensitive: false
        };
        setState(prev => ({
            ...prev,
            searchParams: clearedParams,
            activeSearch: clearedParams,
            currentPage: 1
        }));
        fetchQueries(1);
    };

    // Handle sort change
    const handleSortChange = (e: any, { value }: any) => {
        setState(prev => ({ ...prev, sortBy: value }));
    };

    // Toggle sort order
    const toggleSortOrder = () => {
        setState(prev => ({
            ...prev,
            sortOrder: prev.sortOrder === 'asc' ? 'desc' : 'asc'
        }));
    };

    return (
        <Template activeLink="query-logging">
            {state.error && (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{state.error}</p>
                </Message>
            )}
            
            <Card fluid>
                <Card.Content>
                    <h2>Query Logging</h2>
                    
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
                        <div>
                            <Button icon onClick={handleManualRefresh} title="Refresh now">
                                <Icon name="refresh" />
                            </Button>
                            <Dropdown
                                selection
                                compact
                                options={[
                                    { key: '0', text: 'Manual refresh only', value: 0 },
                                    { key: '10', text: 'Every 10 seconds', value: 10 },
                                    { key: '30', text: 'Every 30 seconds', value: 30 },
                                    { key: '60', text: 'Every minute', value: 60 },
                                    { key: '300', text: 'Every 5 minutes', value: 300 },
                                ]}
                                value={state.refreshInterval}
                                onChange={handleRefreshIntervalChange}
                            />
                        </div>
                        <div>
                            <span style={{ marginRight: '1em' }}>Sort by:</span>
                            <Dropdown
                                selection
                                compact
                                options={[
                                    { key: 'execution_time', text: 'Execution Time', value: 'execution_time' },
                                    { key: 'timestamp', text: 'Timestamp', value: 'timestamp' },
                                    { key: 'id', text: 'Query ID', value: 'id' },
                                ]}
                                value={state.sortBy}
                                onChange={handleSortChange}
                            />
                            <Button icon onClick={toggleSortOrder} style={{ marginLeft: '0.5em' }}>
                                <Icon name={state.sortOrder === 'asc' ? 'sort ascending' : 'sort descending'} />
                            </Button>
                        </div>
                    </div>
                    
                    {/* Search Form */}
                    <Card fluid>
                        <Card.Content>
                            <Form>
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Search Term</label>
                                        <Input 
                                            placeholder="Search queries..." 
                                            value={state.searchParams.term}
                                            onChange={(e) => handleSearchChange('term', e.target.value)}
                                            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && handleSearch()}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Start Date</label>
                                        <Input 
                                            type="date" 
                                            value={state.searchParams.startDate}
                                            onChange={(e) => handleSearchChange('startDate', e.target.value)}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>End Date</label>
                                        <Input 
                                            type="date" 
                                            value={state.searchParams.endDate}
                                            onChange={(e) => handleSearchChange('endDate', e.target.value)}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Checkbox 
                                        label="Case Sensitive" 
                                        checked={state.searchParams.caseSensitive}
                                        onChange={(e, data) => handleSearchChange('caseSensitive', data.checked)}
                                    />
                                </Form.Group>
                                <Button primary onClick={handleSearch}>
                                    <Icon name="search" />
                                    Search
                                </Button>
                                <Button onClick={handleClearSearch}>
                                    <Icon name="cancel" />
                                    Clear
                                </Button>
                            </Form>
                        </Card.Content>
                    </Card>
                    
                    {/* Statistics */}
                    {!state.loading && state.queries && state.queries.length > 0 && (
                        <Card fluid style={{ marginTop: "15px" }}>
                            <Card.Content>
                                <Header as="h4">Statistics</Header>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                                    <div style={{ textAlign: "center", flex: 1 }}>
                                        <div style={{ fontSize: "1.5em", fontWeight: "bold" }}>
                                            {state.pagination?.total_records || state.queries.length}
                                        </div>
                                        <div style={{ fontSize: "0.9em", color: "rgba(0,0,0,0.6)" }}>Total Queries</div>
                                    </div>
                                    
                                    <div style={{ textAlign: "center", flex: 1 }}>
                                        {(() => {
                                            // We can only calculate the average based on the queries we have loaded
                                            // If there's pagination, we only have the current page's queries
                                            const queriesOnPage = state.queries.length;
                                            
                                            // Only calculate if we have queries
                                            if (queriesOnPage === 0) {
                                                return (
                                                    <>
                                                        <div style={{ fontSize: "1.5em", fontWeight: "bold" }}>
                                                            N/A
                                                        </div>
                                                        <div style={{ fontSize: "0.9em", color: "rgba(0,0,0,0.6)" }}>
                                                            Average Time
                                                        </div>
                                                    </>
                                                );
                                            }
                                            
                                            // Calculate total execution time from the queries on the current page
                                            const totalTime = state.queries.reduce((sum, q) => sum + q.execution_time, 0);
                                            
                                            // Calculate average
                                            const avgTime = totalTime / queriesOnPage;
                                            
                                            return (
                                                <>
                                                    <div style={{ fontSize: "1.5em", fontWeight: "bold", color: getTimeColor(avgTime) }}>
                                                        {formatTime(avgTime)}
                                                    </div>
                                                    <div style={{ fontSize: "0.9em", color: "rgba(0,0,0,0.6)" }}>
                                                        Average Time {state.pagination ? "(Current Page)" : ""}
                                                    </div>
                                                </>
                                            );
                                        })()}
                                    </div>
                                    
                                    <div style={{ textAlign: "center", flex: 1 }}>
                                        {(() => {
                                            // Only calculate if we have queries
                                            if (state.queries.length === 0) {
                                                return (
                                                    <>
                                                        <div style={{ fontSize: "1.5em", fontWeight: "bold" }}>
                                                            N/A
                                                        </div>
                                                        <div style={{ fontSize: "0.9em", color: "rgba(0,0,0,0.6)" }}>
                                                            Slowest Query
                                                        </div>
                                                    </>
                                                );
                                            }
                                            
                                            // Find the query with the highest execution time
                                            const slowestQuery = state.queries.reduce(
                                                (slowest, current) => 
                                                    current.execution_time > slowest.execution_time ? current : slowest, 
                                                state.queries[0]
                                            );
                                            
                                            return (
                                                <>
                                                    <div style={{ fontSize: "1.5em", fontWeight: "bold", color: getTimeColor(slowestQuery.execution_time) }}>
                                                        {formatTime(slowestQuery.execution_time)}
                                                    </div>
                                                    <div style={{ fontSize: "0.9em", color: "rgba(0,0,0,0.6)" }}>
                                                        Slowest Query {state.pagination ? "(Current Page)" : ""}
                                                    </div>
                                                </>
                                            );
                                        })()}
                                    </div>
                                    
                                    <div style={{ textAlign: "center", flex: 1 }}>
                                        <div style={{ fontSize: "1.5em", fontWeight: "bold" }}>
                                            {formatLastRefreshed()}
                                        </div>
                                        <div style={{ fontSize: "0.9em", color: "rgba(0,0,0,0.6)" }}>Last Refreshed</div>
                                    </div>
                                </div>
                            </Card.Content>
                        </Card>
                    )}
                    
                    {/* Query Results */}
                    <Card fluid style={{ marginTop: "15px" }}>
                        <Card.Content>
                            {state.loading ? (
                                <div style={{ height: "200px", position: "relative" }}>
                                    <Dimmer active inverted>
                                        <Loader inverted>Loading</Loader>
                                    </Dimmer>
                                </div>
                            ) : state.queries.length === 0 ? (
                                <div style={{ textAlign: "center", padding: "40px 0" }}>
                                    <Icon name="search" size="huge" color="grey" />
                                    <Header as="h3" style={{ marginTop: "10px" }}>
                                        {Object.values(state.activeSearch).some(v => v !== '' && v !== false)
                                            ? "No queries found matching your search criteria. Try adjusting your filters."
                                            : "No queries found"}
                                    </Header>
                                </div>
                            ) : (
                                <div style={{ maxHeight: "600px", overflowY: "auto" }}>
                                    {state.queries.map(query => (
                                        <Card key={query.id} fluid style={{ borderLeft: `4px solid ${getTimeColor(query.execution_time)}` }}>
                                            <Card.Content>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                                                    <Header as="h4" style={{ margin: 0 }}>Query #{query.id}</Header>
                                                    <div>
                                                        {query.status && (
                                                            <Label color={getStatusColor(query.status) as any} size="tiny" style={{ marginRight: "5px" }}>
                                                                {query.status}
                                                            </Label>
                                                        )}
                                                        <Label color={getTimeColor(query.execution_time) as any} size="tiny">
                                                            {formatTime(query.execution_time)}
                                                        </Label>
                                                    </div>
                                                </div>
                                                <Segment secondary style={{ overflowX: "auto", margin: 0 }}>
                                                    <pre style={{ margin: 0 }}>{query.query_text}</pre>
                                                </Segment>
                                                <div style={{ display: "flex", justifyContent: "space-between", fontSize: "0.9em", color: "rgba(0,0,0,0.6)", marginTop: "10px" }}>
                                                    <span>Time: {formatTimestamp(query.timestamp)}</span>
                                                    {query.source_location && <span>Source: {query.source_location}</span>}
                                                </div>
                                            </Card.Content>
                                        </Card>
                                    ))}
                                </div>
                            )}
                        </Card.Content>
                    </Card>
                    
                    {/* Pagination */}
                    {state.pagination && state.pagination.total_pages > 1 && (
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                            <Pagination
                                activePage={state.currentPage}
                                totalPages={state.pagination.total_pages}
                                onPageChange={handlePageChange}
                                ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                                firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                                lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                            />
                        </div>
                    )}
                </Card.Content>
            </Card>
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        app: state.app,
        alert: state.alert
    };
}

export default connect(mapStateToProps)(QueryLogging);
