import { App } from "../../model";
import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const SidebarOpen = (flag: App) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		dispatch({ type: ActionType.SIDEBAR_OPEN, payload: { leftSidebarOpen: flag } });
	} catch (err) {
		return dispatch({ type: ActionType.SIDEBAR_OPEN, payload: { leftSidebarOpen: false } });
	}
};


export const UpdatePracticesOptions=(practices:string[])=>async(dispatch:Function,getState:Function,api:any)=>{
	const capitalizeString=(str:string)=>{
	const arr = str.split(" ");
	for (var i = 0; i < arr.length; i++) {
		arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

	}
	return arr.join(" ");
		}
	try{
		dispatch({type:ActionType.UPDATE_PRACTICES_OPTIONS,payload:{
					practiceOptions:practices.map(prac=>{
						return {
							key:prac+`${Math.random()*20}`, text:capitalizeString(prac), value:capitalizeString(prac),
						}
					})
				}})
	}catch(err){
		return dispatch({

		})
	}
}
export const UpdateRegionOptions=(regions:any)=>async(dispatch:Function,getState:Function,api:any)=>{
	try{
		dispatch({type:ActionType.UPDATE_REGION_OPTIONS,payload:{
			regionOptions:regions
		}})
	}catch(err){
		return dispatch({

		})
	}
}
export const UpdateBrandsOption=(brands:any)=>async(dispatch:Function,getState:Function,api:any)=>{
	try{
		dispatch({type:ActionType.UPDATE_BRANDS_OPTIONS,payload:{
			brandOptions:brands
		}})
	}catch(err){
		return dispatch({

		})
	}
}
export const Loader = (flag: App) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		dispatch({ type: ActionType.LOADER, payload: { loader: flag } });
	} catch (err) {
		return dispatch({ type: ActionType.LOADER, payload: { loader: false } });
	}
};

export const sendFeedback = (feedback : any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("report/sendFeedback", feedback);
		dispatch({ type: ActionType.SEND_FEEDBACK, payload: resp });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const saveUsage = (usage : any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const resp =  await api.post("report/saveUsage", usage);
		dispatch({ type: ActionType.SAVE_USAGE, payload: resp });
		return resp;
	} catch (err) {
		return dispatch(errorHandler(err));
	}
};


export const getEarningsSendingSetup = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.get("earnings/setup");
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const saveEarningsSendingSetup = (data :any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("earnings/setup", data);
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};


export const getPassCodes = (mco: string) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.get("dialpad/passcodes/"+mco);
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const getOrthoEarningsSendingSetup = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.get("orthoearnings/setup");
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const saveOrthoEarningsSendingSetup = (data :any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("orthoearnings/setup", data);
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const saveErrorFeedback = (data :any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("app/error/feedback", data);
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};
