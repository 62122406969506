import { ActionType } from "../../model/actionTypes";

export const listS3Objects = (prefix: string) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
      const resp = await api.post("pdf-preview/s3/list", { prefix });
      // Ensure consistent response structure
      return {
        ...resp,
        data: {
          objects: resp.data?.objects || []
        }
      };
    } finally {
      dispatch({ type: ActionType.LOADER, payload: false });
    }
  };

export const getS3Object = (key: string) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    const resp = await api.post("pdf-preview/s3/get", { key });
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
};

export const putS3Object = (payload: { key: string; content: string }) => 
  async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    const resp = await api.post("pdf-preview/s3/put", payload);
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
};
export const sendEmailWithAttachments = (emailData: {
  subject: string;
  body: string;
  recipients: string[];
  attachments: Array<{
      fileName: string;
      htmlContent: string;
  }>;
}) => async (dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
      const resp = await api.post("pdf-preview/email/send", {
          subject: emailData.subject,
          body: emailData.body,
          recipients: emailData.recipients,
          attachments: emailData.attachments
      });
      dispatch({ type: ActionType.LOADER, payload: false });
      return { success: true, data: resp.data };
  } catch (error:any) {
      dispatch({ type: ActionType.LOADER, payload: false });
      return { success: false, error: error.response?.data?.error || 'Failed to send email' };
  }
};