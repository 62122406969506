import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import Template from "../../components/Template";
import FilterSection from "../../components/FilterSection";
import ReportMain from "../../components/ReportMain";
import {getFilteredData, practiceRecords} from "./Filters/Common";
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import * as appActions from "../../redux/actions/app";
import * as userSettingsActions from "../../redux/actions/userSettings";
import * as practiceActions from '../../redux/actions/practice'
import * as cronEmailSettingsActions from '../../redux/actions/cronEmailSettings';
import {RootState} from "../../model";
import {ReportProps, SendEmail} from "../../model/report";
import {ApplyFilter, OnChangeFilter} from "../../model/common";
import {myCDPApplicationList, reportInitialState, reportPageNames} from "../../constants";
import {exportPcrCSV} from "../../components/ExportCsv";
import {sendPCREmail} from "../../components/sendEmail";
import ReactDOMServer from 'react-dom/server';
import RenderHtml from "./RenderHtml";
import DynamicHtmlTableMail from "../../components/DynamicHtmlTableMail";
import {Dimmer, Loader, Modal, Form, Button, Dropdown, Radio, Segment, Icon, List, Header, Divider, Message} from "semantic-ui-react";
import * as issuesActions from "../../redux/actions/downTimeTracker";
import Loading from "../../Loading";

function BySplashBoard(props: ReportProps) {
	const reportAction = useActions(reportActions);
	const appAction = useActions(appActions);
	const userSettingsAction = useActions(userSettingsActions)
	const practiceAction = useActions(practiceActions);
	const cronEmailSettingsAction = useActions(cronEmailSettingsActions);
	const [state, setStates] = useState(reportInitialState);
	const data = state.data , page = reportPageNames.splashBoard;
	const [loader, setLoader] = useState(false);
	const issuesAction = useActions(issuesActions);
	const [issuesState, setIssuesState] = useState({appIsDown:false});
	
	// State for monthend report modal
	const [monthendModalOpen, setMonthendModalOpen] = useState(false);
	const [selectedYear, setSelectedYear] = useState<number | null>(null);
	const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
	const [sendToAll, setSendToAll] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [emailRecipients, setEmailRecipients] = useState<string[]>([]);
	const [fetchingEmails, setFetchingEmails] = useState<boolean>(false);
	const [emailFetchError, setEmailFetchError] = useState<string | null>(null);
	const [emailsLoaded, setEmailsLoaded] = useState<boolean>(false);
	const [currentUserEmail, setCurrentUserEmail] = useState<string>("");

	// Cron ID for monthend report - this would be configured based on your system
	const MONTHEND_REPORT_CRON_ID = 220; // Replace with actual cron ID

	const getPracticesRecords = async() => {
		const propVal = !!props.report.reportNewData ? props.report.reportNewData : props.reportTN.reportTimeAndName[0];

		const result: any = await issuesAction.fetchOpenIssuesList(["PCR"]);

		if(propVal) {
			const stateObj = await practiceRecords(props, state, appAction, practiceAction, userSettingsAction, page, 'bySplashBoardReport')
			setStates(stateObj);
		}
		await reportAction.filterByValue(reportInitialState.filters);
		setIssuesState({
            ...issuesState,
            appIsDown: result.length > 0 ? true :false
        });
	}

	// Get current user's email
	const getCurrentUserEmail = async () => {
		try {
			// Fetch user settings
			const user = props.auth.user;
			
			if(user && user.email) {
				setCurrentUserEmail(user.email);
			} else {
				setCurrentUserEmail("");
			}
			
		} catch (error) {
			console.error("Error fetching user email:", error);
			// Fallback to a default value if there's an error
			setCurrentUserEmail("");
		}
	};

	useEffect(() => { 
		getPracticesRecords();
		getCurrentUserEmail(); // Get current user's email when component mounts
	}, [props]);

	const applyFilter: ApplyFilter = (filters) => {
		const propsData = JSON.parse(JSON.stringify(props));
		const [selectedPractices, data] = getFilteredData(filters, propsData, page, 'bySplashBoardReport');
		appAction.UpdatePracticesOptions(selectedPractices);
		setStates({...state, filters, data, selectedPractices});
	};

	const sendEmail:SendEmail = (dataArray) => {
		if(data && data.tableData && Object.keys(data.tableData).length) {
			const pdf = Object.keys(data.tableData).map( key => (
				ReactDOMServer.renderToString(
					<DynamicHtmlTableMail
						key={key}
						className={`by-splashboard ${key}`}
						tableHead={data ? data.tableHead : []}
						heading={data ? data.tableData[key].heading : ""}
						tableData={data ? data.tableData[key].tableData : []}
					/>)
			));
			reportAction.sendReportData({ filters: state.filters, page: page, data: dataArray, pdf: pdf.join("") });
		}
	};

	const fetchEmailRecipients = async () => {
		try {
			setFetchingEmails(true);
			setEmailFetchError(null);
			
			const response = await cronEmailSettingsAction.getCronEmailSettingsByCronId(MONTHEND_REPORT_CRON_ID);
			
			
			if (response && response.length) {
				// The API returns an array of objects with an 'emails' field that contains a string of comma-separated emails
				const emailSettings = response[0];
				
				if (emailSettings.emails) {
					// Split the comma-separated emails string into an array
					const emailsArray = emailSettings.emails.split(',').map((email: string) => email.trim());
					setEmailRecipients(emailsArray);
				} else {
					setEmailRecipients([]);
				}
				
			} else {
				// Fallback to empty array if no recipients found
				setEmailRecipients([]);
			}
		} catch (error) {
			console.error("Error fetching email recipients:", error);
			setEmailFetchError("Failed to load email recipients. Please try again.");
			// Fallback to empty array on error
			setEmailRecipients([]);
		} finally {
			setFetchingEmails(false);
			setEmailsLoaded(true);
		}
	};

	const handleSendToAllChange = (value: boolean) => {
		setSendToAll(value);
		
		// Only fetch email recipients when "Send to All" is selected and emails haven't been loaded yet
		if (value && !emailsLoaded) {
			fetchEmailRecipients();
		}
	};

	const sendMonthendReport = () => {
		// Reset email-related states when opening the modal
		setEmailsLoaded(false);
		setSendToAll(false);
		setSelectedYear(null);
		setSelectedMonth(null);
		setEmailFetchError(null);
		setMonthendModalOpen(true);
	};
	
	const handleMonthendSubmit = () => {
		if (!selectedYear || !selectedMonth) {
			return; // Don't proceed if year or month is not selected
		}

		setIsLoading(true);
		
		const year = selectedYear;
		const month = selectedMonth;
		const lastDay = new Date(year, month, 0).getDate();
		
		// Format the date as YYYY-MM-DD
		const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
		
		// Prepare the parameters for the API call
		const params = {
			date: formattedDate,
			recipients: sendToAll ? emailRecipients : [currentUserEmail],
			practiceList: state.filters.practice
		};
		
		// Call the API to send the monthend report
		reportAction.sendMonthendReport(params)
			.then((response: any) => {
				// Hide loading state and close modal
				setIsLoading(false);
				setMonthendModalOpen(false);
			})
			.catch((error: any) => {
				console.error("Error sending monthend report:", error);
				setIsLoading(false);
			});
	};

	// Check if emails are available for sending
	const isEmailAvailable = () => {
		if (sendToAll) {
			return emailRecipients.length > 0;
		} else {
			return currentUserEmail.trim() !== '';
		}
	};

	const onChangeFilter:OnChangeFilter = async (filters) => {
		filters.month && await reportAction.resetReportData();
		reportAction.filterByValue(filters);
		applyFilter(filters);
		setLoader(true);
		await reportAction.filterByValue(reportInitialState.filters);
		setLoader(false);
	};
	
	// Generate year options (current year and 5 years back)
	const yearOptions = Array.from({ length: 1 }, (_, i) => {
		const year = new Date().getFullYear() - i;
		return { key: year, text: year.toString(), value: year };
	});
	
	// Generate month options
	const monthOptions = [
		// { key: 1, text: 'January', value: 1 },
		{ key: 2, text: 'February', value: 2 },
		{ key: 3, text: 'March', value: 3 },
		// { key: 4, text: 'April', value: 4 },
		// { key: 5, text: 'May', value: 5 },
		// { key: 6, text: 'June', value: 6 },
		// { key: 7, text: 'July', value: 7 },
		// { key: 8, text: 'August', value: 8 },
		// { key: 9, text: 'September', value: 9 },
		// { key: 10, text: 'October', value: 10 },
		// { key: 11, text: 'November', value: 11 },
		// { key: 12, text: 'December', value: 12 }
	];

	// Get the month name for display
	const getMonthName = (monthNumber: number) => {
		return monthOptions.find(month => month.value === monthNumber)?.text || '';
	};
	
	return (
		<Template activeLink={`pcr-${page}`}>
			{loader && <Dimmer active={true} inverted={true}>
				<Loading />
			</Dimmer>
			}
			<ReportMain>
				<FilterSection 
					page={page} 
					heading="PCR By Splashboard" 
					appIsDown={{status:issuesState.appIsDown, appsDown:["PCR"]}}  
					filters={state.filters} 
					onChangeFilter={onChangeFilter} 
					exportCSV={()=>exportPcrCSV(data, page)} 
					sendEmail={()=>sendPCREmail(data, page, sendEmail)} 
					sendMonthendReport={sendMonthendReport}
				/>
				<RenderHtml data={data} page={page} />
				
				{/* Monthend Report Modal */}
				<Modal
					size="small"
					open={monthendModalOpen}
					onClose={() => setMonthendModalOpen(false)}
					dimmer="blurring"
				>
					<Modal.Header>
						<Icon name="calendar check" /> Monthend Report Configuration
					</Modal.Header>
					<Modal.Content>
						<Header as="h4" className="mb-3">
							Please select the month and year for your report
						</Header>
						
						<Segment raised>
							<Form>
								<Form.Group widths="equal">
									<Form.Field>
										<label><Icon name="calendar" /> Year</label>
										<Dropdown
											fluid
											selection
											options={yearOptions}
											value={selectedYear || undefined}
											onChange={(_, data) => setSelectedYear(data.value as number)}
										/>
									</Form.Field>
									<Form.Field>
										<label><Icon name="calendar alternate" /> Month</label>
										<Dropdown
											fluid
											selection
											options={monthOptions}
											value={selectedMonth || undefined}
											onChange={(_, data) => setSelectedMonth(data.value as number)}
										/>
									</Form.Field>
								</Form.Group>
								
								<Divider horizontal>
									<Header as="h5">
										<Icon name="mail" />
										Recipients
									</Header>
								</Divider>
								
								<Form.Field>
									<div className="ui form">
										<div className="inline fields">
											<div className="field">
												<Radio
													label="Send to Me Only"
													name="sendToRadioGroup"
													value="me"
													checked={!sendToAll}
													onChange={() => handleSendToAllChange(false)}
												/>
											</div>
											<div className="field">
												<Radio
													label="Send to All Users"
													name="sendToRadioGroup"
													value="all"
													checked={sendToAll}
													onChange={() => handleSendToAllChange(true)}
												/>
											</div>
										</div>
									</div>
								</Form.Field>
								
								{sendToAll && (
									<Segment secondary style={{ maxHeight: '150px', overflow: 'auto' }}>
										<Header as="h5">
											<Icon name="users" /> Recipients List
										</Header>
										
										{fetchingEmails && (
											<div className="text-center p-2">
												<Icon name="spinner" loading /> Loading recipients...
											</div>
										)}
										
										{emailFetchError && (
											<Message negative size="tiny">
												<Message.Header>Error</Message.Header>
												<p>{emailFetchError}</p>
												<Button size="tiny" onClick={fetchEmailRecipients}>
													<Icon name="refresh" /> Try Again
												</Button>
											</Message>
										)}
										
										{!fetchingEmails && !emailFetchError && emailsLoaded && (
											emailRecipients.length > 0 ? (
												<List>
													{emailRecipients.map((email, index) => (
														<List.Item key={index}>
															<Icon name="user" />
															<List.Content>{email}</List.Content>
														</List.Item>
													))}
												</List>
											) : (
												<Message info size="tiny">
													<p>No recipients configured for this report.</p>
												</Message>
											)
										)}
									</Segment>
								)}
								
								{!sendToAll && currentUserEmail.trim() === '' && (
									<Message warning size="tiny">
										<Icon name="warning" />
										<span>Your email address is not available. Please contact support.</span>
									</Message>
								)}
							</Form>
						</Segment>
						
						<Segment secondary>
							<Header as="h5">
								<Icon name="info circle" /> Summary
							</Header>
							<p>
								{selectedYear && selectedMonth ? (
									<>You are about to send a monthend report for <strong>{getMonthName(selectedMonth)} {selectedYear}</strong> to {sendToAll ? 'all users' : 'yourself only'}.</>
								) : (
									<>Please select both year and month to proceed.</>
								)}
							</p>
							{selectedYear && selectedMonth && (
								<p>
									The Report will include data for the month of <strong>{getMonthName(selectedMonth)}</strong> year <strong>{selectedYear}</strong>.
								</p>
							)}
						</Segment>
					</Modal.Content>
					<Modal.Actions>
						<Button negative onClick={() => setMonthendModalOpen(false)} disabled={isLoading}>
							<Icon name="cancel" /> Cancel
						</Button>
						<Button 
							positive 
							onClick={handleMonthendSubmit} 
							loading={isLoading} 
							disabled={isLoading || (sendToAll && fetchingEmails) || !isEmailAvailable()}
						>
							<Icon name="send" /> Send Report
						</Button>
					</Modal.Actions>
				</Modal>
			</ReportMain>
		</Template>
	);

}

function mapStateToProps(state: RootState) {
	return {
		report: state.report,
		practice: state.practice,
		reportTN: state.reportTimeAndName,
		auth: state.auth,
	};
}

export default connect(mapStateToProps)(BySplashBoard);
