import React from "react";

//generate a select input
interface props {
    name: string,
    label: string,
    options: {name:string,id:number, text:any}[],
}

const SelectInput = ({ name, label, options, ...rest }:props) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <select name={name} id={name} {...rest} className="form-control">
        <option value="" />
        {options?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;