import React, {useEffect, useState} from "react";
import {Button, Label, Table} from "semantic-ui-react";
import {VARIABLE_NAMES} from "../../constants";
import {useDispatch} from "react-redux";
import * as doctorVariableActions from "../../redux/actions/doctorVariables";
import * as providerActions from "../../redux/actions/provider";
import ProviderRatesVariablesModal from "./ProviderRatesVariablesModal";

function ProviderRatesVariables() {
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const [variable, setVariable] = useState<any>();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setVariable(undefined);
    const results = await dispatch(doctorVariableActions.fetchVariables());
    setData(results as any)
  }

  return <>
    <Button className="float-right" content="Add Variable" primary={true} onClick={() => setVariable({})}/>
    <br/>
    {variable && <ProviderRatesVariablesModal variable={variable} onClose={() => loadData()}/>}
    <br/>
    <Table striped={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell/>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Variable</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Options</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((variable, i) => <Table.Row key={variable.name + i}>
          <Table.Cell><Button icon="edit" onClick={() => setVariable(variable)}/></Table.Cell>
          <Table.Cell>{variable.label}</Table.Cell>
          <Table.Cell><b>{variable.name}</b></Table.Cell>
          <Table.Cell>{variable.inputType?.toUpperCase()}</Table.Cell>
          <Table.Cell><Label content={variable.options?.length}/></Table.Cell>
        </Table.Row>)}
      </Table.Body>
    </Table>
    <br/>
  </>;
}

export default ProviderRatesVariables;