import React, {Component, ReactNode} from "react";
import {Button, Form, Grid} from "semantic-ui-react";
import {SetupAppName} from "../enums/userSetupAppName";
import * as AppActions from "../redux/actions/app";
import {useActions} from "../redux/actions";

interface ErrorBoundaryProps {
    children: ReactNode;
    saveErrorFeedback: (payload: { app: string; error: string; comments: string }) => void;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: any;
    userComments?: string;
    errorInfo?: any
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: "",
            errorInfo: '',
            userComments: "",
        };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return {error, hasError: true};
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
        this.setState({errorInfo: errorInfo.componentStack});
    }

    handleSubmit = () => {
        const {error, userComments, errorInfo} = this.state;
        const {saveErrorFeedback} = this.props;

        const feedback: any = {
            app: SetupAppName.CDP_MY,
            error: error.toString() + errorInfo.toString(),
            comments: userComments,
        };

        saveErrorFeedback(feedback); // Call Redux action
        this.setState({
            userComments: ""
        });
        alert('Thank you for your Feedback, We will investigate and resolve the issue as soon as possible')
    };

    handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({userComments: event.target.value});
    };

    render() {
        if (this.state.hasError) {
            return (
                <Grid centered={true} columns={3}>
                    <Grid.Column>
                        <div style={{padding: "20px", fontFamily: "Arial, sans-serif", color: "#333"}}>
                            <h2>Something went wrong!</h2>
                            <p>We're sorry, but an unexpected error occurred. Please provide any details that might help
                                us in resolving the issue.</p>
                            <Form>
                                <div>
                                    <strong>Error Details:</strong>
                                    <pre
                                        style={{
                                            background: "#f8f8f8",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            overflowX: "auto",
                                        }}
                                    >
                        {this.state.error && this.state.error.toString()}
                      </pre>
                                </div>
                                <div>
                                    <Form.TextArea
                                        onChange={this.handleDescriptionChange}
                                        label="Your feedback please"
                                        value={this.state.userComments}
                                        placeholder="Can you please describe what you were doing when the error occurred?"
                                        style={{
                                            width: "100%",
                                            minHeight: "100px",
                                            marginTop: "10px",
                                            padding: "10px",
                                            border: "1px solid #ccc",
                                            borderRadius: "5px",
                                        }}
                                    />
                                </div>
                                <Button
                                    onClick={this.handleSubmit}
                                    style={{
                                        marginTop: "10px",
                                        padding: "10px 20px",
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                    }}
                                >
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </Grid.Column>
                </Grid>
            );
        }

        return this.props.children;
    }
}

// ✅ Functional Wrapper to Inject `useActions`
const ErrorBoundaryWrapper: React.FC<{ children: ReactNode }> = ({children}) => {
    const {saveErrorFeedback} = useActions(AppActions); // Get saveErrorFeedback action

    return <ErrorBoundary saveErrorFeedback={saveErrorFeedback}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryWrapper;
