import React, { useState, useEffect, useMemo } from 'react';
import Template from "../../components/Template";
import { Button, Grid, Table, Icon, Input, Pagination, Dropdown, Dimmer, Loader, Card, Statistic, Rating, Segment, Header, Menu, Tab, Modal, Form, Message } from "semantic-ui-react";
import { SemanticICONS } from 'semantic-ui-react';
import axios from 'axios';

interface Review {
  id: number;
  rating: number;
  body: string;
  title: string | null;
  origin: string;
  reviewerName: string;
  externalId: string;
  reviewDate: string;
  reviewUpdatedDate: string;
  recommended: boolean | null;
  notifiedAt: string | null;
  read: boolean;
  normalizedRating: number;
  repliedAt: string | null;
  sharedToFb: boolean | null;
  teamId: number;
  team: {
    id: number;
    name: string;
  };
}

interface Location {
  id: number;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  zoom: number;
  externalId: string;
  teamId: number;
  createdAt: string;
  updatedAt: string;
  phoneNumber: string | null;
  timezone: string | null;
  reviews: Review[];
}

interface Brand {
  id: number;
  ownerId: number;
  name: string;
  slug: string | null;
  photoUrl: string | null;
  createdAt: string;
  updatedAt: string;
  timezone: string;
  agencyId: number;
  cancelledAt: string | null;
  cancelledBy: string | null;
  locations: Location[];
  locationReviews: Location[];
}

interface ApiResponse {
  data: Brand[];
}

const ReviewDashboard = () => {
  // State management
  const [allBrands, setAllBrands] = useState<Brand[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string>('all');
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  
  // Pagination state (client-side)
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  
  // Search and filter state
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [ratingFilter, setRatingFilter] = useState<number | null>(null);
  const [sourceFilter, setSourceFilter] = useState<string>('');
  const [dateSort, setDateSort] = useState<'asc' | 'desc'>('desc');

  // Active tab state
  const [activeTab, setActiveTab] = useState<number>(0);

  // Add new state for review details
  const [selectedReview, setSelectedReview] = useState<Review | null>(null);
  const [showReplyModal, setShowReplyModal] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<string>('');

  // Add debug logging
  useEffect(() => {
    console.log('Selected Brand:', selectedBrand);
    console.log('Selected Location:', selectedLocation);
    console.log('Location Reviews:', selectedBrand?.locationReviews);
  }, [selectedBrand, selectedLocation]);

  // Fetch all reviews data once on component mount
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        setLoading(true);
        // Use the new endpoint instead of the action
        const response = await axios.get<ApiResponse>('https://wp.cdp.dental/cx/');
        setAllBrands(response.data.data);
        
        if (response.data.data.length > 0 && !selectedBrand) {
          setSelectedBrand(response.data.data[0]);
        }
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
        setError(errorMessage);
      } finally {
        setLoading(false);
        setInitialLoading(false);
      }
    };

    fetchReviews();
  }, []);

  // Add shuffle function
  const shuffleArray = <T,>(array: T[]): T[] => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // Get filtered and shuffled reviews
  const filteredReviews = useMemo(() => {
    if (!selectedBrand) return [];

    // First, collect all reviews and deduplicate by ID
    const reviewMap = new Map<number, Review>();
    
    if (selectedLocation === 'all') {
      // For all locations, collect unique reviews by ID
      selectedBrand.locationReviews.forEach(location => {
        if (location.reviews && Array.isArray(location.reviews)) {
          location.reviews.forEach(review => {
            if (!reviewMap.has(review.id)) {
              reviewMap.set(review.id, review);
            }
          });
        }
      });
    } else {
      // For specific location
      const location = selectedBrand.locationReviews.find(loc => 
        loc.id === parseInt(selectedLocation)
      );
      if (location?.reviews && Array.isArray(location.reviews)) {
        location.reviews.forEach(review => {
          if (!reviewMap.has(review.id)) {
            reviewMap.set(review.id, review);
          }
        });
      }
    }

    // Convert map to array
    let reviews = Array.from(reviewMap.values());
    
    // Apply filters
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      reviews = reviews.filter(review => 
        review.body.toLowerCase().includes(term) || 
        (review.reviewerName && review.reviewerName.toLowerCase().includes(term))
      );
    }
    
    if (ratingFilter !== null) {
      reviews = reviews.filter(review => review.rating === ratingFilter);
    }
    
    if (sourceFilter) {
      reviews = reviews.filter(review => review.origin === sourceFilter);
    }
    
    // Shuffle the reviews
    return shuffleArray(reviews);
  }, [selectedBrand, selectedLocation, searchTerm, ratingFilter, sourceFilter]);

  // Calculate statistics for specific location
  const locationStats = useMemo(() => {
    if (!selectedBrand) return [];
    
    return selectedBrand.locationReviews.map(location => {
      // Use a Set to ensure unique reviews per location
      const uniqueReviews = new Set(location.reviews?.map(r => r.id));
      const reviews = location.reviews?.filter(r => uniqueReviews.has(r.id)) || [];
      
      // Calculate average rating
      const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
      const avgRating = reviews.length ? Number((totalRating / reviews.length).toFixed(1)) : 0;
      
      // Count reviews by rating
      const ratingCounts = [0, 0, 0, 0, 0, 0]; // Index 0 is unused, 1-5 for ratings
      reviews.forEach(review => {
        if (review.rating >= 1 && review.rating <= 5) {
          ratingCounts[review.rating]++;
        }
      });
      
      return {
        id: location.id,
        name: location.name,
        address: location.address,
        totalReviews: reviews.length,
        averageRating: avgRating,
        ratingCounts,
        sources: Array.from(new Set(reviews.map(r => r.origin).filter(Boolean)))
      };
    });
  }, [selectedBrand]);

  // Calculate overall statistics
  const overallStats = useMemo(() => {
    if (!selectedBrand) return null;
    
    // Get unique reviews across all locations
    const seenReviewIds = new Set<number>();
    const allReviews: Review[] = [];
    
    selectedBrand.locationReviews.forEach(location => {
      location.reviews?.forEach(review => {
        if (!seenReviewIds.has(review.id)) {
          seenReviewIds.add(review.id);
          allReviews.push(review);
        }
      });
    });
    
    const totalRating = allReviews.reduce((acc, review) => acc + review.rating, 0);
    const avgRating = allReviews.length ? Number((totalRating / allReviews.length).toFixed(1)) : 0;
    
    // Count reviews by rating
    const ratingCounts = [0, 0, 0, 0, 0, 0]; // Index 0 is unused, 1-5 for ratings
    allReviews.forEach(review => {
      if (review.rating >= 1 && review.rating <= 5) {
        ratingCounts[review.rating]++;
      }
    });
    
    return {
      totalReviews: allReviews.length,
      averageRating: avgRating,
      ratingCounts,
      sources: Array.from(new Set(allReviews.map(r => r.origin).filter(Boolean)))
    };
  }, [selectedBrand]);

  // Get unique sources for filter dropdown
  const sources = useMemo(() => {
    if (!selectedBrand) return [];
    
    const allSources = new Set<string>();
    
    selectedBrand.locationReviews.forEach(location => {
      location.reviews.forEach(review => {
        if (review.origin) {
          allSources.add(review.origin);
        }
      });
    });
    
    return Array.from(allSources).map(source => ({
      key: source,
      text: source,
      value: source
    }));
  }, [selectedBrand]);

  // Pagination logic
  const paginatedReviews = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredReviews.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredReviews, currentPage, itemsPerPage]);

  const totalPages = useMemo(() => {
    return Math.ceil(filteredReviews.length / itemsPerPage);
  }, [filteredReviews, itemsPerPage]);

  const handlePageChange = (_: React.MouseEvent, data: any) => {
    setCurrentPage(data.activePage);
  };

  const handleRatingFilter = (rating: number) => {
    setRatingFilter(prevRating => prevRating === rating ? null : rating);
    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    setSearchTerm('');
    setRatingFilter(null);
    setSourceFilter('');
    setDateSort('desc');
    setCurrentPage(1);
  };

  const handleBrandChange = (brandId: number) => {
    console.log('Changing brand to:', brandId);
    const brand = allBrands.find(b => b.id === brandId) || null;
    console.log('Found brand:', brand?.name);
    setSelectedBrand(brand);
    setSelectedLocation('all');
    setCurrentPage(1);
    setSearchTerm('');
    setRatingFilter(null);
    setSourceFilter('');
    setDateSort('desc');
  };

  const handleLocationChange = (locationId: string) => {
    console.log('Changing location to:', locationId);
    setSelectedLocation(locationId);
    setCurrentPage(1);
    setSearchTerm('');
    setRatingFilter(null);
    setSourceFilter('');
    setDateSort('desc');
  };

  const renderStars = (rating: number) => {
    return <Rating icon="star" rating={rating} maxRating={5} disabled />;
  };

  const renderRatingBar = (count: number, total: number) => {
    const percentage = total > 0 ? (count / total) * 100 : 0;
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <div style={{ width: '100%', backgroundColor: '#e0e0e0', height: '8px', borderRadius: '4px' }}>
          <div 
            style={{ 
              width: `${percentage}%`, 
              backgroundColor: '#2185d0', 
              height: '8px', 
              borderRadius: '4px' 
            }} 
          />
        </div>
        <span style={{ marginLeft: '10px', minWidth: '30px' }}>{count}</span>
      </div>
    );
  };

  // Format date helper
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Handle marking a review as read
  const handleMarkAsRead = async (reviewId: number) => {
    try {
      setLoading(true);
      // Use the new endpoint instead of the action
      await axios.post(`https://wp.cdp.dental/cx/reviews/${reviewId}/read`);
      
      // Update the review in state
      setAllBrands(prevBrands => 
        prevBrands.map(brand => ({
          ...brand,
          locationReviews: brand.locationReviews.map(location => ({
            ...location,
            reviews: location.reviews.map(review => 
              review.id === reviewId 
                ? { ...review, read: true }
                : review
            )
          }))
        }))
      );
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to mark review as read';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Get review status
  const getReviewStatus = (review: Review): { color: string; text: string; icon: SemanticICONS } => {
    if (review.repliedAt) {
      return { color: 'green', text: 'Replied', icon: 'check' };
    }
    if (review.read) {
      return { color: 'blue', text: 'Read', icon: 'eye' };
    }
    return { color: 'yellow', text: 'New', icon: 'star' };
  };

  const renderReviewActions = (review: Review) => (
    <div className="review-actions">
      {!review.repliedAt && (
        <Button
          primary
          size="tiny"
          onClick={() => {
            setSelectedReview(review);
            setShowReplyModal(true);
          }}
        >
          <Icon name="reply" />
          Reply
        </Button>
      )}
      {review.repliedAt && (
        <div className="replied-badge">
          <Icon name="check circle" color="green" />
          Replied
        </div>
      )}
    </div>
  );

  // Render review table row
  const renderReviewRow = (review: Review) => {
    const status = getReviewStatus(review);
    const locationName = selectedLocation === 'all' && selectedBrand 
      ? selectedBrand.locationReviews.find(loc => 
          loc.reviews.some(r => r.id === review.id)
        )?.name || ''
      : '';

    return (
      <Table.Row key={review.id} className={!review.read ? 'unread-review' : ''}>
        <Table.Cell>
          <div className="ui items">
            <div className="item">
              <div className="content">
                <div className="header">{review.reviewerName || 'Anonymous'}</div>
                <div className="meta">
                  <span className={`ui ${status.color} label tiny`}>
                    <Icon name={status.icon} />
                    {status.text}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell>
          {renderStars(review.rating)}
          {review.normalizedRating !== review.rating && (
            <div className="meta">
              <small>Normalized: {review.normalizedRating}</small>
            </div>
          )}
        </Table.Cell>
        <Table.Cell>
          <div className="review-content">
            <div className="review-header">
              <strong>{review.reviewerName || 'Anonymous'}</strong>
              <span className="review-date">{formatDate(review.reviewDate)}</span>
            </div>
            <div className="review-rating">
              {renderStars(review.rating)}
            </div>
            {review.title && (
              <div className="review-title">{review.title}</div>
            )}
            <div className="review-body">{review.body}</div>
            {renderReviewActions(review)}
          </div>
        </Table.Cell>
        <Table.Cell>
          <div>{formatDate(review.reviewDate)}</div>
          {review.reviewUpdatedDate !== review.reviewDate && (
            <small className="meta">
              Updated: {formatDate(review.reviewUpdatedDate)}
            </small>
          )}
        </Table.Cell>
        <Table.Cell>
          <div className="ui label">
            <Icon name="linkify" />
            {review.origin}
            {review.externalId && (
              <div className="detail">{review.externalId.slice(0, 8)}...</div>
            )}
          </div>
        </Table.Cell>
        {selectedLocation === 'all' && (
          <Table.Cell>{locationName}</Table.Cell>
        )}
      </Table.Row>
    );
  };

  // Handle reply submission
  const handleReplySubmit = async () => {
    if (!selectedReview || !replyText.trim()) return;

    try {
      setLoading(true);
      // Use the new endpoint instead of the action
      await axios.post(`https://wp.cdp.dental/cx/reviews/${selectedReview.id}/reply`, {
        reply: replyText
      });
      
      // Update the review in state
      setAllBrands(prevBrands => 
        prevBrands.map(brand => ({
          ...brand,
          locationReviews: brand.locationReviews.map(location => ({
            ...location,
            reviews: location.reviews.map(review => 
              review.id === selectedReview.id 
                ? { ...review, repliedAt: new Date().toISOString() }
                : review
            )
          }))
        }))
      );

      // Reset state
      setReplyText('');
      setShowReplyModal(false);
      setSelectedReview(null);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to submit reply';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Add some CSS styles for the component
  const styles = `
    .reviews-dashboard-root {
      font-family: 'Poppins', sans-serif;
      padding: 20px;
    }
    
    .reviews-dashboard-root h1 {
      font-weight: 700;
      margin-bottom: 20px;
    }
    
    .reviews-data-section {
      background: white;
      border-radius: 8px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.1);
      padding: 20px;
      margin-bottom: 20px;
    }
    
    .table-top-actions {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    
    .table-selector {
      margin-bottom: 20px;
    }
    
    .table-selector-list {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      border-bottom: 1px solid #e0e0e0;
    }
    
    .table-selector-list li {
      margin-right: 20px;
    }
    
    .table-selector-list li a {
      display: block;
      padding: 10px 0;
      color: #666;
      text-decoration: none;
    }
    
    .table-selector-list li.active a {
      color: #2185d0;
      border-bottom: 2px solid #2185d0;
      font-weight: 600;
    }
    
    .table-search {
      margin-bottom: 20px;
    }
    
    .table-search input {
      width: 100%;
      padding: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
    
    .stat-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
      margin-bottom: 20px;
    }
    
    .stat-card {
      background: white;
      border-radius: 8px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.1);
      padding: 20px;
    }
    
    .stat-card h4 {
      color: #666;
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 14px;
    }
    
    .stat-card h2 {
      margin: 0;
      font-size: 24px;
      font-weight: 700;
    }
    
    .review-actions {
      display: flex;
      gap: 10px;
    }
    
    .review-actions a, .review-actions button {
      padding: 6px 12px;
      border-radius: 4px;
      text-decoration: none;
      font-size: 12px;
      font-weight: 500;
    }
    
    .review-actions a.is-danger, .review-actions button.is-danger {
      color: #db2828;
      border: 1px solid #db2828;
    }
    
    .review-actions a.is-primary, .review-actions button.is-primary {
      background: #2185d0;
      color: white;
      border: none;
    }
    
    .unread-review {
      background-color: rgba(33, 133, 208, 0.05) !important;
    }
    
    .replied-badge {
      display: inline-flex;
      align-items: center;
      color: #21ba45;
      font-size: 0.9em;
    }
    
    .replied-badge i.icon {
      margin-right: 5px;
    }
    
    .meta {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.9em;
      margin-top: 4px;
    }
    
    .review-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    
    .review-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .review-date {
      font-size: 0.9em;
      color: #666;
    }
    
    .review-title {
      font-weight: 600;
    }
    
    .review-body {
      margin-bottom: 10px;
    }
    
    .filter-buttons {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
    }
    
    .table-status-online {
      display: flex;
      align-items: center;
      color: #21ba45;
    }
    
    .table-status-online svg {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
    
    .highlighted-label {
      display: inline-block;
      padding: 6px 12px;
      background: #2185d0;
      color: white;
      border-radius: 4px;
      text-decoration: none;
      font-weight: 500;
    }
  `;

  if (error) return (
    <Template activeLink='reviews'>
      <style>{styles}</style>
      <div className="reviews-dashboard-root">
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{error}</p>
        </Message>
      </div>
    </Template>
  );

  if (initialLoading) return (
    <Template activeLink='reviews'>
      <style>{styles}</style>
      <div className="reviews-dashboard-root">
        <Dimmer active>
          <Loader size="large">Loading Reviews...</Loader>
        </Dimmer>
      </div>
    </Template>
  );

  return (
    <Template activeLink='reviews'>
      <style>{styles}</style>
      <div className="reviews-dashboard-root">
        <h1>
          <Icon name="star" />
          Reviews Dashboard
        </h1>

        <div className="reviews-data-section">
          <div className="table-top-actions">
            <div>
              <Dropdown
                placeholder="Select Brand"
                selection
                options={allBrands.map(brand => ({
                  key: brand.id,
                  text: brand.name,
                  value: brand.id
                }))}
                onChange={(_, data) => handleBrandChange(data.value as number)}
                value={selectedBrand?.id || ''}
              />
              
              {selectedBrand && (
                <Dropdown
                  placeholder="Select Location"
                  selection
                  style={{ marginLeft: '10px' }}
                  options={[
                    { key: 'all', text: 'All Locations', value: 'all' },
                    ...selectedBrand.locationReviews.map(location => ({
                      key: location.id,
                      text: location.name,
                      value: location.id
                    }))
                  ]}
                  onChange={(_, data) => handleLocationChange(data.value as string)}
                  value={selectedLocation}
                />
              )}
            </div>
          </div>

          {selectedBrand && (
            <div className="stat-grid">
              <div className="stat-card">
                <h4>Total Reviews</h4>
                <h2>{overallStats?.totalReviews || 0}</h2>
              </div>
              <div className="stat-card">
                <h4>Average Rating</h4>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h2>{overallStats?.averageRating || 0}</h2>
                  <div style={{ marginLeft: '10px' }}>
                    {renderStars(overallStats?.averageRating || 0)}
                  </div>
                </div>
              </div>
              <div className="stat-card">
                <h4>Sources</h4>
                <div>
                  {overallStats?.sources.map(source => (
                    <div key={source} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                      <Icon name="linkify" style={{ marginRight: '5px' }} />
                      {source}
                    </div>
                  ))}
                </div>
              </div>
              <div className="stat-card">
                <h4>Rating Distribution</h4>
                <div>
                  {[5, 4, 3, 2, 1].map(rating => (
                    <div key={rating} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <div style={{ width: '30px' }}>{rating}</div>
                      <Icon name="star" style={{ marginRight: '10px' }} />
                      {renderRatingBar(overallStats?.ratingCounts[rating] || 0, overallStats?.totalReviews || 0)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="table-selector">
            <ul className="table-selector-list">
              <li className={activeTab === 0 ? 'active' : ''}>
                <a href="javascript:void(0)" onClick={() => setActiveTab(0)}>Overview</a>
              </li>
              <li className={activeTab === 1 ? 'active' : ''}>
                <a href="javascript:void(0)" onClick={() => setActiveTab(1)}>Reviews</a>
              </li>
              <li className={activeTab === 2 ? 'active' : ''}>
                <a href="javascript:void(0)" onClick={() => setActiveTab(2)}>Location Details</a>
              </li>
            </ul>
          </div>

          {activeTab === 0 && (
            <div>
              {selectedBrand && overallStats && (
                <div>
                  <div className="reviews-data-section">
                    <h2>Locations Overview</h2>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Location</Table.HeaderCell>
                          <Table.HeaderCell>Address</Table.HeaderCell>
                          <Table.HeaderCell>Total Reviews</Table.HeaderCell>
                          <Table.HeaderCell>Average Rating</Table.HeaderCell>
                          <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {locationStats.map(location => (
                          <Table.Row key={location.id}>
                            <Table.Cell>{location.name}</Table.Cell>
                            <Table.Cell>{location.address}</Table.Cell>
                            <Table.Cell>{location.totalReviews}</Table.Cell>
                            <Table.Cell>
                              {renderStars(location.averageRating)}
                              <span style={{ marginLeft: '10px' }}>{location.averageRating}</span>
                            </Table.Cell>
                            <Table.Cell>
                              <div className="review-actions">
                                <Button 
                                  primary 
                                  size="small"
                                  onClick={() => {
                                    handleLocationChange(location.id.toString());
                                    setActiveTab(1);
                                  }}
                                >
                                  View Reviews
                                </Button>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              )}
            </div>
          )}

          {activeTab === 1 && (
            <div>
              <div className="table-search">
                <div className="ui stackable grid">
                  <div className="four column row">
                    <div className="column">
                      <Input 
                        icon="search" 
                        placeholder="Search reviews..." 
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          setCurrentPage(1);
                        }}
                        fluid
                      />
                    </div>
                    <div className="column">
                      <Dropdown
                        placeholder="Filter by source"
                        fluid
                        selection
                        clearable
                        options={[
                          { key: '', text: 'All Sources', value: '' },
                          ...sources
                        ]}
                        onChange={(_, data) => {
                          setSourceFilter(data.value as string);
                          setCurrentPage(1);
                        }}
                        value={sourceFilter}
                      />
                    </div>
                    <div className="column">
                      <Dropdown
                        placeholder="Sort by date"
                        fluid
                        selection
                        options={[
                          { key: 'desc', text: 'Newest First', value: 'desc' },
                          { key: 'asc', text: 'Oldest First', value: 'asc' }
                        ]}
                        onChange={(_, data) => {
                          setDateSort(data.value as 'asc' | 'desc');
                          setCurrentPage(1);
                        }}
                        value={dateSort}
                      />
                    </div>
                    <div className="column">
                      <Button 
                        fluid 
                        onClick={handleClearFilters}
                        disabled={!searchTerm && ratingFilter === null && !sourceFilter && dateSort === 'desc'}
                      >
                        Clear Filters
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="filter-buttons">
                {[1, 2, 3, 4, 5].map(rating => (
                  <Button 
                    key={rating} 
                    toggle 
                    active={ratingFilter === rating}
                    onClick={() => handleRatingFilter(rating)}
                  >
                    {rating} <Icon name="star" />
                  </Button>
                ))}
              </div>

              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Reviewer</Table.HeaderCell>
                    <Table.HeaderCell>Rating</Table.HeaderCell>
                    <Table.HeaderCell>Review</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Source</Table.HeaderCell>
                    {selectedLocation === 'all' && <Table.HeaderCell>Location</Table.HeaderCell>}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {loading ? (
                    <Table.Row>
                      <Table.Cell colSpan={selectedLocation === 'all' ? 6 : 5} textAlign="center">
                        <div className="ui placeholder segment">
                          <div className="ui icon header">
                            <Loader active inline="centered" />
                            Loading reviews...
                          </div>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ) : paginatedReviews.length === 0 ? (
                    <Table.Row>
                      <Table.Cell colSpan={selectedLocation === 'all' ? 6 : 5} textAlign="center">
                        <div className="ui placeholder segment">
                          <div className="ui icon header">
                            <Icon name="search" />
                            No reviews found
                          </div>
                          {(searchTerm || ratingFilter !== null || sourceFilter) && (
                            <Button primary onClick={handleClearFilters}>Clear Filters</Button>
                          )}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    paginatedReviews.map(review => renderReviewRow(review))
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan={selectedLocation === 'all' ? 6 : 5}>
                      <div className="ui two column stackable grid">
                        <div className="column">
                          <Dropdown
                            selection
                            compact
                            options={[
                              { key: 10, text: '10 per page', value: 10 },
                              { key: 25, text: '25 per page', value: 25 },
                              { key: 50, text: '50 per page', value: 50 },
                              { key: 100, text: '100 per page', value: 100 },
                            ]}
                            value={itemsPerPage}
                            onChange={(_, data) => {
                              setItemsPerPage(Number(data.value));
                              setCurrentPage(1);
                            }}
                          />
                          <span style={{ marginLeft: '15px' }}>
                            Showing {Math.min(filteredReviews.length, (currentPage - 1) * itemsPerPage + 1)} - {Math.min(filteredReviews.length, currentPage * itemsPerPage)} of {filteredReviews.length} reviews
                          </span>
                        </div>
                        <div className="column">
                          <Pagination
                            activePage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                            firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                            lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                            prevItem={{ content: <Icon name="angle left" />, icon: true }}
                            nextItem={{ content: <Icon name="angle right" />, icon: true }}
                            floated="right"
                          />
                        </div>
                      </div>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </div>
          )}

          {activeTab === 2 && (
            <div>
              {selectedBrand && selectedLocation !== 'all' && (
                <div>
                  {locationStats
                    .filter(loc => loc.id.toString() === selectedLocation)
                    .map(location => (
                      <React.Fragment key={location.id}>
                        <div className="reviews-data-section">
                          <h2>{location.name}</h2>
                          <p>{location.address}</p>
                        </div>
                        
                        <div className="stat-grid">
                          <div className="stat-card">
                            <h4>Total Reviews</h4>
                            <h2>{location.totalReviews}</h2>
                          </div>
                          <div className="stat-card">
                            <h4>Average Rating</h4>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <h2>{location.averageRating}</h2>
                              <div style={{ marginLeft: '10px' }}>
                                {renderStars(location.averageRating)}
                              </div>
                            </div>
                          </div>
                          <div className="stat-card">
                            <h4>Sources</h4>
                            <div>
                              {location.sources.map(source => (
                                <div key={source} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                  <Icon name="linkify" style={{ marginRight: '5px' }} />
                                  {source}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="stat-card">
                            <h4>Rating Distribution</h4>
                            <div>
                              {[5, 4, 3, 2, 1].map(rating => (
                                <div key={rating} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                  <div style={{ width: '30px' }}>{rating}</div>
                                  <Icon name="star" style={{ marginRight: '10px' }} />
                                  {renderRatingBar(location.ratingCounts[rating], location.totalReviews)}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        
                        <Button 
                          primary
                          onClick={() => {
                            setActiveTab(1);
                          }}
                        >
                          View Reviews for this Location
                        </Button>
                      </React.Fragment>
                    ))}
                </div>
              )}
              
              {selectedLocation === 'all' && (
                <div className="ui placeholder segment">
                  <div className="ui icon header">
                    <Icon name="building" />
                    Please select a specific location to view details
                  </div>
                  <div className="ui divider"></div>
                  <div>
                    <Dropdown
                      placeholder="Select Location"
                      fluid
                      selection
                      options={selectedBrand?.locationReviews.map(location => ({
                        key: location.id,
                        text: location.name,
                        value: location.id
                      })) || []}
                      onChange={(_, data) => handleLocationChange(data.value as string)}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Reply Modal */}
        <Modal
          open={showReplyModal}
          onClose={() => {
            setShowReplyModal(false);
            setSelectedReview(null);
            setReplyText('');
          }}
          size="small"
        >
          <Modal.Header>Reply to Review</Modal.Header>
          <Modal.Content>
            {selectedReview && (
              <>
                <div className="review-details" style={{ marginBottom: '20px' }}>
                  <div style={{ marginBottom: '10px' }}>
                    <strong>{selectedReview.reviewerName || 'Anonymous'}</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {renderStars(selectedReview.rating)}
                    </span>
                  </div>
                  {selectedReview.title && (
                    <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                      {selectedReview.title}
                    </div>
                  )}
                  <div style={{ marginBottom: '10px' }}>
                    {selectedReview.body}
                  </div>
                  <div className="meta">
                    {formatDate(selectedReview.reviewDate)}
                  </div>
                </div>
                <Form>
                  <Form.TextArea
                    value={replyText}
                    onChange={(e) => setReplyText(e.currentTarget.value)}
                    placeholder="Type your reply here..."
                    rows={4}
                  />
                </Form>
              </>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button 
              negative
              onClick={() => {
                setShowReplyModal(false);
                setSelectedReview(null);
                setReplyText('');
              }}
            >
              Cancel
            </Button>
            <Button 
              positive
              loading={loading}
              disabled={!replyText.trim()}
              onClick={handleReplySubmit}
            >
              Submit Reply
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    </Template>
  );
};

export default ReviewDashboard;