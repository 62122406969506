import React, {useEffect, useState} from 'react';
import { Button, Checkbox, Form, Grid, Select, Header, FormGroup, FormField, Segment, Card, GridRow, Popup, Tab, Table, Input } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { baseDentistTypeList, contractName, dentistShiftSpecialityList, dentistSubTypeList, dentistWorkTimeType, genderOptions, notificationPreferences, payTypeOptions, schedulePermissions, tierControllerOptions, udrPermissions, weekdayPreference } from './constants';
import moment from 'moment';
import {isArray} from "lodash";
import * as UKGActions from "../../redux/actions/UKGServices";
import {useActions} from "../../redux/actions";
import {terminationOptions} from "./constants"



interface EditProviderProfileFormProps {
    cancelForm: any,
    saveForm: any,
    initialValues: any,
    practicesOptions: any,
    deleteProfile: any,
    setOpenOrthoDayRateModal: any,
    setOpenProviderDayRateModal: any,
    setOpenProviderRampThresholdModal: any
    setOpenDoctorBaseWorkDaysModal: any
}

interface TierManagement {
    id?: number;
    providerId: number;
    tierType: number;
    amountFrom?: number;
    amountTo?: number;
    percentValue?: number;
    practiceId: number;
    isDeleted?: boolean;
}

function EditProviderProfileForm(props: EditProviderProfileFormProps) {
    const getValue = (key: string) => {
        const { UdaDrLists } = props.initialValues;
        if (!UdaDrLists) return "";
        if (!Array.isArray(UdaDrLists)) return "";
        const firstObj = UdaDrLists[0];
        if (!firstObj) return "";
        
        if (key.includes('.')) {
            const [parent, child] = key.split('.');
            if (firstObj[parent] && typeof firstObj[parent] === 'object') {
                return firstObj[parent][child] || "";
            }
        }
        
        if (Object.keys(firstObj).includes(key)) {
            return firstObj[key];
        }
        return "";
    }

    const setInitialFormDates = (value: string) => {
        const potentialDateValue = new Date(value)
        if (potentialDateValue instanceof Date && !isNaN(potentialDateValue.getTime())) {
            return potentialDateValue
        } else {
            return new Date();
        }
    }

    const initialState = {
        editForm:
        {
            ...props.initialValues,
            dateOfBirth: setInitialFormDates(props.initialValues.dateOfBirth),
            hireDate: setInitialFormDates(props.initialValues.hireDate),
            contractStart: setInitialFormDates(props.initialValues.contractStart),
            contractEnd: setInitialFormDates(props.initialValues.contractEnd),
            StateLicense: getValue("StateLicense"),
            StateWhereLicensed: getValue("StateWhereLicensed"),
            wHynContractStart:setInitialFormDates(props.initialValues?.wHynContractStart),
            terminationDate:setInitialFormDates(props.initialValues?.terminationDate),
            tedCollections: props.initialValues?.tedCollections || false,
            tierContract: props.initialValues?.tierContract || false,
            tierController: props.initialValues?.tierController,
        }
    }
    const { cancelForm, saveForm, initialValues, practicesOptions, deleteProfile, setOpenOrthoDayRateModal,
        setOpenProviderDayRateModal, setOpenProviderRampThresholdModal, setOpenDoctorBaseWorkDaysModal } = props;
    const handleDateChange = (date: any, name: string) => {
        const { editForm } = state;
        editForm[name] = moment(date).toDate();
        setState({ ...state, editForm });
    };


    const [state, setState] = useState(initialState);
    const ukgActions = useActions(UKGActions);
    const [ukgProfiles, setUkgProfiles] = useState({} as any);
    const [tierManagements, setTierManagements] = useState<TierManagement[]>(
        (props.initialValues?.TierManagements || [{
            providerId: props.initialValues.UserID,
            tierType: 0,
            amountFrom: undefined,
            amountTo: undefined,
            percentValue: undefined,
            practiceId: 0,
            isDeleted: false
        }]).map((tier:any) => ({
            ...tier,
            isDeleted: false
        }))
    );

    useEffect(() => {
        const getUKGProfiles = async (firstName: string, lastName: string) => {
            const positionData = await ukgActions.getPositionDetails(`${firstName}`);
            if(positionData.data.length) {
                const profile = positionData.data.find((item: any) => {
                    const ukgId = (state.editForm.ukgid?.toString().trim() || '');
                    const employeeId = (item.employee_id?.toString().trim() || '');
                    return ukgId === employeeId;
                  });
                if  (profile) {
                    setUkgProfiles(profile)
                }
            }
            setUkgProfiles(positionData);
        };
        getUKGProfiles(state.editForm.firstName, state.editForm.lastName)
    }, [])




    const onChange = (e: any) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            editForm: {
                ...prevState.editForm,
                [name]: value,
            }
        }));
    };

    const onChangeDayRate = (e: any) => {
        const {name, value} = e.target;
        setState({editForm: {...state.editForm, [name]: value}})
    }

    const handleCheckBox = (value: any, name: string) => {
        let { editForm } = state
        editForm[name] = +value;
        setState({ ...state, editForm });
    };


    const onChangePermission = (_event: any, data: any) => {
        let { editForm } = state;
        editForm[data.name] = data.value;
        setState({ ...state, editForm: editForm });
    };

    const onChangeIsPRN = (_event: any, data: any) => {
        let { editForm } = state;
        editForm[data.name] = data.value;
        setState({ ...state, editForm: editForm });
    };

    const getOrthodonticsAndUdaRole = (udrUsersPermissionsVal: any) => {
        let udaRole: any = [];
        let isOrthodontics = false;
        if (udrUsersPermissionsVal.length) {
            udrUsersPermissionsVal.forEach((item: any) => {
                if (item.udrPermissions) {
                    udrPermissions.forEach((permissions) => {
                        if (item.udrPermissions.id == +permissions.value)
                            udaRole.push(permissions.value)
                    });
                    if (item.udrPermissions.role === 'Orthodontics') {
                        isOrthodontics = true;
                    }
                }
            })
        }
        return { udaRole, isOrthodontics }
    }

    const onChangeMultiSelect = (_event: any, data: any) => {
        let { editForm } = state;
        editForm[data.name] = data.value;
        setState({ ...state, editForm: editForm });
    };

    const getPracticeOptions = () => {
        return [{ key: "All", value: "all", text: "All" }, ...practicesOptions];
    }

    const getTierManagementPracticeOptions = () => {
        return [
            { key: "All", value: 0, text: "All" }, 
            ...practicesOptions.map((practice :any)=> ({
                key: practice.key,
                value: parseInt(practice.key), // Using practice ID as value instead of text
                text: practice.text
            }))
        ];
    }

    const isEligibleForRatesByPractice = () => {
        const eligibleContracts = ['GOX', 'GOZ', 'Greater Of'];
        return eligibleContracts.includes(state.editForm.contractName);
    };

    const handleTierManagementChange = (index: number, field: keyof TierManagement, value: any) => {
        setTierManagements(prev => {
            const updated = [...prev];
            updated[index] = {
                ...updated[index],
                [field]: value
            };
            return updated;
        });
    };

    const addTierManagement = () => {
        setTierManagements(prev => [...prev, {
            providerId: props.initialValues.UserID,
            tierType: 0,
            amountFrom: undefined,
            amountTo: undefined,
            percentValue: undefined,
            practiceId: 0,
            isDeleted: false
        }]);
    };

    const removeTierManagement = (index: number) => {
        setTierManagements(prev => prev.map((tier, i) => 
            i === index ? { ...tier, isDeleted: true } : tier
        ));
    };

    const saveFormLocal = () => {
        const { credentialingDocs, dentistWorkTimeType, id, UserID, docEmail, firstName, lastName, abbr, personalEmail,
            docMobile, address, notificationPrefer, locPrefer, weekdayPrefer, workHistory, role, contractStart, contractEnd,
            homeLocation, canWork, dentistType, dentistSubType, dentistShiftType, dentistShiftSpecialty, status, profilePic,
            contractName, prodAdjWo, dailyRatePay, dailyRateProd, payOverBase, monthlyBasePay, monthlyBaseProduction,
            monthlyBaseWorkDays, denovoMonthlyBasePay, denovoMonthlyWorkDays, firstStart, payTimeOffDays, goxDailyRatePay,
            goxPercentagePay, orthoDayRate, startBonus, sdStartBonus, sdContract, travelStipend, ukgid, debondBonus, dotBonus,
            consultPrice,ph1StartBonus, consultPay, gender, dateOfBirth, hireDate, payType, monthlyRequiredDays, isAuditee,
            isAuditor, isAuditAdmin,includeHygienist,wHynContractStart,terminationDate,terminationType, tedCollections,
            pH1Start, isPRN, tierContract ,tierController} = state.editForm;
        const initialRoles = initialValues.UdrUsersPermissions
        let udaRole = state.editForm.udaRole;
        if(isArray(initialRoles) && initialRoles.length > 0 && udaRole === undefined){
            udaRole = initialRoles.map((value, index) => {
                return value.roleid
            })
        }
        let obj = {
            dentistWorkTimeType,
            credentialingDocs,
            id,
            UserID,
            docEmail,
            firstName,
            lastName,
            abbr,
            personalEmail,
            docMobile,
            address,
            notificationPrefer,
            locPrefer,
            weekdayPrefer,
            workHistory,
            udaRole,
            role,
            contractStart,
            contractEnd,
            homeLocation,
            canWork,
            dentistType,
            dentistSubType,
            dentistShiftType,
            dentistShiftSpecialty,
            status,
            profilePic,
            dateOfBirth:moment(dateOfBirth).toDate(),
            gender,
            hireDate,
            payType,
            monthlyRequiredDays,
            StateLicense: state.editForm.StateLicense,
            StateWhereLicensed: state.editForm.StateWhereLicensed,
            ukgid: payType === '1099' ? null : ukgid,
            isAuditAdmin,
            isAuditee,
            isAuditor,
            includeHygienist,
            wHynContractStart,
            terminationDate,
            terminationType:state.editForm.terminationType,
            tedCollections: state.editForm.tedCollections,
            tierContract: state.editForm.tierContract,
            tierController,
            isPRN
        };
        let udaObj = {
            userId: UserID, email: docEmail, firstName, lastName, abbr, contractName, prodAdjWo, dailyRatePay, dailyRateProd,
            payOverBase, monthlyBasePay, monthlyBaseProduction, monthlyBaseWorkDays, denovoMonthlyBasePay, denovoMonthlyWorkDays, firstStart, payTimeOffDays, goxDailyRatePay, goxPercentagePay,
            orthoDayRate,startBonus, sdStartBonus, sdContract, travelStipend, debondBonus, dotBonus, consultPrice, consultPay, dentistTypes: udaRole, StateLicense: state.editForm.StateLicense,
            StateWhereLicensed: state.editForm.StateWhereLicensed, monthlyRequiredDays: state.editForm.monthlyRequiredDays,isActive:status,docTabId:id,
            ph1StartBonus: Number(state.editForm.ph1StartBonus) || 0,
        };
        udaObj.startBonus = udaObj.startBonus ?? 0;
        udaObj.sdStartBonus = udaObj.sdStartBonus  ?? 0;
        udaObj.sdContract = udaObj.sdContract ?? 0;
        udaObj.travelStipend = udaObj.travelStipend ?? 0;
        udaObj.prodAdjWo = udaObj.prodAdjWo ?? 0;
        udaObj.dailyRatePay = udaObj.dailyRatePay ?? 0;
        udaObj.dailyRateProd = udaObj.dailyRateProd ?? 0;
        udaObj.payOverBase = udaObj.payOverBase ?? 0;
        udaObj.monthlyBasePay = udaObj.monthlyBasePay ?? 0;
        udaObj.monthlyBaseProduction = udaObj.monthlyBaseProduction ?? 0;
        udaObj.monthlyBaseWorkDays = udaObj.monthlyBaseWorkDays ?? 0;
        udaObj.denovoMonthlyBasePay = udaObj.denovoMonthlyBasePay ?? 0;
        udaObj.denovoMonthlyWorkDays = udaObj.denovoMonthlyWorkDays ?? 0;
        udaObj.payTimeOffDays = udaObj.payTimeOffDays ?? 0;
        udaObj.goxDailyRatePay = udaObj.goxDailyRatePay ?? 0;
        udaObj.goxPercentagePay = udaObj.goxPercentagePay ?? 0;
        udaObj.orthoDayRate = udaObj.orthoDayRate ?? 0;
        udaObj.debondBonus = udaObj.debondBonus ?? 0;
        udaObj.dotBonus = udaObj.dotBonus ?? 0;
        udaObj.consultPrice = udaObj.consultPrice ?? 0;
        udaObj.StateLicense = udaObj.StateLicense ?? 0;
        udaObj.StateWhereLicensed = udaObj.StateWhereLicensed ?? 0;
        udaObj.monthlyRequiredDays = udaObj.monthlyRequiredDays ?? 0;
        udaObj.ph1StartBonus = udaObj.ph1StartBonus ?? 0;
        const finalObj = {
            ...obj,
            tierManagement: tierManagements
        };
        saveForm(finalObj, udaObj);
    }

    const { udaRole, isOrthodontics } = getOrthodonticsAndUdaRole(initialValues.UdrUsersPermissions);

    const panes = [
        {
            menuItem: 'Personal Information',
            render: () => (
                <Tab.Pane>
                    <Card color='yellow' fluid>
                        <Card.Content header='PERSONAL INFORMATION' />
                        <Card.Content>
                            <Grid.Row>
                                <Form.Group widths='equal'>
                                    <Form.Input
                                        fluid={true}
                                        label='First Name'
                                        placeholder='First Name'
                                        defaultValue={state.editForm.firstName}
                                        name={'firstName'}
                                        onChange={onChange}
                                    />
                                    <Form.Input
                                        fluid={true}
                                        label='Last Name'
                                        placeholder='Last Name'
                                        defaultValue={state.editForm.lastName}
                                        name={'lastName'}
                                        onChange={onChange}
                                    />
                                    <Form.Input
                                        fluid={true}
                                        label='Cell Phone'
                                        placeholder='Cell Phone'
                                        defaultValue={state.editForm.docMobile}
                                        name={'docMobile'}
                                        onChange={onChange}
                                    />
                                    <Form.Input
                                        fluid={true}
                                        label='Home Address'
                                        placeholder='Home Address'
                                        defaultValue={state.editForm.address}
                                        name={'address'}
                                        onChange={onChange}
                                    />
                                </Form.Group>
                            </Grid.Row>
                            <Grid.Row>
                                <Form.Group widths='equal'>
                                    <div className="field">
                                        <label>Date Of Birth</label>
                                        <div className="datePicker widthFluid">
                                            <DatePicker
                                                name='dateOfBirth'
                                                selected={state.editForm.dateOfBirth}
                                                onChange={(date, _) => handleDateChange(date, 'dateOfBirth')}
                                            />
                                        </div>
                                    </div>
                                    <Form.Input
                                        fluid={true}
                                        disabled={true}
                                        label='Work Email'
                                        placeholder='Work Email'
                                        defaultValue={state.editForm.docEmail}
                                        name={'docEmail'}
                                        onChange={onChange}
                                    />
                                    <Form.Input
                                        fluid={true}
                                        label='Personal Email'
                                        placeholder='Personal Email'
                                        defaultValue={state.editForm.personalEmail}
                                        name={'personalEmail'}
                                        onChange={onChange}
                                    />

                                    <Form.Field><label>Gender</label>
                                        <Select
                                            placeholder='Gender'
                                            options={genderOptions}
                                            defaultValue={state.editForm.gender || ""}
                                            name="gender"
                                            onChange={onChangePermission}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Grid.Row>
                        </Card.Content>
                    </Card>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Work Detail',
            render: () => (
                <Tab.Pane>
                    <Card fluid>
                        <Card.Content header='WORK DETAIL' />
                        <Card.Content>
                            <Grid.Row>
                                <FormGroup widths="equal">
                                    <Form.Field> <label>Notification Preference</label>
                                        <Select
                                            placeholder='Preference'
                                            name="notificationPrefer"
                                            options={notificationPreferences}
                                            onChange={onChangeMultiSelect}
                                            multiple={true}
                                            defaultValue={state.editForm.notificationPrefer || []}
                                        />
                                    </Form.Field>
                                    <Form.Field> <label>Fill In Location Preference</label>
                                        <Select
                                            placeholder='Location Preference'
                                            name="locPrefer"
                                            options={getPracticeOptions()}
                                            onChange={onChangeMultiSelect}
                                            multiple={true}
                                            defaultValue={state.editForm.locPrefer || []}
                                        />
                                    </Form.Field>
                                    <Form.Field><label>Fill In Weekday Preference</label>
                                        <Select
                                            name="weekdayPrefer"
                                            placeholder='Weekday Preference'
                                            options={weekdayPreference}
                                            multiple={true}
                                            onChange={onChangeMultiSelect}
                                            defaultValue={state.editForm.weekdayPrefer || []}
                                        />
                                    </Form.Field>
                                    <Form.Field> <label>UDA Role</label>
                                        <Select
                                            name="udaRole"
                                            placeholder='UDA Role'
                                            options={udrPermissions}
                                            multiple={true}
                                            onChange={onChangeMultiSelect}
                                            defaultValue={state.editForm.udaRole || udaRole}
                                        />
                                    </Form.Field>
                                </FormGroup>
                                <Grid.Row>
                                    <FormGroup widths="equal">
                                        <Form.Field><label>Schedule Role</label>
                                            <Select
                                                name="role"
                                                placeholder='Schedule Role'
                                                options={schedulePermissions}
                                                onChange={onChangeMultiSelect}
                                                multiple={true}
                                                defaultValue={state.editForm.role || []}
                                            />
                                        </Form.Field>
                                        <Form.Input
                                            fluid={true}
                                            label='Dentist ABBR'
                                            placeholder='Dentist ABBR'
                                            defaultValue={state.editForm.abbr}
                                            name={'abbr'}
                                            onChange={onChange}
                                        />
                                        <Form.Field><label>Pay Type</label>
                                            <Select
                                                name="payType"
                                                placeholder='Pay Type'
                                                options={payTypeOptions}
                                                onChange={onChangeMultiSelect}
                                                multiple={true}
                                                defaultValue={state.editForm.payType || []}
                                            />
                                        </Form.Field>
                                        <Form.Input
                                            fluid={true}
                                            label='Work History'
                                            defaultValue={state.editForm.workHistory}
                                            name={'workHistory'}
                                            onChange={onChange}
                                        />
                                    </FormGroup>
                                </Grid.Row>
                                <Grid.Row>
                                    <FormGroup widths='equal'/>


                                </Grid.Row>

                                <Grid.Row>
                                    <FormGroup widths='equal'>
                                        <Form.Input
                                            fluid={true}
                                            label='State License'
                                            defaultValue={state.editForm.StateLicense}
                                            name={'StateLicense'}
                                            onChange={onChange}
                                        />
                                        <Form.Input
                                            fluid={true}
                                            label='State Where Licensed'
                                            defaultValue={state.editForm.StateWhereLicensed}
                                            name={'StateWhereLicensed'}
                                            onChange={onChange}
                                        />
                                    </FormGroup>
                                </Grid.Row>
                                {/* New section for employment termination */}
                                    <Grid.Row>
                                        <FormGroup widths='equal'>
                                            <Form.Field>
                                                <label>Employment Termination Date</label>
                                               {console.log("terminationDate",state.editForm.terminationDate)}   
                                                <DatePicker
                                                    selected={state.editForm.terminationDate}
                                                    onChange={(date, _) => handleDateChange(date, 'terminationDate')}
                                                    placeholderText="Select termination date"
                                                />
                                            </Form.Field>
                                            
                                            <Form.Select
                                                fluid
                                                name="terminationType"
                                                label='Termination Type'
                                                options={terminationOptions}
                                                placeholder='Select termination type'
                                                defaultValue={state.editForm.terminationType}
                                                onChange={onChangeMultiSelect}
                                            />
                                        </FormGroup>
                                    </Grid.Row>
                            </Grid.Row>
                        </Card.Content>
                    </Card>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Specialty Information',
            render: () => (
                <Tab.Pane>
                    <Card fluid>
                        <Card.Content header='SPECIALITY INFORMATION' />
                        <Card.Content>
                            <Grid.Row>
                                <FormGroup widths='equal'>
                                    <Form.Field> <label>Home Location</label>
                                        <Select
                                            name="homeLocation"
                                            placeholder='Home Location'
                                            options={getPracticeOptions()}
                                            onChange={onChangeMultiSelect}
                                            multiple
                                            defaultValue={state.editForm.homeLocation || []}
                                        />
                                    </Form.Field>
                                    <Form.Field><label>Can Work</label>
                                        <Select
                                            name="canWork"
                                            placeholder='Can Work'
                                            options={getPracticeOptions()}
                                            onChange={onChangeMultiSelect}
                                            multiple={true}
                                            defaultValue={state.editForm.canWork || []}
                                        />
                                    </Form.Field>
                                    <Form.Field><label>Base Dentists Type</label>
                                        <Select
                                            name="dentistType"
                                            placeholder='Dentist Type'
                                            options={baseDentistTypeList}
                                            onChange={onChangeMultiSelect}
                                            multiple={true}
                                            defaultValue={state.editForm.dentistType || []}
                                        />
                                    </Form.Field>
                                    <Form.Field> <label>Dentists Sub Type</label>
                                        <Select
                                            name="dentistSubType"
                                            placeholder='Dentist Type'
                                            options={dentistSubTypeList}
                                            onChange={onChangeMultiSelect}
                                            defaultValue={state.editForm.dentistSubType || []}
                                            clearable
                                        />
                                    </Form.Field>
                                </FormGroup>
                            </Grid.Row>
                            <Grid.Row>
                                <FormGroup widths='equal'>
                                    <Form.Field><label>Dentists shift Specialty</label>
                                        <Select
                                            name="dentistShiftSpecialty"
                                            placeholder='Dentists shift Specialty'
                                            options={dentistShiftSpecialityList}
                                            onChange={onChangeMultiSelect}
                                            multiple={true}
                                            defaultValue={state.editForm.dentistShiftSpecialty || []}
                                        />
                                    </Form.Field>
                                    <Form.Field><label>Dentists shift Type</label>
                                        <Select
                                            name="dentistShiftType"
                                            placeholder='Dentists Shift Type'
                                            options={baseDentistTypeList}
                                            multiple={true}
                                            onChange={onChangeMultiSelect}
                                            defaultValue={state.editForm.dentistShiftType || []}
                                        />
                                    </Form.Field>
                                   
                                    <Form.Input
                                        fluid={true}
                                        label='UKG ID'
                                        disabled={initialValues.payTpe === '1099'}
                                        value={state.editForm.payType !== '1099' ? state.editForm.ukgid || '' : ''}
                                        name={'ukgid'}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                                <FormGroup widths='3'>
                                    <Form.Input
                                        fluid={true}
                                        label='UKG Account Name'
                                        disabled={true}
                                        value={ukgProfiles ? ukgProfiles.account_name : ""}
                                    />
                                 <Form.Field>
                                        <label>Include Hygienist Pay</label>
                                        <Checkbox
                                            name="includeHygienist"
                                            onChange={(e, data) => onChangePermission(e, {...data, value: data.checked})}
                                            toggle={true}
                                            checked={state.editForm.includeHygienist}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Is PRN</label>
                                        <Checkbox
                                            name="isPRN"
                                            onChange={(e, data) =>
                                                onChangeIsPRN(e, {...data, value: data.checked})}
                                            toggle={true}
                                            checked={state.editForm.isPRN}
                                        />
                                    </Form.Field>
                                </FormGroup>
                                <FormGroup widths='3'>
                                    <Form.Field>
                                        <label> (w/Hyn) Contract Start Date</label>
                                        <div className="datePicker widthFluid">
                                            <DatePicker
                                                name='wHynContractStart'
                                                selected={state.editForm.wHynContractStart}
                                                onChange={(date, _) => handleDateChange(date, 'wHynContractStart')}
                                            />
                                        </div>
                                    </Form.Field>
                                </FormGroup>
                            </Grid.Row>
                        </Card.Content>
                    </Card>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Clinical Excellence',
            render: () => (
                <Tab.Pane>
                    <Card fluid>
                        <Card.Content header='CLINICAL EXCELLENCE' />
                        <Card.Content>
                            <Grid.Row>
                                <FormGroup widths='equal'>
                                    <Form.Field>
                                        <label>Is Review Admin</label>
                                        <Checkbox
                                            name="isAuditAdmin"
                                            onChange={(e, data) => onChangePermission(e, {...data, value: data.checked})}
                                            toggle={true}
                                            checked={state.editForm.isAuditAdmin}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Is Reviewer</label>
                                        <Checkbox
                                            name="isAuditor"
                                            onChange={(e, data) => onChangePermission(e, {...data, value: data.checked})}
                                            toggle={true}
                                            checked={state.editForm.isAuditor}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Is Reviewee</label>
                                        <Checkbox
                                            name="isAuditee"
                                            onChange={(e, data) => onChangePermission(e, {...data, value: data.checked})}
                                            toggle={true}
                                            checked={state.editForm.isAuditee}
                                        />
                                    </Form.Field>
                                </FormGroup>
                            </Grid.Row>
                        </Card.Content>
                    </Card>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'Contracts',
            render: () => (
                <Tab.Pane>
                    <Card fluid>
                        <Card.Content header='CONTRACTS' />
                        <Card.Content>
                            <Grid.Row>
                                <FormGroup widths='equal'>
                                    <Form.Field> <label>Part/Full Time</label>
                                        <Select
                                            name="dentistWorkTimeType"
                                            placeholder='Part/Full Time'
                                            onChange={onChangePermission}
                                            options={dentistWorkTimeType}
                                            defaultValue={state.editForm.dentistWorkTimeType || ""}
                                        />
                                    </Form.Field>
                                    <div className="field">
                                        <label>Hire Date</label>
                                        <div className="datePicker widthFluid">
                                            <DatePicker
                                                name='hireDate'
                                                selected={state.editForm.hireDate}
                                                onChange={(date, _) => handleDateChange(date, 'hireDate')}
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label>Contract Start Date</label>
                                        <div className="datePicker widthFluid">
                                            <DatePicker
                                                name='contractStart'
                                                selected={state.editForm.contractStart}
                                                onChange={(date, _) => handleDateChange(date, 'contractStart')}
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label>Contract End Date</label>
                                        <div className="datePicker widthFluid">
                                            <DatePicker
                                                name='contractEnd'
                                                selected={state.editForm.contractEnd}
                                                onChange={(date, _) => handleDateChange(date, 'contractEnd')}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Grid.Row>
                            <Grid.Row>
                                <FormGroup widths='equal'>
                                    <Form.Field> <label>Contract Name</label>
                                        <Select
                                            name="contractName"
                                            placeholder='Contract Name'
                                            options={contractName}
                                            onChange={onChangePermission}
                                            defaultValue={state.editForm.contractName || ""}
                                        />
                                    </Form.Field>
                                    {
                                        (state.editForm.contractName === 'GOX' || state.editForm.contractName === 'GOZ' || state.editForm.contractName === 'Greater Of' || state.editForm.contractName === 'Collections' || state.editForm.contractName === 'Collections w/HYN' || state.editForm.contractName === 'Greater Of w/HYN') &&
                                        <Form.Input
                                            fluid={true}
                                            label={`${state.editForm.contractName} Day Rate`}
                                            value={state.editForm.dailyRatePay || ''}
                                            name={'dailyRatePay'}
                                            onChange={onChangeDayRate}
                                        />
                                    }
                                    {
                                        (state.editForm.contractName === 'GOX' || state.editForm.contractName === 'GOZ' || state.editForm.contractName === 'Collections' || state.editForm.contractName === 'Collections w/HYN' || state.editForm.contractName === 'Greater Of' || state.editForm.contractName === 'Greater Of w/HYN')
                                            ?
                                            <Form.Input
                                                fluid={true}
                                                label={`${state.editForm.contractName} Pay %`}
                                                value={state.editForm.payOverBase || ''}
                                                name={'payOverBase'}
                                                onChange={onChange}
                                            />
                                            :
                                            <Form.Input
                                                fluid={true}
                                                label={`Pay Over Base %`}
                                                value={state.editForm.payOverBase || ''}
                                                name={'payOverBase'}
                                                onChange={onChange}
                                            />
                                    }
                                </FormGroup>
                            </Grid.Row>
                            <Grid.Row>
                                <FormGroup widths="equal">
                                    <Form.Input
                                        fluid={true}
                                        label={`ProdADJWO %`}
                                        value={state.editForm.prodAdjWo || ''}
                                        name={'prodAdjWo'}
                                        onChange={onChange}
                                    />
                                    {(state.editForm.contractName !== 'Collections'|| state.editForm.contractName !== 'Collections w/HYN') &&
                                        <FormField>
                                            <div title="There are some variants in the practices." className="col-md-3 col-sm-6 col-xs-12 dentist-list">
                                                <label style={{ color: "red" }}>**These rates will differ by Practice**</label>
                                                {isEligibleForRatesByPractice() ? (
                                                    <Button
                                                        primary
                                                        fluid
                                                        onClick={() => setOpenProviderDayRateModal(true)}
                                                    >
                                                        Rates by Practice
                                                    </Button>
                                                ) : (
                                                    <Popup
                                                        content="Rates by Practice is only available for GOX, GOZ, and Greater Of contracts."
                                                        trigger={
                                                            <div style={{ display: 'inline-block', width: '100%' }}>
                                                            <Button
                                                                primary
                                                                fluid
                                                                disabled
                                                            >
                                                                Rates by Practice
                                                            </Button>
                                                            </div>
                                                        }
                                                        position="top center"
                                                    />
                                                )}
                                            </div>
                                        </FormField>

                                    }
                                    {(state.editForm.contractName === 'Sum Of' ||state.editForm.contractName === 'Sum Of w/HYN' ) &&
                                        <Form.Input
                                            fluid={true}
                                            label='Monthly Base Work Days'
                                            value={state.editForm.monthlyBaseWorkDays || ''}
                                            name={'monthlyBaseWorkDays'}
                                            onChange={onChange}
                                        />
                                    }

                                </FormGroup>

                            </Grid.Row>

                            <Grid.Row>
                                <FormGroup widths='equal'>
                                    {(state.editForm.contractName === 'Sum Of' || state.editForm.contractName === 'Sum Of w/HYN') &&
                                        <Form.Input
                                            fluid={true}
                                            label='Monthly Base Pay'
                                            value={state.editForm.monthlyBasePay || ''}
                                            name={'monthlyBasePay'}
                                            onChange={onChange}
                                        />
                                    }
                                    {
                                        (state.editForm.contractName === 'Sum Of' || state.editForm.contractName === 'Sum Of w/HYN') &&
                                        <Form.Input
                                            fluid={true}
                                            label='Monthly Base Production'
                                            value={state.editForm.monthlyBaseProduction || ''}
                                            name={'monthlyBaseProduction'}
                                            onChange={onChange}
                                        />
                                    }
                                    {
                                        state.editForm.contractName === 'UKG HYN' &&
                                        <Form.Input
                                            fluid={true}
                                            label='Pay Time Off Days'
                                            value={state.editForm.payTimeOffDays || ''}
                                            name={'payTimeOffDays'}
                                            onChange={onChange}
                                        />
                                    }
                                    {(state.editForm.contractName === 'Sum Of' || state.editForm.contractName === 'Sum Of w/HYN') &&
                                        <>
                                            <Button primary fluid style={{ marginTop: "18px" }}
                                                    onClick={() => setOpenDoctorBaseWorkDaysModal(true)}
                                            >Practice Monthly Base Work Days</Button>
                                            <Button primary fluid style={{ marginTop: "18px" }}
                                                    onClick={() => setOpenProviderRampThresholdModal(true)}
                                            >Provider Ramp Threshold</Button>
                                        </>

                                    }
                                </FormGroup>

                            </Grid.Row>

                            <Grid.Row>
                                <FormGroup widths='equal'>
                                </FormGroup>
                            </Grid.Row>
                            <GridRow>
                                {isOrthodontics &&
                                    <>
                                        <GridRow>
                                            <FormGroup widths='equal'>
                                                <Form.Input
                                                    fluid={true}
                                                    label='Start Bonus'
                                                    value={state.editForm.startBonus || 0}
                                                    name={'startBonus'}
                                                    onChange={onChange}
                                                />
                                                <Form.Input
                                                    fluid={true}
                                                    label='SD-Start Bonus'
                                                    value={state.editForm.sdStartBonus}
                                                    name={'sdStartBonus'}
                                                    onChange={(e) => onChange(e)}
                                                />
                                                <Form.Input
                                                    fluid={true}
                                                    label='SD-Contract'
                                                    value={state.editForm.sdContract || 0}
                                                    name={'sdContract'}
                                                    onChange={onChange}
                                                />
                                                <Button primary fluid style={{ marginTop: "18px" }}
                                                        onClick={() => setOpenOrthoDayRateModal(true)}>Ortho Day Rate</Button>
                                            </FormGroup>
                                        </GridRow>
                                        <GridRow>
                                            <FormGroup widths='equal'>
                                                <Form.Input
                                                    fluid={true}
                                                    label='Travel Stipend'
                                                    defaultValue={state.editForm.travelStipend || 0}
                                                    name={'travelStipend'}
                                                    onChange={onChange}
                                                />
                                                <Form.Input
                                                    fluid={true}
                                                    label='Debond Bonus'
                                                    value={state.editForm.debondBonus || 0}
                                                    name={'debondBonus'}
                                                    onChange={onChange}
                                                />
                                                <Form.Input
                                                    fluid={true}
                                                    label='DOT Bonus'
                                                    value={state.editForm.dotBonus || 0}
                                                    name={'dotBonus'}
                                                    onChange={onChange}
                                                />
                                                 <Form.Input
                                                    fluid={true}
                                                    label='PH1 Start Bonus'
                                                    value={state.editForm.ph1StartBonus }
                                                    name={'ph1StartBonus'}
                                                    onChange={onChange}
                                                />
                                            </FormGroup>
                                        </GridRow>
                                        <GridRow>
                                            <FormGroup widths='4'>
                                                <FormField>
                                                    <Checkbox
                                                        label="Ooo Consult Pay"
                                                        name='consultPay'
                                                        checked={state.editForm.consultPay}
                                                        onChange={(_e, { checked }) => handleCheckBox(checked, 'consultPay')}
                                                    />
                                                </FormField>
                                                <FormField>
                                                    <Checkbox
                                                        label="First Start"
                                                        name='firstStart'
                                                        checked={state.editForm.firstStart}
                                                        onChange={(_e, { checked }) => handleCheckBox(checked, 'firstStart')}
                                                    />
                                                </FormField>
                                            </FormGroup>
                                        </GridRow>
                                    </>
                                }
                            </GridRow>
                            <GridRow>
                                <FormGroup widths='4'>
                                    <FormField>
                                        <Form.Input
                                            fluid={true}
                                            label='Monthly Required Days'
                                            value={state.editForm.monthlyRequiredDays}
                                            name={'monthlyRequiredDays'}
                                            onChange={onChange}
                                        />
                                    </FormField>
                                    {state.editForm.contractName === 'Sum Of' && (
                                        <>
                                            <FormField>
                                                <label>TED Collections</label>
                                                <Checkbox
                                                    name="tedCollections"
                                                    onChange={(e, data) => onChangePermission(e, {...data, value: data.checked})}
                                                    toggle={true}
                                                    checked={state.editForm.tedCollections}
                                                />
                                            </FormField>
                                           
                                        </>
                                    )}
                                     <FormField>
                                            <label>Contract Tier</label>
                                            <Checkbox
                                                name="tierContract"
                                                onChange={(e, data) => onChangePermission(e, {...data, value: data.checked})}
                                                toggle={true}
                                                checked={state.editForm.tierContract}
                                            />
                                    </FormField>
                                    { state.editForm.tierContract &&(
                                        <Form.Field><label>Tier Controller</label>
                                        <Select
                                            placeholder='Tier Controller'
                                            options={tierControllerOptions}
                                            defaultValue={state.editForm.tierController || ""}
                                            name="tierController"
                                            onChange={onChangePermission}
                                        />
                                    </Form.Field>

                                    )

                                    }
                                </FormGroup>
                            </GridRow>
                        </Card.Content>
                    </Card>
                </Tab.Pane>
            )
        },
        ...(state.editForm.tierContract ? [{
            menuItem: 'Tier Management',
            render: () => (
                <Tab.Pane>
                    <Card fluid>
                        <Card.Content>
                            <Button 
                                primary 
                                icon="plus"
                                content="Add Tier"
                                onClick={addTierManagement}
                                style={{ marginBottom: '1rem' }}
                            />
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Tier Type</Table.HeaderCell>
                                        <Table.HeaderCell>Practice</Table.HeaderCell>
                                        <Table.HeaderCell>Amount From</Table.HeaderCell>
                                        <Table.HeaderCell>Amount To</Table.HeaderCell>
                                        <Table.HeaderCell>Percentage</Table.HeaderCell>
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {tierManagements.filter(tier => !tier.isDeleted).length === 0 ? (
                                        <Table.Row>
                                            <Table.Cell colSpan="6" textAlign="center">
                                                No records found
                                            </Table.Cell>
                                        </Table.Row>
                                    ) : (
                                        tierManagements
                                            .filter(tier => !tier.isDeleted)
                                            .map((tier, index) => (
                                                <Table.Row key={index}>
                                                    <Table.Cell>
                                                        <Select
                                                            fluid
                                                            value={tier.tierType}
                                                            options={tierControllerOptions}
                                                            onChange={(_, data) => 
                                                                handleTierManagementChange(index, 'tierType', data.value)}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Select
                                                            fluid
                                                            value={tier.practiceId}
                                                            options={getTierManagementPracticeOptions()}
                                                            onChange={(_, data) => 
                                                                handleTierManagementChange(index, 'practiceId', data.value)}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input
                                                            fluid
                                                            type="number"
                                                            value={tier.amountFrom || ''}
                                                            onChange={(e) => 
                                                                handleTierManagementChange(index, 'amountFrom', e.target.value ? Number(e.target.value) : undefined)}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input
                                                            fluid
                                                            type="number"
                                                            value={tier.amountTo || ''}
                                                            onChange={(e) => 
                                                                handleTierManagementChange(index, 'amountTo', e.target.value ? Number(e.target.value) : undefined)}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input
                                                            fluid
                                                            type="number"
                                                            value={tier.percentValue || ''}
                                                            onChange={(e) => 
                                                                handleTierManagementChange(index, 'percentValue', e.target.value ? Number(e.target.value) : undefined)}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Button
                                                            icon="trash"
                                                            negative
                                                            onClick={() => {
                                                                const actualIndex = tierManagements.findIndex(t => 
                                                                    t === tierManagements.filter(t => !t.isDeleted)[index]
                                                                );
                                                                removeTierManagement(actualIndex);
                                                            }}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))
                                    )}
                                </Table.Body>
                            </Table>
                        </Card.Content>
                    </Card>
                </Tab.Pane>
            )
        }] : [])
    ];

    return (
        <>
            <Form className="formStyle">
                <div style={{ float: 'right' }}>
                    <FormGroup widths='1' textAlign="right">
                        <Form.Field>
                            <Checkbox
                                label="Active"
                                name='status'
                                checked={state.editForm.status}
                                onChange={(_e, { checked }) => handleCheckBox(checked, 'status')}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                label="Credentialing Documents"
                                name='credentialingDocs'
                                checked={!!state.editForm.credentialingDocs}
                                onChange={(_e, { checked }) => handleCheckBox(checked, 'credentialingDocs')}
                            />
                        </Form.Field>
                    </FormGroup>
                </div>

                <Tab panes={panes}/>

                <Card>
                    <Card.Content>
                        <Grid.Row>
                            <FormGroup widths='equal'>
                                <Button secondary={true} onClick={cancelForm}>Cancel</Button>
                                <Button primary={true} onClick={saveFormLocal}>Save</Button>
                                <Button secondary={true} onClick={() => deleteProfile(props.initialValues)}>Delete</Button>
                            </FormGroup>
                        </Grid.Row>
                    </Card.Content>
                </Card>
            </Form>
        </>
    );
}

export default EditProviderProfileForm;
