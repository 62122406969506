import {Button, Dropdown, Form, Grid, Icon, Input, Label, Modal} from "semantic-ui-react";
import React, {useState} from "react";
import DynamicFieldController from "../DynamicFormInputs/DynamicFieldController";
import DatePicker from "react-datepicker";


interface props {
    handleModalClose: () => void,
    currentProvider: any,
    handleSaveProvider: any,
    variables: any[],
}

const ProviderRatesAddForm = ({handleModalClose, currentProvider, handleSaveProvider, variables}: props) => {
    const [form, setForm] = useState(currentProvider.variables);
    const [dates, setDates] = useState<any>(currentProvider ?? {from: new Date()});

    console.log('CONSOLE_LOG', currentProvider)

    const handleSubmit = () => {
        handleSaveProvider({...currentProvider, variables: form, dates});
    }

    return (
        <Modal open={true} closeIcon={true} size="large" onClose={handleModalClose}>
            <Modal.Content>
                <Label size="large" color="orange" tag={true}>
                    <Icon name="doctor"/> {currentProvider.text}
                </Label>
                <br/>
                <br/>
                <Form>
                    <Grid divided="vertically">
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <Form.Field>
                                    <label>Start Date</label>
                                    <DatePicker selected={dates.from} onChange={(d) => setDates({...dates, from: d!})}/>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Form.Field>
                                    <label>End Date</label>
                                    <DatePicker selected={dates.to} onChange={(d) => setDates({...dates, to: d!})}/>
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            {variables
                                .sort((a, b) => a.inputType.localeCompare(b.inputType))
                                .map((field, i) => {
                                    const {
                                        name, label, inputType, options, id
                                    } = field;
                                    return <Grid.Column width="3" key={name + i}>
                                        <Form.Field>
                                            <label className="text-uppercase">{label}</label>
                                            <DynamicFieldController
                                                type={inputType}
                                                placeholder={label}
                                                name={name}
                                                options={options}
                                                value={form[id]}
                                                onChange={(e: any) => setForm({...form, [id]: e.target.value})}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                })}
                        </Grid.Row>
                    </Grid>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="grey" onClick={handleModalClose}>
                    Cancel
                </Button>
                <Button color="orange" onClick={handleSubmit}>
                    Save Rates
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ProviderRatesAddForm;