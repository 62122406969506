import { ActionType } from "../../model/actionTypes";
import { errorHandler } from "./auth";

export const getQueryLogs = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.get("query-logging/logs", null, params);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}; 