import { Dispatch } from 'redux';
import {errorHandler} from "./auth";

// Action Types
export const ActionType = {
    LOADER: 'LOADER',
    DOCTOR_VARIABLE_SAVE_SUCCESS: 'DOCTOR_VARIABLE_SAVE_SUCCESS',
    DOCTOR_VARIABLE_FETCH_SUCCESS: 'DOCTOR_VARIABLE_FETCH_SUCCESS',
};

// Action to save doctor variable
export const saveDoctorVariable = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const response = await api.post('api/provider-rates', data);
        dispatch({ type: ActionType.DOCTOR_VARIABLE_SAVE_SUCCESS, payload: response });
        dispatch({ type: ActionType.LOADER, payload: false });
        return response;
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        console.error(error);
    }
};

// Action to fetch doctor variables
export const fetchDoctorVariables = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    console.log('fetchDoctorVariables');
    try {
        const response = await api.get('api/provider-rates');
        dispatch({ type: ActionType.DOCTOR_VARIABLE_FETCH_SUCCESS, payload: response });
        dispatch({ type: ActionType.LOADER, payload: false });
        return response
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        console.error(error);
    }
};


// Action to save doctor variable
export const saveVariable = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        await api.post('api/provider-rates/variables', data);
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        console.error(error);
    }
};

// Action to fetch doctor variables
export const fetchVariables = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        return await api.get('api/provider-rates/variables')
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        console.error(error);
        return [];
    }
};
