import React, { useState, lazy, Suspense } from 'react';
import Template from "../../components/Template";
import { Accordion, Card, Grid, GridColumn, Icon, Label } from "semantic-ui-react";
import { RootState } from "../../model";
import { User } from "../../model/user";
import { connect, useSelector } from "react-redux";
import moment from 'moment';
const EmailModal = lazy(() => import('./emailModal')) ;

const CronsHealth = lazy(() => import('./CronsHealth'));
const PasswordCheckerHealth = lazy(() => import('./PasswordCheckerHealth'));
const DentaHub = lazy(() => import('./DentaHub'));
const AptoodConfigHealth = lazy(() => import('./AptoodConfigHealth'));
const SpotHealth = lazy(() => import('./Spot/SpotHealth'));
const OrthoMountDateHealth = lazy(() => import('./OrthoMountDate/OrthoMountDateHealth'));
const XrayServiceHealth = lazy(() => import('./XrayService/XrayServiceHealth'));
const OpenDentalHealth = lazy(() => import('./ODConnection/OpenDentalStatusHealth'));
const DentalXChangeHealth = lazy(() => import('./DentalXChange/DentalXChangeHealth'));
const Five9CallsHealth = lazy(() => import('./Five9Calls/Five9CallsHealth'));
const AwsDatabaseMigration = lazy(() => import('./AWSDataMigration/AwsDatabaseMigration'));
const PatientValidationHealth = lazy(() => import('./PatientValidation/PatientValidationHealth'));
const HalopsaHealth = lazy(() => import('./Halopsa/HalopsaHealth'));
const MySQLHealth = lazy(() => import('./MySQL/MySQLHealth'));
const AwsBounceEmails = lazy(() => import('./AwsBounceEmail/AwsBounce'));
const AwsLambdaHealth = lazy(() => import('./AwsLambdas/LambdaHealth'));
const UptimeRobot = lazy(() => import('./UptimeRobot/uptimeRobotStatus'));
const SpCreatedTablesHealth = lazy(() => import('./SpCreatedTables/SpCreatedTablesHealth'));
const PCRHealth = lazy(() => import('./PCR/PCRHealth'));
const WhiteScreenHealth = lazy(() => import('./WhiteScreen/WhiteScreenHealth'));
const OrthoPcrHealth = lazy(() => import('./OrthoPcr/OrthoPcrHealth'));

interface Props {
    auth: User
}

function HealthStatus(props: Props) {
    const [state, setState] = useState({
        viewMail: false,
        data: { groupedData: [], records: [], trackerRecords: [] }, loading: false, count: {
            success: 0,
            failed: 0,
            pending: 0,
        }
    });

    const { data, loading, count, viewMail } = state;
    const healthScore = useSelector((state: any) => state.healthScore);

    const getTimeStamp = () => (
        <strong style={{ color: 'gray' }}><small>{moment().format('YYYY-MM-DD HH:mm')}</small></strong>
    );

    return (
        <Template activeLink='health status'>
            {viewMail && <Suspense fallback={<div>Loading Email Modal...</div>}>
                <EmailModal open={viewMail} close={() => setState({ ...state, viewMail: false })} />
            </Suspense>}
            <Card fluid style={{ padding: '10px' }}>
                <Grid>
                    <GridColumn floated='left' width={3}>
                        <h2 className="float-left mr10">Health Status <br />
                            {getTimeStamp()}
                        </h2>
                        <Label as='a' size='large' onClick={() => setState({ ...state, viewMail: true })} color='grey'>
                            <Icon name='mail outline' /> Email support
                        </Label>
                    </GridColumn>
                    <GridColumn floated='right' textAlign='right' width={12}>
                        <Label size='huge' color='green'>{healthScore.success}</Label>
                        <Label size='huge' color='yellow'>{healthScore.pending}</Label>
                        <Label size='huge' color='red'>{healthScore.failed}</Label>
                    </GridColumn>
                </Grid>
            </Card>
            <Grid.Row>
                <Accordion styled fluid>
                    <Suspense fallback={<div>Loading Components...</div>}>
                        <DentaHub auth={props.auth} />
                        <PatientValidationHealth />
                        <DentalXChangeHealth data={data} loading={loading} />
                        <SpotHealth />
                        <SpCreatedTablesHealth />
                        <OpenDentalHealth />
                        <OrthoPcrHealth />
                        <PCRHealth />
                        <WhiteScreenHealth />
                        <HalopsaHealth />
                        <PasswordCheckerHealth />
                        <AptoodConfigHealth />
                        <XrayServiceHealth />
                        <MySQLHealth />
                        <UptimeRobot />
                        <AwsLambdaHealth />
                        <AwsDatabaseMigration />
                        <AwsBounceEmails />
                        <OrthoMountDateHealth />
                        <CronsHealth />
                        <Five9CallsHealth />
                    </Suspense>
                </Accordion>
            </Grid.Row>
        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(HealthStatus);
